export default {
  tooltipButton: {
    padding: '2px',
  },
  menuItem: {
    display: 'flex',
    gap: '7px',
    fontFamily: 'Mulish',
    color: '#464646',
  },
  menuItemIcon: {
    width: '20px',
    height: '20px',
  },
};
