import axios from 'axios';
import uniqid from './clientId';

/**
 * Axios instance pointing to the document service
 */
const CleanAxios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_URL}/v1`,
  timeout: 60000,
  withCredentials: true,
  headers: {
    'x-request-id': uniqid,
  },
});

export default CleanAxios;
