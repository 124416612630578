import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './style';

const useStyles = makeStyles(styles);

function AllocationComponent({ ...props }) {
  const {
    labelText,
    name,
    listItem,
    selectedValue,
    errorText,
    color,
    fontVariant,
    fontWeight,
    isError,
    textPlacement,
    isDisabled,
    checktextWrap,
    checkmarginLeft,
    checkmarginTop,
    ...rest
  } = props;
  const delimiter = ',';
  const classes = useStyles();
  const delimitedSelectedValue = delimiter.concat(selectedValue, delimiter);
  return (
    <FormControl error={isError} component="fieldset" variant="outlined">
      <FormLabel style={{ color, fontWeight }} component="legend">
        <Typography
          variant={fontVariant || 'body1'}
          style={labelText ? { display: 'inline-block' } : { display: 'none' }}
        >
          {labelText}
        </Typography>
      </FormLabel>
      <FormGroup {...rest} name={name} className={classes.groupRoot}>
        {listItem.map(object => (
          <Grid item xs={12}>
            <FormControlLabel
              key={object.id}
              value={object.id}
              disabled={isDisabled}
              style={{ textWrap: checktextWrap, marginLeft: checkmarginLeft, marginTop: checkmarginTop }}
              className={
                delimitedSelectedValue.indexOf(delimiter + object.id + delimiter) !== -1
                  ? classes.checkStyleafter
                  : classes.checkStylebefore
              }
              checked={delimitedSelectedValue.indexOf(delimiter + object.id + delimiter) !== -1}
              control={<Checkbox name={name} id={`chk_${name}_${+object.id}`} color="primary" />}
              label={
                <>
                  <span
                    className={
                      delimitedSelectedValue.indexOf(delimiter + object.id + delimiter) !== -1
                        ? classes.labelTextafter
                        : classes.labelTextbefore
                    }
                  >
                    {object.value.split('|')[0]}
                  </span>{' '}
                  <span className={classes.spanRed}>{object.value.split('|')[1]}</span>
                </>
              }
            />
          </Grid>
        ))}
      </FormGroup>
      {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
}

AllocationComponent.propTypes = {
  labelText: PropTypes.string,
  name: PropTypes.string,
  selectedValue: PropTypes.string,
  errorText: PropTypes.string,
  color: PropTypes.string,
  fontVariant: PropTypes.string,
  fontWeight: PropTypes.string,
  listItem: PropTypes.array,
  labelClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  textPlacement: PropTypes.string,
  checktextWrap: PropTypes.string,
  checkmarginLeft: PropTypes.string,
  checkmarginTop: PropTypes.string,
};

export default AllocationComponent;
