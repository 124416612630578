/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RenderForms from 'views/illustration/renderforms';
import constants from 'constants/common';
import styles from '../../styles';

const useStyles = makeStyles(styles);
export default function RenderFormValidation(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const tabName =
    getStarted.trackergroupnames.length > 0
      ? getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].displayname
      : '';
  const maxTrustees = constants.FieldListIndex;
  let jsonDataRes = [];
  let isJson = false;
  let isFieldVisible = false;

  const GetAppData = (jsonData, isDynamicJson) => {
    if (isDynamicJson) {
      return (
        <React.Fragment>
          <Grid item xs={12} className={isJson || isFieldVisible ? classes.displayBlock : classes.displayNone}>
            <Paper className={classes.paperGrid} square elevation={3}>
              <Grid container className={classes.paperBody} spacing={2}>
                <RenderForms onChange={props.onChange} onTabClick={props.onTabClick} JsonData={jsonData} />
              </Grid>
            </Paper>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <RenderForms onChange={props.onChange} onTabClick={props.onTabClick} JsonData={jsonData} />
      </React.Fragment>
    );
  };

  const componentBox = count => {
    if (getStarted.currentpage.JsonData === '') {
      return;
    }

    jsonDataRes = getStarted.currentpage.JsonData.filter(x => x.fieldProperty.boxSection === `${tabName}${count}`);
    isJson =
      jsonDataRes.filter(x => x.fieldProperty.isVisible === true || x.fieldProperty.isVisible === undefined).length > 0;

    isFieldVisible =
      jsonDataRes.filter(
        x =>
          document.getElementById(`grd${x.fieldName}`) != null &&
          document.getElementById(`grd${x.fieldName}`).style.display === 'block',
      ).length > 0;

    return GetAppData(jsonDataRes, true);
  };

  return (
    <React.Fragment>
      {maxTrustees.map(cnt => {
        return componentBox(cnt);
      })}
    </React.Fragment>
  );
}

RenderFormValidation.propTypes = {
  onChange: PropTypes.func,
  onTabClick: PropTypes.func,
};
