import { useQuery } from 'react-query';
import masterService from 'api/masterService';

const useContracts = partyId => {
  const contracts = useQuery({
    queryKey: ['contracts', partyId],
    queryFn: async () => masterService.getContractsForParty(partyId),
    refetchOnMount: false,
    staleTime: Infinity,
    enabled: !!partyId,
  });

  return contracts;
};

export default useContracts;
