import masterService from 'api/masterService';
import { useQuery } from 'react-query';

const useProducts = () => {
  const products = useQuery({
    queryKey: ['products'],
    queryFn: async () => masterService.getProducts(),
    refetchOnMount: false,
    staleTime: Infinity,
  });

  return products;
};

export default useProducts;
