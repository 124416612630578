export default {
  modal: {
    margin: '2.5rem',
    textAlign: 'center',
    overflowY: 'hidden',
    position: 'relative',
  },
  modalClear: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },
  modalTitle: {
    marginBottom: '1rem',
  },
  modalText: {
    marginBottom: '2.5rem',
  },
  modalButton: {
    display: 'block',
    margin: '1rem auto',
    width: '225px',
  },
};
