import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Delete, Email, GetApp, MoreHoriz } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import ContentCopy from 'components/icons/ContentCopy';
import styles from './styles';

const useStyles = makeStyles(styles);

/**
 * @param {TableActionMenuProps}
 */
export default function TableActionMenu({ rowData }) {
  const classes = useStyles();
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const handleOpenMenu = event => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorElement(null);
  };

  return (
    <>
      <Tooltip title="Actions" className={classes.tooltipButton} onClick={handleOpenMenu}>
        <IconButton>
          <MoreHoriz />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorElement}
        keepMounted
        open={Boolean(menuAnchorElement)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem className={classes.menuItem} onClick={() => console.log(rowData)}>
          <ContentCopy className={classes.menuItemIcon} />
          <span>Duplicate</span>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <GetApp className={classes.menuItemIcon} />
          <span>Download</span>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Email className={classes.menuItemIcon} />
          <span>Email</span>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Delete className={classes.menuItemIcon} />
          <span>Delete</span>
        </MenuItem>
      </Menu>
    </>
  );
}

/**
 * @typedef {Object} TableActionMenuProps
 * @property {import('views/history').IllustrationData} rowData
 */
