/* eslint-disable no-console */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect } from 'use-async-effect';
import { AnalyticsBanner } from '@aspida/react-components';
import constants from 'constants/common';
import masterService from 'api/masterService';
import NotificationService from 'services/notificationService';
import commonFunctions from 'views/illustration/utils/commonFunctions';
import { setAuthenticatedUser } from 'redux/user/actions';
import SideBar from 'components/sideBar';
import TabletMenu from 'components/tabletMenu';
import compTablet from 'assets/images/compTablet.svg';
import standardLogo from 'assets/images/standard-logo.svg';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles, Hidden, Grid, Typography, Paper, CircularProgress } from '@material-ui/core';
import { setFlags } from 'redux/appConfig/action';
import Helper from '../util/utilFunctions';
import ErrorBoundary from './ErrorBoundary';

import styles from './styles';
import './app.css';

window.jsonToQueryString = json => {
  return `?${Object.keys(json)
    .map(key => {
      return `${key}=${json[key]}`;
    })
    .join('&')}`;
};

const useStyles = makeStyles(styles);
function App() {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);
  const user = useSelector(state => state.user);
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let sessionIllustrationData;

  useAsyncEffect(async isMounted => {
    if (user.id) return;
    try {
      const [err, userInfo] = await masterService.getCurrentUser();
      if (err) {
        navigate('/signout');
        return;
      }
      const [errorResponse, userObj] = await masterService.getPeopleById(userInfo['custom.partyId']);

      if (errorResponse) {
        navigate('/signout');
        return;
      }

      if (!isMounted()) {
        return;
      }

      setConnected(true);
      dispatch(setFlags());

      const pendoConfig = {
        sanitizeUrl: url => {
          return url.split('?')[0]; // chop off query parameters
        },
      };

      if (userObj && userObj.analyticsCollectionId !== constants.analyticsCollection.optOut) {
        pendoConfig.visitor = {
          id: userObj.id,
          role: userObj.types.map(t => t.description),
          env: process.env.REACT_APP_ENV,
        };
      }

      window.pendo.initialize(pendoConfig);

      if (!user.isDemographicVerified && userObj.types.length) {
        const illustrationAllowedRoles = constants.illustrationUserRoles;
        const foundRole = userObj.types.filter(userRole => illustrationAllowedRoles.includes(userRole.id));

        if (foundRole.length === 0) {
          NotificationService.error('You are not authorized to access Illustration.');
          navigate('/signout');
          return;
        }
        if (commonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('IllustrationPolicyData')) !== '') {
          sessionIllustrationData = JSON.parse(sessionStorage.getItem('IllustrationPolicyData'));
          Helper.setSpecification(userObj, sessionIllustrationData);
        }
      }

      dispatch(setAuthenticatedUser(userObj));
    } catch (err) {
      console.error(err);
      setConnected(true);
    }
    setConnected(true);
  }, []);

  const updateAnalyticsDecision = async decision => {
    const analyticsCollectionId = constants.analyticsCollection[decision ? 'accepted' : 'optOut'];
    const updatedUser = { analyticsCollectionId };
    await masterService.updatePerson(user.id, updatedUser);
  };

  return (
    <div>
      {connected && (
        <div>
          <Hidden only={['xs', 'md', 'lg', 'xl']}>
            <TabletMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
          </Hidden>
          <div className="app">
            <Hidden only={['xs', 'sm']}>
              <div className="menu">
                <SideBar />
              </div>
            </Hidden>
            <Hidden only="xs">
              <div id="divContainer" className="container">
                <div className="innerContainer">
                  {user ? (
                    <ErrorBoundary>
                      <Outlet />
                    </ErrorBoundary>
                  ) : (
                    <div className={classes.localLoading}>
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </div>
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <div className={classes.divWidth}>
                <Grid container>
                  <Grid item xs={12} className={classes.aspidaIconStyle}>
                    <img src={standardLogo} alt="logo" />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paperGridNotification} elevation={0}>
                      <Grid container className={classes.paperBodyNotification} spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom className={classes.TitleNotification}>
                            The illustration is currently not available on mobile devices. Please use a tablet or
                            desktop browser to continue.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <img src={compTablet} alt="logo" className={classes.aspidaIcon} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom className={classes.subTitleNotification}>
                            Thank You!
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Hidden>
          </div>
        </div>
      )}
      <AnalyticsBanner collect={user && user.analyticsCollectionId} onSelect={updateAnalyticsDecision} />
    </div>
  );
}

export default App;
