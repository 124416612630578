export default {
  prodNum: {
    fontSize: '1rem',
    padding: '1rem 1.5rem',

    '@media (min-width:59.9375rem) and (max-width:75rem)': {
      marginTop: '.5rem',
      marginLeft: '.5em',
      fontSize: '.7rem',
    },
    '@media (max-width:51.75rem)': {
      marginTop: '0',
      marginLeft: '2em',
      fontSize: '9pt',
    },
    '@media (max-width:31.5rem)': {
      marginTop: '0',
      marginLeft: '2em',
      fontSize: '6pt',
    },
  },
  editBtn: {
    textTransform: 'capitalize',
    width: '169px',
    color: '#14043C',
    borderRadius: '2rem',
    boxSizing: 'border-box',
    height: '32px',
    border: '1.5px solid #F50057',
    cursor: 'default',
    fontSize: '12px',
    backgroundColor: '#FFF7F9',
  },
  gridAlignCenterExitButton: {
    textAlign: 'center',
    display: 'block',
  },
  displayNone: {
    display: 'none',
  },
  personIcon: {
    color: '#EF426F',
    fontSize: '25px !important',
  },
  header: {
    height: '4rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '1.3rem',
    paddingBottom: '1rem',
  },
  newApp: {
    fontSize: '0.9375rem',
    textDecoration: 'underline',
    fontWeight: '600',
    cursor: 'pointer',
    '@media (min-width:59.9375rem) and (max-width:75rem)': {
      fontSize: '.7235rem',
    },
    '@media (max-width:39.5rem)': {
      fontSize: '0.5375rem',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  appNumberTxt: {
    color: '#4D4D4D',
    fontFamily: 'Muli',
    fontSize: '28px',
    fontWeight: 'bold',
  },
};
