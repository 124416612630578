import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

interface NumberChangePayload {
  target: {
    value: string,
    type: string,
  };
  currentTarget: {
    type: string,
  };
}
interface PercentageTextBoxProps {
  inputRef: React.Ref<any> | ((el: HTMLInputElement) => void) | undefined;
  onChange: (vals: NumberChangePayload) => void;
  type: string;
  isDecimal?: boolean;
}
function CustomNumberFormat(props: PercentageTextBoxProps & any) {
  const { inputRef, onChange, type, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            type,
          },
          currentTarget: {
            type: '',
          },
        });
      }}
      suffix="%"
      decimalScale={props.isDecimal ? 2 : 0}
      fixedDecimalScale={props.isDecimal}
    />
  );
}

function PercentageTextBox({ ...props }) {
  const { name, isDecimal, ...rest } = props;

  return (
    <TextField
      InputProps={{
        // @ts-ignore
        inputComponent: CustomNumberFormat,
        inputProps: { name, maxLength: 7, isDecimal },
      }}
      {...rest}
    />
  );
}

export default PercentageTextBox;
PercentageTextBox.propTypes = {
  name: PropTypes.string,
  isDecimal: PropTypes.bool,
};
