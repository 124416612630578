import React from 'react';
import AspidaSignOut from 'views/authPages/signout';
import { createBrowserRouter } from 'react-router-dom';
import Illustration from 'views/illustration';
import App from 'app/app';
import HistoryView from 'views/history';
import FeatureGuard from 'components/featureGuard';

export default createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <p>Page Not Found </p>,
    children: [
      {
        index: true,
        element: (
          <FeatureGuard
            featureName="illustration_history"
            enabledElement={<HistoryView />}
            disabledElement={<Illustration />}
          />
        ),
      },
      {
        path: 'history',
        element: <HistoryView />,
      },
      {
        path: 'illustration',
        element: <Illustration />,
      },
    ],
  },
  {
    path: '/signout',
    element: <AspidaSignOut />,
  },
]);
