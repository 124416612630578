import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      height: '100vh',
      width: '261px',
      transform: 'rotate(360deg)',
      backgroundColor: '#14043C',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    linkLogoutStyle: {
      padding: '6px !important',
      textDecoration: 'none !important',
    },
    box: {
      minHeight: '100vh',
    },
    gettingStarted: {
      color: 'white !important',
      textWrap: 'wrap',
    },
    returnToPortalButton: {
      margin: '0.5rem 1.5rem',
      width: 'calc(100% - 3rem)',
    },
    sideBarLinkButton: {
      justifyContent: 'flex-start',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      fontFamily: 'Muli',
      fontSize: '17px',
      fontWeight: '300',
      letterSpacing: '0',
      lineHeight: '24px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#8A829E',
      },
      width: '100%',
    },
    sideBarNavLink: {
      borderRadius: '4px',
      marginBottom: '8px',
    },
    sideBarLinkActive: {
      backgroundColor: 'rgba(138, 130, 158, 1)',
    },
    sideBarLinkContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '15px',
    },
    dividerStyleIcon: {
      marginTop: '18px',
      marginBottom: '2px',
      backgroundColor: '#847c98',
    },
    panelexpandStyle: {
      margin: '7px',
      fontSize: '24px',
    },
    dividerStyle: {
      margin: '18px',
      backgroundColor: '#847c98',
    },
    iconLogoStyle: {
      cursor: 'pointer',
      marginBottom: '-4px',
      marginRight: '26px',
      fontSize: '24px',
    },
    legalRoot: {
      margin: '1rem',
      textAlign: 'center',
      '@media (min-width:960px)': {
        color: 'white',
      },
    },
    icon: {
      '@media (min-width:960px)': {
        color: 'white',
      },
    },
    text: {
      marginTop: '0.5rem',
    },
    links: {
      marginBottom: '1rem',
    },
    link: {
      margin: '0.375rem 0.5rem',
      cursor: 'pointer',
      textDecoration: 'underline',
      '@media (min-width:960px)': {
        color: 'white',
      },
    },
    iconStyle: {
      fontSize: '24px',
    },
    dividericon: {
      padding: '150px 8px 8px 39px',
      textDecoration: 'none',
      fontSize: '25px',
      color: '#FFFFFF',
      display: 'block',
    },
    linkStyleIcon: {
      padding: '12px 12px 48px 12px',
      textDecoration: 'none',
      fontSize: '25px',
      color: '#FFFFFF',
      display: 'block',
    },
    linkLogoStyle: {
      padding: '8px 8px 8px 72px',
      textDecoration: 'none',
      fontSize: '25px',
      color: '#FFFFFF',
      display: 'block',
    },
    linkStyle: {
      padding: '6px !important',
      textDecoration: 'none !important',
    },
    linkStyleActive: {
      borderRadius: '4px',
      backgroundColor: 'rgba(138, 130, 158, 1)',
    },
    collapsedSidebarLinkContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '10px',
    },
    closeside: {
      width: '95px',
    },
    openside: {
      width: '292px',
    },
    textAlign: {
      textAlign: 'center',
    },
    iconLogo: {
      display: 'block',
      cursor: 'pointer',
      margin: '0 auto 0',
      width: '140px',
    },
    linkExitStyle: {
      margin: '0.7rem',
      padding: '12px !important',
      textDecoration: 'none !important',
      fontSize: '25px !important',
      color: '#FFFFFF !important',
      display: 'block !important',
    },
    backToPortal: {
      marginLeft: '.5rem',
    },
  });

export default styles;
