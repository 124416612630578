import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography, Divider, Link, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pinkWhiteLogo from '@aspida/react-components/dist/pinkWhite.svg';
import whiteAspidaLogo from 'assets/images/WhiteAspidaLogo.png';
import LogOut from 'assets/images/logouticon.svg';
import aspidalogo from 'assets/images/miniLogo.png';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Brush, History, SubdirectoryArrowLeft } from '@material-ui/icons';
import CommonFunctions from 'views/illustration/utils/commonFunctions';
import { NavLink, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles';

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const appConfig = useSelector(state => state.appConfig);
  const getStarted = useSelector(state => state.getStarted);
  const esignSuccess = window.location.href.includes('/esignsuccess');
  const [editVisible, setEditVisible] = useState(false);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const logOut = () => {
    sessionStorage.removeItem('IllustrationPolicyData');
    navigate('/signout');
  };

  return (
    <Box className={esignSuccess && classes.displayNone}>
      <Box
        id="mySidebar"
        class="sidebar"
        className={classNames(editVisible ? classes.openside : classes.closeside, 'sidebar')}
        onMouseEnter={() => setEditVisible(true)}
        onMouseLeave={() => setEditVisible(false)}
      >
        {editVisible ? (
          <React.Fragment>
            <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.box}>
              <Box className={classes.textAlign}>
                <Box tabIndex={0} role="link" onClick={() => navigate('/')} onKeyPress={() => navigate('/')}>
                  <img src={pinkWhiteLogo} className={classes.iconLogo} alt="logo" />
                </Box>
                <Box className={classes.sideBarLinkContainer}>
                  {user !== false && (
                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                      {appConfig.config.illustration_history?.enabled && (
                        <NavLink
                          to="/"
                          className={({ isActive }) =>
                            classNames(classes.sideBarNavLink, isActive ? classes.sideBarLinkActive : '')
                          }
                        >
                          <Button
                            variant="outlined"
                            className={classes.sideBarLinkButton}
                            startIcon={<History />}
                            size="large"
                          >
                            History
                          </Button>
                        </NavLink>
                      )}

                      {getStarted.policyapplicationid !== '' && (
                        <Button
                          size="large"
                          variant="outlined"
                          onClick={() => CommonFunctions.handleExitClick(navigate)}
                          className={classNames(classes.sideBarLinkButton, classes.sideBarNavLink)}
                          startIcon={<Brush />}
                        >
                          Start New Illustration
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        className={classNames(classes.sideBarLinkButton, classes.sideBarNavLink)}
                        startIcon={<SubdirectoryArrowLeft />}
                        href={`${process.env.REACT_APP_DISTRIBUTOR_URL}`}
                        size="large"
                      >
                        Back to Portal
                      </Button>
                      <Divider className={classes.dividerStyle} />
                      <Button
                        size="large"
                        onClick={logOut}
                        variant="outlined"
                        className={classNames(classes.sideBarLinkButton, classes.sideBarNavLink)}
                        startIcon={<img src={LogOut} alt="logo" className={classes.logoutIcon} />}
                      >
                        Log Out
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
              <div className={classes.legalRoot}>
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Link href="https://aspida.com/privacy.html" target="_blank" className={classes.linkStyle}>
                    <img src={whiteAspidaLogo} className={classes.iconStyle} alt="logo" />
                  </Link>
                  <IconButton href="https://linkedin.com/company/aspida-insurance/" target="_blank">
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>
                  <IconButton href="https://www.facebook.com/aspida_ins/" target="_blank">
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Link className={classes.link} href="https://aspida.com/privacy.html" target="_blank">
                    Privacy Policy
                  </Link>
                  <Link className={classes.link} href="https://aspida.com/terms.html" target="_blank">
                    Legal Disclaimer
                  </Link>
                </Box>
                <Typography variant="body2">
                  &copy; {currentYear} Aspida Life Insurance Co.
                  <br />
                  All rights reserved
                </Typography>
              </div>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.box}>
              <Box className={classes.legalRoot}>
                <Link href="https://aspida.com/privacy.html" target="_blank" className={classes.linkStyleIcon}>
                  <img src={aspidalogo} className={classes.iconStyle} alt="logo" />
                </Link>
                <Box>
                  {user !== false && (
                    <div className={classes.collapsedSidebarLinkContainer}>
                      {appConfig.config.illustration_history?.enabled && (
                        <NavLink to="/">
                          {({ isActive }) => (
                            <IconButton
                              className={classNames(classes.linkStyle, isActive ? classes.linkStyleActive : '')}
                            >
                              <History className={classes.icon} />
                            </IconButton>
                          )}
                        </NavLink>
                      )}

                      {getStarted.policyapplicationid !== '' && (
                        <IconButton
                          onClick={() => CommonFunctions.handleExitClick(navigate)}
                          className={classes.linkStyle}
                        >
                          <Brush className={classes.icon} />
                        </IconButton>
                      )}
                      <IconButton
                        href={`${process.env.REACT_APP_DISTRIBUTOR_URL}`}
                        target="_blank"
                        className={classes.linkStyle}
                      >
                        <SubdirectoryArrowLeft className={classes.icon} />
                      </IconButton>
                      <Divider className={classes.dividerStyleIcon} />
                      <IconButton onClick={logOut} className={classes.linkLogoutStyle}>
                        <img src={LogOut} alt="logo" className={classes.logoutIcon} />
                      </IconButton>
                    </div>
                  )}
                </Box>
              </Box>
              <div className={classes.legalRoot}>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Link href="https://aspida.com/privacy.html" target="_blank" className={classes.linkStyle}>
                    <img src={whiteAspidaLogo} className={classes.iconStyle} alt="logo" />
                  </Link>
                  <IconButton href="https://linkedin.com/company/aspida-insurance/" target="_blank">
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>
                  <IconButton href="https://www.facebook.com/aspida_ins/" target="_blank">
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                </Box>
              </div>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
};
