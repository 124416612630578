import { createTheme } from '@material-ui/core';

export const styles = {
  subHeader: {
    fontSize: '28px',
    fontWeight: 700,
    color: '#4d4d4d',
    paddingLeft: '13px',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    paddingLeft: '13px',
    flex: 1,
  },
  searchInput: {
    maxWidth: '479px',
    width: '100%',
  },
  actionButtonsBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'end',
  },
  actionButtonIcon: {
    marginRight: '9px',
  },
  actionButton: {
    maxHeight: '39px',
  },
  searchIcon: {
    width: '24px',
    height: '24px',
    flexShrink: 0,
    fill: 'hsla(0, 0%, 0%, 0.54)',
  },

  contentWrapper: {
    marginRight: '60px',
  },
  searchInputContainer: {
    width: '100%',
    marginRight: '30px',
    display: 'flex',
    alignItems: 'end',
  },
  dataTableContainer: {
    marginTop: '7px',
  },
  tooltipButton: {
    padding: '3px',
  },
};

export const tableTheme = createTheme({
  overrides: {
    MUIDataTableBodyRow: {
      root: {
        '&.mui-row-selected': {
          backgroundColor: 'rgba(234, 231, 241, 1)',
        },
        '&.mui-row-selected:hover': {
          backgroundColor: 'rgba(234, 231, 241, 1)',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        paddingTop: '18px',
        paddingBottom: '18px',
      },
      body: {
        color: 'rgba(70, 70, 70, 1)',
        fontSize: '16px',
        paddingTop: '24px',
        paddingBottom: '24px',
      },
      root: {
        fontFamily: 'Mulish',
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '',
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Mulish',
        fontWeight: '600',
        fontSize: '14px',
      },
    },
    MuiPaper: {
      root: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'hsla(252, 8%, 88%, 1)',
        borderRadius: '4px',
        marginBottom: '30px',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-checked': {
          color: '#14043C',
        },
      },
    },
  },
});
