import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import startIllustration from 'assets/images/start-illustration.svg';
import { AddCircle } from '@material-ui/icons';
import styles from './styles';

const useStyles = makeStyles(styles);
function HistoryNoData() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.componentContainer}>
      <img src={startIllustration} className={classes.brushImage} alt="text" />
      <Box className={classes.textContainer}>
        <span className={classes.messageText}>No illustrations have been made yet.</span>
        <span className={classes.messageText}>Click below to get started!</span>
      </Box>
      <Button
        className={classes.actionButton}
        variant="contained"
        color="secondary"
        onClick={() => navigate('/illustration')}
        disableElevation
      >
        <AddCircle className={classes.actionButtonIcon} />
        CREATE NEW ILLUSTRATION
      </Button>
    </Box>
  );
}

export default HistoryNoData;
