export default {
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  btnCanav: {
    marginLeft: '0.25rem',
  },
  paperHeader: {
    padding: '1rem',
    opacity: 1,
    borderBottom: '0',
  },
  paperBody: {
    padding: '1rem',
    opacity: 1,
    borderTop: '0',
    backgroundColor: '#F8FAFC',
  },
  reviewBtn: {
    textTransform: 'capitalize',
  },
};
