import { combineReducers } from 'redux';
import user from './user/reducer';
import getStarted from './getStarted/reducer';
import appConfig from './appConfig/reducer';

const rootReducer = combineReducers({
  getStarted,
  user,
  appConfig,
});

export default rootReducer;
