const product = {
  msgSynergy2: '0ffab8b9-2c37-477e-9b46-13c16b69e97d',
  msgSynergy3: '766d4649-d0aa-4466-a9e8-051216beb6a2',
  msgSynergy5: 'b79624a5-3ee4-4839-bcef-d44e69b354fc',
  msgSynergy7: '7c1a7a01-a67e-4cf9-bf8a-5b81ece7315c',
  wvMyga2: '03697350-a1a0-4076-a45b-5d40a89368d9',
  wvMyga3: '755ac73a-7888-4995-bef0-f7fdec7b3f8c',
  wvMyga5: 'ac32d8de-0a5b-4b4e-91b0-c69a8c78ee4b',
  wvMyga7: '07d51d61-6f3b-4c8f-b76a-8c64ec473b07',
  wvfia5: '0b7eba54-8671-4ecc-8eb7-ab12b7d59e44',
  wvfia7: '73ce9e0b-319e-4827-9a23-74d6f60b54c0',
  wvfia10: '73a914bb-357d-441f-bdf5-d52eec146abc',
  synergyMax5: 'f5388d2c-44d0-49fb-af11-0001a389fac9',
  synergyBonus10: '3b63257a-d4a4-470f-8c53-29e85a9e918b',
  synergyMax10: '02e03916-1b78-4039-a6ac-e71815c8fbb7',
  synergyIncome10: 'c62e0a9f-70f2-45eb-ac41-bca9c86128e3',
  aaMyga2: '8ffaadf1-e0aa-4a83-b33b-6a57b3cc7eeb',
  aaMyga3: 'dab0896e-8b01-4875-b233-b2f07560e25a',
  aaMyga5: 'da1ff749-e78c-4653-ae97-f6b585d4ce08',
  aaMyga7: 'd3350107-0df6-469c-8bdf-4a3439d36bed',
  wvMygaPlus2: '199d1a17-cfd0-47f7-a538-95508a5d70d7',
  wvMygaPlus3: '38a89d5b-1821-4516-9c04-adb6512c0071',
  wvMygaPlus5: '448967e6-ca66-4174-b55a-0ff309d8864a',
  wvMygaPlus7: '5ee966a7-c4fc-4037-a584-3449cfcb1a33',
};

const jsonFiles = {
  WV_MYGA_Illustration: 'wvmygaIllustration',
  AA_MYGA_Illustration: 'aamygaIllustration',
  MSG_MYGA_Illustration: 'msgmygaIllustration',
  WV_FIA_Illustration: 'wvfiaIllustration',
  MSG_FIA_Illustration: 'msgfiaIllustration',
  MSG_FIA_Income_Illustration: 'msgfiaIncomeIllustration',
};

const personRole = {
  rosterAdmin: '183bf7bf-8355-40e5-a9c5-71cb86ce4bbe',
  reportingAdmin: '7cf90f52-df22-4768-b61b-155fd7f46a6f',
  limitedReportingAdmin: '3deae0b1-e488-4f1c-a72a-596c0131da72',
  producer: '26bbb353-96fb-4c13-ab80-f42634c31619',
  licensingOps: '63dc4c0b-0ce0-4a27-9f94-ae3452484cb8',
  producingPrincipalAgent: '06e30b04-9ea2-4b05-b676-a7492eb8d31c',
  nonProducingPrincipalAgent: 'd4530c12-ebdb-40ed-af5c-bf2f851e8f05',
};

const PRODUCER_ROLE_IDS = [personRole.producer, personRole.producingPrincipalAgent];
const PRINCIPAL_ROLE_IDS = [personRole.producingPrincipalAgent, personRole.nonProducingPrincipalAgent];
const STANDARD_ADMIN_ROLE_IDS = [...PRINCIPAL_ROLE_IDS, personRole.licensingOps, personRole.rosterAdmin];
const REPORTING_ADMIN_ROLE_IDS = [personRole.reportingAdmin, personRole.limitedReportingAdmin];

const schemaCodes = {
  AA_MYGA_Illustration: {
    prefix: 'AA',
    productCode: 'mygaIllustration',
    schemaName: 'wealthLockMygaSchema',
  },
  WV_MYGA_Illustration: {
    prefix: 'WV',
    productCode: 'mygaIllustration',
    schemaName: 'wealthLockMygaSchema',
  },
  MSG_MYGA_Illustration: {
    prefix: 'WV',
    productCode: 'mygaIllustration',
    schemaName: 'wealthLockMygaSchema',
  },
  MSG_FIA_Illustration: {
    prefix: 'FIA',
    productCode: 'wvFIA',
    schemaName: 'fiaSchema',
  },
  MSG_FIA_Income_Illustration: {
    prefix: 'FIA',
    productCode: 'wvFIA',
    schemaName: 'fiaIncomeSchema',
  },
  WV_FIA_Illustration: {
    prefix: 'FIA',
    productCode: 'wvFIA',
    schemaName: 'fiaSchema',
  },
};

const eAppUserRoles = [personRole.producer, personRole.producingPrincipalAgent];

const illustrationUserRoles = [
  personRole.rosterAdmin,
  personRole.reportingAdmin,
  personRole.limitedReportingAdmin,
  personRole.nonProducingPrincipalAgent,
  personRole.producingPrincipalAgent,
  personRole.producer,
];

const nonAllocationFunds = [
  {
    product: product.msgSynergy2,
    allocation: [
      {
        fundCode: 'ASMSGM_2',
        fundFullName: 'Aspida MSG MYGA 2 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.msgSynergy3,
    allocation: [
      {
        fundCode: 'ASMSGM_3',
        fundFullName: 'Aspida MSG MYGA 3 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.msgSynergy5,
    allocation: [
      {
        fundCode: 'ASMSGM_5',
        fundFullName: 'Aspida MSG MYGA 5 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.msgSynergy7,
    allocation: [
      {
        fundCode: 'ASMSGM_7',
        fundFullName: 'Aspida MSG MYGA 7 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMyga2,
    allocation: [
      {
        fundCode: 'ASWVM_2',
        fundFullName: 'Aspida WV MYGA 2 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMyga3,
    allocation: [
      {
        fundCode: 'ASWVM_3',
        fundFullName: 'Aspida WV MYGA 3 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMyga5,
    allocation: [
      {
        fundCode: 'ASWVM_5',
        fundFullName: 'Aspida WV MYGA 5 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMyga7,
    allocation: [
      {
        fundCode: 'ASWVM_7',
        fundFullName: 'Aspida WV MYGA 7 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMygaPlus2,
    allocation: [
      {
        fundCode: 'ASWVM_2',
        fundFullName: 'Aspida WV MYGA 2 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMygaPlus3,
    allocation: [
      {
        fundCode: 'ASWVM_3',
        fundFullName: 'Aspida WV MYGA 3 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMygaPlus5,
    allocation: [
      {
        fundCode: 'ASWVM_5',
        fundFullName: 'Aspida WV MYGA 5 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.wvMygaPlus7,
    allocation: [
      {
        fundCode: 'ASWVM_7',
        fundFullName: 'Aspida WV MYGA 7 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.aaMyga2,
    allocation: [
      {
        fundCode: 'ASWVM_2',
        fundFullName: 'Aspida WV MYGA 2 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.aaMyga3,
    allocation: [
      {
        fundCode: 'ASWVM_3',
        fundFullName: 'Aspida WV MYGA 3 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.aaMyga5,
    allocation: [
      {
        fundCode: 'ASWVM_5',
        fundFullName: 'Aspida WV MYGA 5 Yr',
        allocPercent: 100,
      },
    ],
  },
  {
    product: product.aaMyga7,
    allocation: [
      {
        fundCode: 'ASWVM_7',
        fundFullName: 'Aspida WV MYGA 7 Yr',
        allocPercent: 100,
      },
    ],
  },
];

const appFields = {
  allocation1P: '#allocation1P',
  allocation2P: '#allocation2P',
  allocation3P: '#allocation3P',
  allocation4P: '#allocation4P',
  allocation5P: '#allocation5P',
  allocation6P: '#allocation6P',
  allocation7P: '#allocation7P',
  allocation8P: '#allocation8P',
  allocation9P: '#allocation9P',
  allocation10P: '#allocation10P',
  allocation11P: '#allocation11P',
  allocation12P: '#allocation12P',
  allocationMSG2: '#allocationMSG2',
  allocationMSG3: '#allocationMSG3',
  allocationMSG5: '#allocationMSG5',
  allocationMSG7: '#allocationMSG7',
  allocationWV2: '#allocationWV2',
  allocationWV3: '#allocationWV3',
  allocationWV5: '#allocationWV5',
  allocationWV7: '#allocationWV7',
  allocationAA2: '#allocationAA2',
  allocationAA3: '#allocationAA3',
  allocationAA5: '#allocationAA5',
  allocationAA7: '#allocationAA7',
  signedState: '#signedState',
  signedStateId: '#signedStateId',
  applicationtype: '#applicationtype',
  product: '#product',
  productText: 'product',
  applicationTypeName: '#applicationTypeName',
  productName: '#productName',
  issueYear: '#issueYear',
  applicationDate: '#applicationDate',
  writingAgentWritingCode: '#writingAgentWritingCode',
  applicationId: '#applicationId',
  otherAgentName: '#otherAgentName',
  forOtherAgent: '#forOtherAgent',
  plannedValue: '#plannedValue',
  plannedValueString: '#plannedValueString',
  fiaHypotheticalValuesTable: '#fiaHypotheticalValuesTable',
  fiaGuaranteedValuesTable: '#fiaGuaranteedValuesTable',
  fiaHistIndexComparisonTable: '#fiaHistIndexComparisonTable',
  fiaAdditionalBenefits: '#fiaAdditionalBenefits',
  fiaMonthlyLifeTimeTable: '#fiaMonthlyLifeTimeTable',
  fiaStrategyDetailsSummary: '#fiaStrategyDetailsSummary',
  monthlyTableData: '#monthlyTableData',
  numericTable: '#numericTable',
  withdrawalCharges: '#withdrawalCharges',
  fiaTotals: '#fiaTotals',
  policyPrintFormName: '#policyPrintFormName',
  formOrder: '#formOrder',
  totalAllocation: '#totalAllocation',
  residentStateId: '#residentStateId',
  residentState: '#residentState',
  SplitAgentData: '#SplitAgentData',
  ifSplitCommission: '#ifSplitCommission',
  splitAgentTotalPercentage: '#splitAgentTotalPercentage',
  splitAgent1AppointedFlag: '#splitAgent1AppointedFlag',
  splitAgent2AppointedFlag: '#splitAgent2AppointedFlag',
  splitAgent3AppointedFlag: '#splitAgent3AppointedFlag',
  fiaSupplementalTable: '#fiaSupplementalTable',
  fiaHistoricTable: '#fiaHistoricTable',
  historicTable: '#historicTable',
  supplementalHistoricalTable: '#supplementalHistoricalTable',
  fiaSupplementalHistoricTable: '#fiaSupplementalHistoricTable',
  fundStrategySummarySection: '#fundStrategySummarySection',
  fundStrategySummaryIncome: '#fundStrategySummaryIncome',
  lifetimePercentageTable: '#lifetimePercentageTable',
  rollupRates: '#rollupRates',
  numericTableTotal: '#numericTableTotal',
  withdrawalSchedule: '#withdrawalSchedule',
  riderAdditionalBenefits: '#riderAdditionalBenefits',
  recent: 'Recent',
  high: 'High',
  low: 'Low',
  allocationCount: 28,
  allocationPercent: 'allocation__P',
  allocationFundCode: 'allocation__FundCode',
  allocation: 'allocation__',
};

const nonallocationList = [
  appFields.allocationMSG2,
  appFields.allocationMSG3,
  appFields.allocationMSG5,
  appFields.allocationMSG7,
  appFields.allocationWV2,
  appFields.allocationWV3,
  appFields.allocationWV5,
  appFields.allocationWV7,
  appFields.allocationAA2,
  appFields.allocationAA3,
  appFields.allocationAA5,
  appFields.allocationAA7,
];

const analyticsCollection = {
  noResponse: 'cd930b35-7954-4b33-bf74-bfa0b141ccda',
  accepted: 'bb0149eb-3df7-4104-83ce-37b94e393d47',
  optOut: '0447a80f-33dc-4ccb-9ed3-b9b8bf85d524',
};

const policyOption = [
  {
    product: [
      product.wvMyga2,
      product.wvMyga3,
      product.wvMyga5,
      product.wvMyga7,
      product.aaMyga2,
      product.aaMyga3,
      product.aaMyga5,
      product.aaMyga7,
      product.wvfia5,
      product.wvfia7,
      product.wvfia10,
      product.synergyMax5,
      product.synergyBonus10,
      product.synergyMax10,
      product.synergyIncome10,
      product.wvMygaPlus2,
      product.wvMygaPlus3,
      product.wvMygaPlus5,
      product.wvMygaPlus7,
    ],
    policy: 'SPDA',
  },
  {
    product: [product.msgSynergy2, product.msgSynergy3, product.msgSynergy5, product.msgSynergy7],
    policy: 'synergySPDA',
  },
];

const popTabName = ['Beneficiaries', 'Funding', 'State Replacement'];
const upperCutOffAge = [0];
const radioTrueValue = [
  'Yes',
  'Yes. The spouse of the Owner will be the sole Primary Beneficiary.',
  'I do have conflicts of interest regarding this transaction',
  'Yes, mail to Producer',
  'By Client or Producer',
];
const radioFalseValue = [
  'No',
  // eslint-disable-next-line max-len
  'No. The spouse of the Owner has been made aware of and consents that they will not be the sole Primary Beneficiary of the contract. The beneficiary(ies) will be named in the Beneficiary section of this application. At the death of the Owner before the Annuity Start Date, any death benefit will be paid to the name beneficiary(ies). At the death of the Owner after the Annuity Start Date, any death benefit will be paid according to the annuitization option elected.',
  'I do not have any conflicts of interest regarding this transaction',
  'No, save the trees',
  'By Aspida',
];
const applicationStatusId = {
  inProgress: '08932f71-7b2c-4e21-a14a-de8932e575f5',
  cancelled: '3a59c079-a8c0-452c-95e1-e604a31173c9',
  newBusinessReview: 'f7864b73-57ad-49af-b1bd-ebbfc51dd596',
  deleted: '553051b6-8209-4084-8b75-8ecd4ddce6ae',
  approved: '652d00e3-e242-4a12-b401-063b415ac2b6',
  approvedWithModifiedAmount: '5f3d6a7c-5e08-43f0-ba61-5b8149c95bac',
  reviewComplete: '388b83ba-d301-4b40-94aa-397f95d3bf11',
  withdrawn: 'ea2f09ad-143e-40fd-9582-ae491c2fff02',
  denied: '91e64510-4bd2-4741-9504-c144f550ef90',
  backOfficePending: '4753b44e-deb4-46bf-ac23-88373ca32a2c',
  backOfficeDeclined: '11b9eafa-7f4a-476c-a901-47a4bb30ce60',
  backOfficeApproved: '3bc429f0-6263-44ff-8f7a-c2c63f67c966',
  issued: '875b9829-9275-4683-b30d-6f269e4c9748',
};
const FieldListIndex = [1, 2, 3, 4, 5, 6];
export default {
  FieldListIndex,
  applicationStatusId,
  upperCutOffAge,
  popTabName,
  radioFalseValue,
  radioTrueValue,
  product,
  jsonFiles,
  schemaCodes,
  personRole,
  eAppUserRoles,
  illustrationUserRoles,
  appFields,
  analyticsCollection,
  policyOption,
  nonAllocationFunds,
  nonallocationList,
  PRODUCER_ROLE_IDS,
  PRINCIPAL_ROLE_IDS,
  STANDARD_ADMIN_ROLE_IDS,
  REPORTING_ADMIN_ROLE_IDS,
};
