import React from 'react';
import _ from 'lodash';
import store from 'redux/store';
import dayjs from 'dayjs';
import {
  setCurrentPage,
  setErrorLists,
  setFastData,
  setInputErrors,
  setInputValues,
  setJSONFilename,
  setLoading,
  setReset,
  setTrackerGroupName,
} from 'redux/getStarted/actions';
import MasterService from 'api/masterService';
import { Tooltip } from '@aspida/react-components';
import Jsonfiles from 'assets/JSONFiles';
import Constants from 'constants/common';
import defaultProps from 'assets/JSONFiles/default.json';
import defaultActionProps from 'assets/JSONFiles/defaultAction.json';
import NotificationService from 'services/notificationService';

const renderHTML = rawHTML => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

const ageCalc = (inputDOB, annuitantAge) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  inputData[annuitantAge] = 0;
  const age = dayjs().diff(inputData[inputDOB], 'years', false);
  inputData[annuitantAge] = age;
};

const validateStartPolicy = (inputDOB, startPolicyYear, startPolicyMaxYear) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  inputData[startPolicyYear] = 0;
  inputData[startPolicyMaxYear] = 91;
  const age = dayjs().diff(inputData[inputDOB], 'years', false);
  if (age < 51) {
    inputData[startPolicyYear] = 51 - age;
  }
  if (age < 91) {
    inputData[startPolicyMaxYear] = 91 - age;
  }
};

/**
 * Handles the "Start new illustration" button
 *
 * @param {NavigateFunction} navigate
 */
const handleExitClick = navigate => {
  const state = store.getState();
  store.dispatch(setReset(state.getStarted));
  sessionStorage.removeItem('IllustrationPolicyData');
  const enfastData = { resultset: {}, issuccess: false, isFastRun: false, isError: false };
  store.dispatch(setFastData(enfastData));
  navigate('/illustration');
};

const tooltipMessageFn = (tooltipTextVal, tooltipStyle) => {
  return (
    <sup>
      <Tooltip message={tooltipTextVal} className={tooltipStyle} placement="center" />
    </sup>
  );
};

const checkIsNullOrUndefined = val => {
  const state = store.getState();
  let checkValue = val;
  if (checkValue === null || checkValue === undefined) {
    return '';
  }
  if (String(checkValue).indexOf('#') === 0) {
    checkValue = state.getStarted.inputvalues[checkValue];
    if (checkValue === null || checkValue === undefined) {
      return '';
    }
  }
  return checkValue;
};

const setDefaultValues = JSONContent => {
  const state = store.getState();
  const illustrationData = state.getStarted.inputvalues;
  JSONContent.forEach(group => {
    if (group.fieldProperty.defaultValue != null) {
      if (checkIsNullOrUndefined(illustrationData[group.fieldName]) === '') {
        illustrationData[group.fieldName] = group.fieldProperty.defaultValue;
      }
    }
  });
  store.dispatch(setInputValues(illustrationData));
};

function getNextPageContent(groupIndex, subGroupIndex, trackerGroupNames) {
  try {
    const jsonFileName = trackerGroupNames[groupIndex].SubGroup[subGroupIndex].$ref;
    const jsonFileNameWithoutExt = jsonFileName.replace('.json', '');
    setDefaultValues(Jsonfiles[jsonFileNameWithoutExt][jsonFileNameWithoutExt]);
    return Jsonfiles[jsonFileNameWithoutExt][jsonFileNameWithoutExt];
  } catch (error) {
    return '';
  }
}
const setJSONGroupName = specification => {
  const state = store.getState();
  const jsonfileName = specification.illustrationSpec;
  const mailRootName = jsonfileName.replace('.json', '');
  const illustrationFileName = Constants.jsonFiles[mailRootName];
  const jsonData = Jsonfiles[illustrationFileName];

  if (jsonData === undefined) {
    store.dispatch(setLoading(false));
    return;
  }
  const groupNames = Object.entries(jsonData[mailRootName]).map(([key, value]) => {
    const obj = Object.entries(value).reduce((acc, [subKey, subValue]) => ({ ...acc, [subKey]: subValue }), {
      [key]: value,
    });
    obj.SubGroup = {};
    return obj;
  });

  groupNames.forEach((obj, index) => {
    let subGroupNames = [];
    const rootName = obj.$ref.replace('.json', '');
    const subJsonData = Jsonfiles[rootName];
    if (obj.$ref !== '') {
      subGroupNames = Object.entries(subJsonData[rootName]).map(([key, value]) => {
        const objSubGroup = Object.entries(value).reduce(
          (acc, [subKey, subValue]) => ({ ...acc, [subKey]: subValue }),
          { [key]: value },
        );
        objSubGroup.SubGroup = {};
        return objSubGroup;
      });
    }
    groupNames[index].SubGroup = subGroupNames;
  });

  store.dispatch(setTrackerGroupName(groupNames));

  const jsonContent = getNextPageContent(
    state.getStarted.currentpage.Group,
    state.getStarted.currentpage.SubGroup,
    groupNames,
  );
  const currentPage = {
    Group: state.getStarted.currentpage.Group,
    SubGroup: state.getStarted.currentpage.SubGroup,
    JsonData: jsonContent,
  };
  sessionStorage.setItem('IllustrationPolicyData', JSON.stringify(state.getStarted));

  store.dispatch(setCurrentPage(currentPage));
  store.dispatch(setLoading(false));
};

const getSpecificationName = async () => {
  const state = store.getState();
  const inputs = {
    applicationTypeId: state.getStarted.applicationtype,
    productId: state.getStarted.product,
    signStateId: state.getStarted.signedstateid,
    hasMasterSellingAgreement: false,
  };
  const query = Object.entries(inputs)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
  const [specerror, specification] = await MasterService.getSpecificationName(query);
  if (specerror !== null) {
    NotificationService.error(specerror.data.message);
    store.dispatch(setLoading(false));
    return;
  }
  if (specification === undefined) {
    NotificationService.error('Unable to load specification.');
    store.dispatch(setLoading(false));
    return;
  }
  store.dispatch(setJSONFilename(specification.illustrationSpec));
  setJSONGroupName(specification);
  document.getElementById('divContainer').scrollTop = 0;
  window.scrollTo(0, 0);
};

const calculateAllocPercentage = (totalAllocationPercent, allocationNames) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;

  inputData[totalAllocationPercent] = 0.0;
  inputErrorData[totalAllocationPercent] = '';

  allocationNames.forEach(allocation => {
    if (checkIsNullOrUndefined(inputData[allocation]) !== '') {
      inputData[totalAllocationPercent] =
        parseFloat(inputData[totalAllocationPercent].toFixed(2)) + parseFloat(inputData[allocation]);
    }
  });
  if (
    inputData[totalAllocationPercent] !== 0 &&
    Number.isNaN(parseFloat(inputData[totalAllocationPercent], 0)) === false
  ) {
    if (inputData[totalAllocationPercent] !== 100) {
      inputErrorData[totalAllocationPercent] = 'Allocation percentage must be 100%';
    }
  }
};

const calculatePercentage = (totalPrimaryPercent, totalContingentPercent, beneficiaryType, benePercent, totalBeni) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;

  inputData[totalContingentPercent] = 0;
  inputData[totalPrimaryPercent] = 0;
  inputErrorData[totalPrimaryPercent] = '';
  inputErrorData[totalContingentPercent] = '';

  for (let count = 1; count <= totalBeni; count += 1) {
    if (checkIsNullOrUndefined(inputData[beneficiaryType + count]) === 1) {
      inputData[totalPrimaryPercent] =
        parseInt(inputData[totalPrimaryPercent], 0) + parseInt(inputData[benePercent + count], 0);
    }
    if (checkIsNullOrUndefined(inputData[beneficiaryType + count]) === 2) {
      inputData[totalContingentPercent] =
        parseInt(inputData[totalContingentPercent], 0) + parseInt(inputData[benePercent + count], 0);
    }
  }
  if (inputData[totalPrimaryPercent] !== 0 && Number.isNaN(parseInt(inputData[totalPrimaryPercent], 0)) === false) {
    if (inputData[totalPrimaryPercent] !== 100) {
      inputErrorData[totalPrimaryPercent] = 'Percentage must be 100%';
    }
  }
  if (
    inputData[totalContingentPercent] !== 0 &&
    Number.isNaN(parseInt(inputData[totalContingentPercent], 0)) === false
  ) {
    if (inputData[totalContingentPercent] !== 100) {
      inputErrorData[totalContingentPercent] = 'Percentage must be 100%';
    }
  }
};

const getMYAFIAProducts = (allProducts, getProductContracts) => {
  const state = store.getState();
  const tempProducts = [];
  const applicationData = state.getStarted;
  if (checkIsNullOrUndefined(allProducts) === '') {
    return;
  }
  allProducts.forEach(prod => {
    const isProductPresent = getProductContracts.find(x => x === prod.id);
    if (isProductPresent) {
      tempProducts.push({ id: prod.id, value: prod.description, productTypeAbbr: prod.productTypeAbbr });
    }
  });
  const isMYAPresent = tempProducts.filter(x => x.productTypeAbbr === 'MYA');
  const isFIAPresent = tempProducts.filter(x => x.productTypeAbbr === 'FIA');
  if (isMYAPresent.length > 0 && isFIAPresent.length > 0) {
    applicationData.inputvalues['#isMYAFIAPresent'] = 'MYAFIA';
  } else if (isMYAPresent.length > 0) {
    applicationData.inputvalues['#isMYAFIAPresent'] = 'MYA';
  } else {
    applicationData.inputvalues['#isMYAFIAPresent'] = 'FIA';
  }
};

const hideSplButton = (itm, responseData, resErrorData) => {
  const splitedText = itm.split('||');
  if (document.getElementById(`grd${splitedText[0]}`) != null) {
    document.getElementById(`grd${splitedText[0]}`).style.display = 'none';
    if (document.getElementsByName(splitedText[0]).length > 0) {
      responseData[splitedText[0]] = ''; // eslint-disable-line
      resErrorData[splitedText[0]] = ''; // eslint-disable-line
    }
  }
};

const executeFieldValidation = (fieldValidation, fieldName, isStore, group, subGroup) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;
  const errorLists = state.getStarted.errorlists;
  let groupIndex = group;
  let subGroupIndex = subGroup;
  if (checkIsNullOrUndefined(groupIndex) === '') {
    groupIndex = state.getStarted.currentpage.Group;
  }
  if (checkIsNullOrUndefined(subGroupIndex) === '') {
    subGroupIndex = state.getStarted.currentpage.SubGroup;
  }

  fieldValidation.every(validation => {
    let checkBool = false;
    let checkString = '';
    validation.condition.forEach(condition => {
      checkBool = false;
      switch (condition.operator) {
        case 'equal':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) ===
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'notequal':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) !==
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'lessthan':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) <
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'lessthanequal':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) <=
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'greaterthan':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) >
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'validate':
          checkBool = !dayjs(checkIsNullOrUndefined(inputData[condition.leftVariable])).isValid();
          break;
        case 'greaterthandt':
          if (new Date(inputData[condition.leftVariable]) < new Date(condition.rightVariable[0])) {
            checkBool = true;
          }
          break;
        case 'lessthandt':
          if (new Date(inputData[condition.leftVariable]) > new Date(condition.rightVariable[0])) {
            checkBool = true;
          }
          break;
        case 'greatedthanequal':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) >=
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'in':
          condition.rightVariable.every(itm => {
            if (itm === inputData[condition.leftVariable]) {
              checkBool = true;
              return false;
            }
            return true;
          });
          break;
        case 'sub': {
          let rightVariable1 = 0;
          let rightVariable2 = 0;
          inputData[condition.leftVariable] = 0;
          if (
            checkIsNullOrUndefined(condition.rightVariable[0]) !== '' &&
            Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0)) === false
          ) {
            rightVariable1 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0);
          }
          if (
            checkIsNullOrUndefined(condition.rightVariable[1]) !== '' &&
            Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0)) === false
          ) {
            rightVariable2 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0);
          }
          inputData[condition.leftVariable] = rightVariable1 - rightVariable2;
          checkBool = true;
          break;
        }
        case 'notin': {
          const foundCount = condition.rightVariable.filter(itm => itm === inputData[condition.leftVariable]).length;
          if (foundCount === 0) {
            checkBool = true;
          }
          break;
        }
        case 'clicktriggered':
          checkBool = true;
          break;
        default:
          break;
      }
      checkString += String(checkBool);
    });
    if (checkString.indexOf('false') === -1) {
      if (validation.action.show !== undefined) {
        validation.action.show.forEach(itm => {
          if (document.getElementById(`grd${itm}`) != null)
            document.getElementById(`grd${itm}`).style.display = 'block';
        });
      }
      if (validation.action.hide !== undefined) {
        validation.action.hide.forEach(itm => {
          if (String(itm).indexOf('||') !== -1) {
            hideSplButton(itm, inputData, inputErrorData);
          }
          if (document.getElementById(`grd${itm}`) != null) {
            document.getElementById(`grd${itm}`).style.display = 'none';
            if (document.getElementsByName(itm).length > 0) {
              if (document.getElementsByName(itm)[0].type !== 'button') {
                inputData[itm] = '';
                inputErrorData[itm] = '';
              }
            }
          }
        });
      }
      if (validation.action.serviceCall !== null) {
        switch (validation.action.serviceCall) {
          case 'calculatePercentage':
            calculatePercentage(
              validation.action.param.totalPrimaryPercent,
              validation.action.param.totalContingentPercent,
              validation.action.param.beneficiaryType,
              validation.action.param.benePercent,
              validation.action.param.totalBeni,
            );
            break;
          case 'ageCalc':
            ageCalc(validation.action.param.inputDoB, validation.action.param.calculatedAge);
            break;
          case 'validateStartPolicy':
            validateStartPolicy(
              validation.action.param.inputDoB,
              validation.action.param.policyCutOff,
              validation.action.param.policyMaxCutOff,
            );
            break;
          case 'calculateAllocPercentage':
            calculateAllocPercentage(
              validation.action.param.totalAllocationPercent,
              validation.action.param.allocPercentage,
            );
            break;
          default:
            break;
        }
      }
      if (validation.action.set !== undefined) {
        validation.action.set.forEach(itm => {
          inputData[itm.to] = checkIsNullOrUndefined(itm.from);
        });
      }
      if (isStore) {
        inputErrorData[fieldName] = checkIsNullOrUndefined(validation.action.errorMsg);
        if (
          checkIsNullOrUndefined(validation.action.errorMsg) !== '' &&
          groupIndex !== undefined &&
          subGroupIndex !== undefined
        ) {
          const trackerGroupNames = state.getStarted.trackergroupnames;

          trackerGroupNames[groupIndex].SubGroup[subGroupIndex].isPageValid = false;
          store.dispatch(setTrackerGroupName(trackerGroupNames));
          if (errorLists.findIndex(p => p.widgetName === fieldName) === -1) {
            errorLists.push({
              groupIndex,
              subGroupIndex,
              errorMessage: validation.action.errorMsg,
              widgetName: fieldName,
            });
          }
        }
      }
      if (checkIsNullOrUndefined(validation.action.errorMsg) !== '') return false;
    }
    if (isStore) {
      const errorObjectIndex = errorLists.findIndex(p => p.widgetName === fieldName);
      if (errorObjectIndex !== -1) {
        errorLists.splice(errorObjectIndex, 1);
      }
      inputErrorData[fieldName] = '';
    }
    return true;
  });
  if (isStore) {
    store.dispatch(setInputValues(inputData));
    store.dispatch(setInputErrors(inputErrorData));
    store.dispatch(setErrorLists(errorLists));
  }
};

const fillFieldDefaults = field => {
  const filledField = _.merge({}, defaultProps, field);

  if (filledField.fieldValidation) {
    if (filledField.fieldValidation.apiCall) {
      filledField.fieldValidation.apiCall.action = _.assign(
        {},
        defaultActionProps,
        filledField.fieldValidation.apiCall.action,
      );

      filledField.fieldValidation.conditions.forEach(validation => {
        // eslint-disable-next-line no-param-reassign
        validation.action = _.assign({}, defaultActionProps, validation.action);
      });
    } else {
      filledField.fieldValidation.forEach(validation => {
        // eslint-disable-next-line no-param-reassign
        validation.action = _.assign({}, defaultActionProps, validation.action);
      });
    }
  }

  return filledField;
};

const allocationRequired = () => {
  return (
    <p style={{ margin: 0, fontWeight: 'bold' }}>
      <span style={{ color: 'red' }}>*</span>Allocation %
    </p>
  );
};

const setSignedStatesEvent = (value, allStates) => {
  if (!allStates.data) {
    return [];
  }

  return allStates.data
    .filter(state => state.approvedProducts.find(x => x === value))
    .map(states => {
      return {
        id: states.id.concat('~', states.abbreviation),
        value: states.name,
      };
    });
};

export default {
  checkIsNullOrUndefined,
  executeFieldValidation,
  getSpecificationName,
  fillFieldDefaults,
  getMYAFIAProducts,
  allocationRequired,
  handleExitClick,
  setSignedStatesEvent,
  tooltipMessageFn,
  renderHTML,
};
