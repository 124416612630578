import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import CommonFunctions from 'views/illustration/utils/commonFunctions';
import Alert from '@material-ui/lab/Alert';

export default function ConstantNotification(props) {
  const useStyles = makeStyles({
    alertStyles: {
      '& .MuiAlert-icon': {
        color: `${props.group.fieldProperty.styles.labelFontColor}`,
      },
      display: 'flex',
      flexDirection: 'row',
      textTransform: `${props.group.fieldProperty.styles.labelFontCase}`,
      margin: '0 12rem 0 12rem',
      '@media (max-width: 1300px)': {
        margin: '0 7rem 0 7rem',
      },
      '@media (min-width: 2450px)': {
        margin: '0 32rem 0 32rem',
      },
      '@media (max-width: 960px)': {
        margin: '0',
      },
    },
  });
  const classes = useStyles();

  return (
    <Alert
      className={classes.alertStyles}
      severity={props.group.fieldProperty.severity}
      action={
        props.group.fieldProperty.gotoText ? (
          <Button color="inherit" size="small" endIcon={<ChevronRight />}>
            {props.group.fieldProperty.gotoText}
          </Button>
        ) : (
          undefined
        )
      }
      style={{
        backgroundColor: `${props.group.fieldProperty.styles.backgroundColor}`,
        color: `${props.group.fieldProperty.styles.labelFontColor}`,
        borderRadius: `${props.group.fieldProperty.styles.borderRadius}rem`,
        fontWeight: `${props.group.fieldProperty.styles.labelFontWeight}`,
        fontSize: `${props.group.fieldProperty.styles.labelFontSize}em`,
        marginRight: `${props.group.fieldProperty.styles.marginRight}em`,
        marginLeft: `${props.group.fieldProperty.styles.marginLeft}em`,
      }}
    >
      {props.group.fieldProperty.isHtml
        ? CommonFunctions.renderHTML(props.group.fieldProperty.mainText)
        : props.group.fieldProperty.mainText}
    </Alert>
  );
}

ConstantNotification.propTypes = {
  group: PropTypes.object,
};
