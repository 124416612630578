import React from 'react';
import { Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import styles from './style';

const useStyles = makeStyles(styles);

const EditModal = ({ editVisible, onYesEditClose, onNoEditClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={editVisible} aria-labelledby="form-dialog-title">
      <IconButton aria-label="close" title="Close" className={classes.modalClear} onClick={onNoEditClose}>
        <ClearIcon />
      </IconButton>
      <DialogContent className={classes.modal}>
        <Typography variant="h4" className={classes.modalTitle}>
          Are you sure?
        </Typography>
        <Typography variant="body1" className={classes.modalText}>
          Editing this application will <b>remove all</b> uploaded documents and <b>clear</b> e-signatures.
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          className={classes.modalButton}
          onClick={onYesEditClose}
        >
          Yes, Continue
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          size="large"
          className={classes.modalButton}
          onClick={onNoEditClose}
        >
          No, Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default EditModal;
EditModal.propTypes = {
  onNoEditClose: PropTypes.func,
  onYesEditClose: PropTypes.func,
  editVisible: PropTypes.bool,
};
