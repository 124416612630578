export default {
  checkStylebefore: {
    borderRadius: '25px',
    border: '2px solid #8c98a8',
    padding: '7px',
    textWrap: 'nowrap',
    fontSize: '14px',
    fontWeight: '300',
    color: '#4D4D4D',
    backgroundColor: '#FFF',
    paddingLeft: '18px',
    paddingTop: '0px',
  },
  checkStyleafter: {
    borderRadius: '25px',
    border: '2px solid #3f536e',
    padding: '7px',
    textWrap: 'nowrap',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#4D4D4D',
    backgroundColor: '#8a829e6e',
    paddingLeft: '18px',
    paddingTop: '0px',
  },
  labelTextbefore: {
    color: '#3f536e',
  },
  labelTextafter: {
    color: '#3f536e',
    fontWeight: 600,
  },
  iconbefore: {
    color: '#909cac',
  },
  iconafter: {
    color: '#3b4f6b',
  },
  spanRed: {
    color: '#ef4b76',
    fontWeight: 600,
  },
};
