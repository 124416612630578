export default {
  checkStylebefore: {
    borderRadius: '14px',
    border: '1px solid #4D4D4D',
    padding: '7px',
    textWrap: 'wrap',
    backgroundColor: '#fff',
    paddingRight: '14px',
    '@media (max-width: 800px)': {
      marginBottom: '.5rem',
    },
  },
  checkStyleafter: {
    borderRadius: '14px',
    border: '1px solid #4D4D4D',
    padding: '7px',
    textWrap: 'wrap',
    fontWeight: 'bold',
    backgroundColor: '#D0CADC',
    paddingRight: '14px',
    '@media (max-width: 800px)': {
      marginBottom: '.5rem',
    },
  },
  labelTextbefore: {
    color: '#3f536e',
  },
  labelTextafter: {
    color: '#3f536e',
    fontWeight: 600,
  },
  iconbefore: {
    width: '20px',
  },
  iconafter: {
    color: '#4a4545',
    width: '20px',
  },
  spanRed: {
    color: '#ef4b76',
    fontWeight: 600,
  },
  groupRoot: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    '@media (max-width: 800px)': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
  },
};
