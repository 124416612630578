const getLength = (TrackerSource, index, filterValue) => {
  return TrackerSource[index].SubGroup.filter(p => p.isPageValid === filterValue).length;
};

const calculateCompletePercentage = TrackerSource => {
  let totalTabCount = 0;
  let validTabCount = 0;
  let percentage = 0;
  TrackerSource.forEach((grpName, index) => {
    totalTabCount += grpName.SubGroup.length;
    validTabCount += getLength(TrackerSource, index, true);
  });

  percentage = (validTabCount / totalTabCount) * 100;
  return !percentage ? 0 : Math.round(percentage, 0);
};

export default {
  getLength,
  calculateCompletePercentage,
};
