import React from 'react';
import PropTypes from 'prop-types';
import store from 'redux/store';
import { makeStyles, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import CommonFunctions from 'views/illustration/utils/commonFunctions';
import styles from './style';

const useStyles = makeStyles(styles);

export default function Review(props) {
  const classes = useStyles();

  const getGroupIndex = tabName => {
    const state = store.getState();
    const groupValue = [];
    if (state.getStarted.trackergroupnames.length > 0) {
      state.getStarted.trackergroupnames.forEach((group, index) => {
        if (group.displayname === tabName) {
          groupValue.push({ Id: index, Value: 0 });
        }
        state.getStarted.trackergroupnames[index].SubGroup.forEach((subGroup, subGroupIndex) => {
          if (subGroup.displayname === tabName) {
            groupValue.push({ Id: index, Value: subGroupIndex });
          }
        });
      });
    }
    return groupValue;
  };

  const getReviewLabelButton = (widget, group) => {
    if (widget === 'reviewlabel')
      return (
        <React.Fragment key={group.fieldName}>
          {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
          <Grid
            key={group.fieldName}
            item
            id={`grd${group.fieldName}`}
            style={{
              marginTop: `${
                group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
              }rem`,
            }}
            xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
            sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
            md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 4}
            lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 4}
          >
            <Typography
              variant={group.fieldProperty.styles.labelFontVariant}
              gutterBottom
              style={{
                color: group.fieldProperty.styles.labelFontColor,
                fontWeight: group.fieldProperty.styles.labelFontWeight,
                marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
              }}
            >
              {group.fieldProperty.labelText}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    if (widget === 'reviewbutton')
      return (
        <Grid
          key={group.fieldName}
          item
          id={`grd${group.fieldName}`}
          style={{
            marginTop: `${
              group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
            }rem`,
            textAlign: `${
              group.fieldProperty.styles.textAlign !== undefined ? group.fieldProperty.styles.textAlign : 0
            }`,
          }}
          xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
          sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 12}
          md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 12}
          lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 12}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={event => {
              const groupInd = getGroupIndex(group.fieldProperty.infieldLabelText);
              props.onTabClick(event, groupInd[0].Id, groupInd[0].Value);
            }}
            value={group.fieldProperty.labelText}
            disabled={!group.fieldProperty.isEditable}
            name={group.fieldName}
            className={classes.reviewBtn}
            startIcon={<Edit />}
            size="small"
          >
            {group.fieldProperty.labelText}
          </Button>
        </Grid>
      );
    return '';
  };

  const getReviewBox = (widget, group) => {
    if (widget === 'reviewbox')
      return (
        <React.Fragment key={group.fieldName}>
          {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
          <Grid
            item
            id={`grd${group.fieldName}`}
            className="reviewbox"
            style={{
              marginTop: `${
                group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
              }rem`,
              backgroundColor: group.fieldProperty.styles.backgroundColor || '#000',
              paddingRight: `${
                group.fieldProperty.styles.paddingRight !== undefined ? group.fieldProperty.styles.paddingRight : 0
              }rem`,
              paddingLeft: `${
                group.fieldProperty.styles.paddingLeft !== undefined ? group.fieldProperty.styles.paddingLeft : 0
              }rem`,
              borderRightStyle: `${
                group.fieldProperty.styles.borderRightStyle !== undefined
                  ? group.fieldProperty.styles.borderRightStyle
                  : 'hidden'
              }`,
              marginBottom: `${
                group.fieldProperty.styles.marginBottom !== undefined ? group.fieldProperty.styles.marginBottom : 0
              }rem`,
              height: `${group.fieldProperty.styles.height !== undefined && group.fieldProperty.styles.height}rem`,
            }}
            xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
            sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
            md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
            lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
          >
            <TextField
              id={`txt${group.fieldName}`}
              label={group.fieldProperty.infieldLabelText}
              name={group.fieldName}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled={!group.fieldProperty.isEditable}
              value={props.Values[group.fieldName] || ''}
            />
          </Grid>
        </React.Fragment>
      );
    return '';
  };

  const getSwitchCaseLabelButton = group => {
    switch (props.widgetType) {
      case 'start': {
        const { startWidgetType } = group.fieldProperty;
        return getReviewLabelButton(startWidgetType, group);
      }
      case 'yourannuity': {
        const { yourAnnuityWidgetType } = group.fieldProperty;
        return getReviewLabelButton(yourAnnuityWidgetType, group);
      }
      case 'owner': {
        const { ownerWidgetType } = group.fieldProperty;
        return getReviewLabelButton(ownerWidgetType, group);
      }
      case 'jowner': {
        const { jownerWidgetType } = group.fieldProperty;
        return getReviewLabelButton(jownerWidgetType, group);
      }
      case 'allocation': {
        const { allocationWidgetType } = group.fieldProperty;
        return getReviewLabelButton(allocationWidgetType, group);
      }
      default:
        return '';
    }
  };

  const getSwitchCaseBox = group => {
    switch (props.widgetType) {
      case 'start': {
        const { startWidgetType } = group.fieldProperty;
        return getReviewBox(startWidgetType, group);
      }
      case 'yourannuity': {
        const { yourAnnuityWidgetType } = group.fieldProperty;
        return getReviewBox(yourAnnuityWidgetType, group);
      }
      case 'owner': {
        const { ownerWidgetType } = group.fieldProperty;
        return getReviewBox(ownerWidgetType, group);
      }
      case 'jowner': {
        const { jownerWidgetType } = group.fieldProperty;
        return getReviewBox(jownerWidgetType, group);
      }
      case 'allocation': {
        const { allocationWidgetType } = group.fieldProperty;
        return getReviewBox(allocationWidgetType, group);
      }
      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.btnCanav}>
        <Paper className={classes.paperHeader} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <React.Fragment>
              {props.JsonData !== '' &&
                props.JsonData.map(fieldGroup => {
                  const group = CommonFunctions.fillFieldDefaults(fieldGroup);
                  return getSwitchCaseLabelButton(group);
                })}
            </React.Fragment>
          </Grid>
        </Paper>
        <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <React.Fragment>
              {props.JsonData !== '' &&
                props.JsonData.map(fieldGroup => {
                  const group = CommonFunctions.fillFieldDefaults(fieldGroup);
                  return getSwitchCaseBox(group);
                })}
            </React.Fragment>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

Review.propTypes = {
  onTabClick: PropTypes.func,
  Values: PropTypes.object,
  JsonData: PropTypes.array,
  widgetType: PropTypes.string,
};
