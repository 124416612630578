import React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import masterService from 'api/masterService';

export default () => {
  useAsyncEffect(async () => {
    const params = {
      redirect: window.location.origin,
    };
    await masterService.signOut();

    window.location = `${process.env.REACT_APP_AUTH_URL}${window.jsonToQueryString(params)}`;
  }, []);

  return <p />;
};
