import store from 'redux/store';
import MasterService from 'api/masterService';
import { setLoading, setPdfData } from 'redux/getStarted/actions';
import ServiceInputJSON from '../assets/ServiceJSON';

const ScApiServiceUrl = `${process.env.REACT_APP_SMARTCOMM_URL}`;

const scApi = Schema => {
  store.dispatch(setLoading(true));
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/plain',
    },
    body: JSON.stringify(Schema),
  };

  fetch(ScApiServiceUrl, options)
    .then(rese => {
      return rese.json();
    })
    .then(data1 => {
      store.dispatch(setLoading(false));
      const byteCharacters = atob(data1);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, 'pdfIllustration.pdf');
      return true;
    });
};

const getTagsMap = async (schemaCode, illustrationData) => {
  const filename = schemaCode.schemaName;

  const schema = JSON.parse(JSON.stringify(ServiceInputJSON[filename].Data));
  const res = illustrationData;

  let resValue = '';
  Object.keys(schema).forEach(pKey => {
    const pvalue = schema[pKey];

    Object.keys(pvalue).forEach(childKey => {
      const value = schema[pKey][childKey];
      if (res[value] !== undefined) {
        resValue = res[value];
        schema[pKey][childKey] = resValue;
      }
    });
  });

  return schema;
};

const loadPdf = async ScShemadata => {
  const [pdfError, illustrationPDF] = await MasterService.getIllustrationPdf(ScShemadata);
  if (pdfError !== null) {
    const erPdfData = { resultset: pdfError, isButton: false, isCalled: true, isError: true };
    store.dispatch(setPdfData(erPdfData));
    sessionStorage.setItem('IllustrationPdfData', JSON.stringify(erPdfData));
    return false;
  }

  const pdfData = { resultset: illustrationPDF, isButton: false, isCalled: true, isError: false };
  store.dispatch(setPdfData(pdfData));
  sessionStorage.setItem('IllustrationPdfData', JSON.stringify(pdfData));

  return true;
};

const displayPdf = illustrationPDF => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;

  const byteCharacters = atob(illustrationPDF);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  inputData['#pdfURL'] = fileURL;
  return true;
};

const prepareSmartCommData = async (schemaCode, illustrationId, illustrationData) => {
  const schema = await getTagsMap(schemaCode, illustrationData);

  const ScShemadata = {
    illustrationId,
    schemaData: {
      Data: schema,
    },
    productCode: schemaCode.productCode,
  };

  const isPdfLoadSuccess = await loadPdf(ScShemadata);

  return isPdfLoadSuccess;
};

export default { prepareSmartCommData, scApi, loadPdf, displayPdf };
