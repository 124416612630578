import { createStyles } from '@material-ui/core';

const styles = theme =>
  createStyles({
    errorCaption: {
      position: 'relative',
      display: 'block',
    },
    displayNone: {
      display: 'none',
    },
    errorsButton: {
      color: '#fff',
      backgroundColor: '#CC0000',
      width: '100%',
      margin: '1rem',
    },
    errorList: {
      position: 'absolute',
      top: '3.3rem',
      left: '1rem',
      zIndex: '2',
      display: 'inline-block',
      width: '14.4rem',
      borderRadius: '0.25rem',
      backgroundColor: 'white',
      border: '1px solid #ddd',
      maxHeight: '20rem',
      overflowY: 'auto',
    },
    errorItem: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px solid #ddd',
      padding: '0.625rem 0.875rem',

      '&:last-child': {
        borderBottom: 'none',
      },

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f5f5f5',
      },
    },
    errorText: {
      color: theme.palette.info.dark,
    },
    errorIcon: {
      color: theme.palette.secondary.main,
      fontSize: '1rem',
      margin: '0.125rem 0.5rem 0.125rem 0',
    },
  });

export default styles;
