import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormatListBulleted } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import MasterService from 'api/masterService';
import Jsonfiles from 'assets/JSONFiles';
import ServiceInputJSON from 'assets/ServiceJSON';
import Header from 'components/header';
import IFrameModal from 'components/illustrateIframe';
import TabPanel from 'components/tabPanel';
import Constants from 'constants/common';
import dayjs from 'dayjs';
import dataMapHelper from 'helpers/dataMapHelper';
import useApplicationTypes from 'hooks/useApplicationTypes';
import useProducts from 'hooks/useProducts';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentPage,
  setFastData,
  setInputValues,
  setLoading,
  setPdfData,
  setTrackerGroupName,
  setValue,
} from 'redux/getStarted/actions';
import store from 'redux/store';
import FastService from 'services/fastService';
import NotificationService from 'services/notificationService';
import SmartCommService from 'services/smartCommService';
import ProgressBar from 'components/progressBar';
import GroupTracker from './grouptracker';
import ProductPicker from './productpicker';
import styles from './styles';
import CommonFunctions from './utils/commonFunctions';
import ProductPickerValidation from './utils/productPickerValidation';
import RenderFormValidation from './utils/renderFormValidation';

const useStyles = makeStyles(styles);

export default function Illustration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getStarted = useSelector(state => state.getStarted);
  const user = useSelector(state => state.user);
  const agentPartyId = user.id;
  const scrollRef = useRef(null);
  const products = useProducts();
  const applicationTypes = useApplicationTypes();

  const onChangeProduct = (field, value) => {
    if (field === 'O') {
      const pdfData = { resultset: '', isButton: false, isCalled: false, isError: FormatListBulleted };
      dispatch(setPdfData(pdfData));

      sessionStorage.removeItem('IllustrationPdfData');
    }

    ProductPickerValidation.handleProductPickOnchangeEvent(field, value);
  };

  useEffect(() => {
    let hasExistingData = false;
    dispatch(setLoading(true));
    const illustrationPolicyData = sessionStorage.getItem('IllustrationPolicyData');
    if (CommonFunctions.checkIsNullOrUndefined(illustrationPolicyData) !== '') {
      const illustrationSessionData = JSON.parse(illustrationPolicyData);
      dispatch(setValue(illustrationSessionData));
      hasExistingData = true;
    }
    const { appFields } = Constants;
    const state = store.getState();

    if (!hasExistingData) {
      const illustrationData = state.getStarted.inputvalues;
      illustrationData[appFields.otherAgentName] = '';
      illustrationData[appFields.forOtherAgent] = '';
      dispatch(setInputValues(illustrationData));
    }

    dispatch(setLoading(false));
  }, [dispatch]);

  const validatePage = () => {
    const trackerGroupNames = getStarted.trackergroupnames;
    if (getStarted.currentpage.JsonData !== '') {
      trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
      dispatch(setTrackerGroupName(trackerGroupNames));
      getStarted.currentpage.JsonData.forEach(fields => {
        if (fields.fieldValidation !== undefined) {
          CommonFunctions.executeFieldValidation(
            fields.fieldValidation,
            fields.fieldName,
            true,
            getStarted.currentpage.Group,
            getStarted.currentpage.SubGroup,
          );
        }
      });
    }
  };

  const checkErrors = () => {
    let hasErrors = false;
    Object.entries(getStarted.inputerrors).forEach(key => {
      if (key[1] !== '') {
        hasErrors = true;
      }
    });
    if (hasErrors) {
      NotificationService.error('Please complete all required Fields');
    }
    return hasErrors;
  };

  const getPlanYear = productName => {
    if (!productName) return '';
    const str = productName.split(' ');
    const len = str.length;
    return str[len - 1];
  };

  const showPDF = () => {
    const checkPdfTimer = setInterval(() => {
      const pdfDataString = sessionStorage.getItem('IllustrationPdfData');
      let isErrored = {
        errorFlag: getStarted.fastdata.isError,
        errorMessage: getStarted.fastdata.resultset,
      };

      if (isErrored.errorFlag) {
        NotificationService.error(isErrored.errorMessage);
        clearInterval(checkPdfTimer);
      }

      if (pdfDataString !== null) {
        const pdfData = JSON.parse(pdfDataString);
        isErrored = {
          errorFlag: pdfData.isError,
          errorMessage: pdfData.resultset,
        };

        if (isErrored.errorFlag) {
          NotificationService.error(isErrored.errorMessage);
          clearInterval(checkPdfTimer);
          return;
        }

        SmartCommService.displayPdf(pdfData.resultset);
        dispatch(setLoading(false));

        clearInterval(checkPdfTimer);
      }
    }, 1000);
  };

  useEffect(() => {
    if (getStarted.currentpage.JsonData !== '') {
      getStarted.currentpage.JsonData.forEach(fields => {
        if (fields.fieldValidation !== undefined) {
          CommonFunctions.executeFieldValidation(fields.fieldValidation, fields.fieldName, false);
        }
      });
    }
    if (
      getStarted.fastdata.issuccess &&
      getStarted.pdfData.resultset === '' &&
      !getStarted.pdfData.isCalled &&
      !getStarted.pdfData.isButton
    ) {
      dataMapHelper.preFastService();
    }
  }, [getStarted]);

  const setDefaultValues = JSONContent => {
    const illustrationData = getStarted.inputvalues;
    JSONContent.forEach(group => {
      if (group.fieldProperty.defaultValue != null) {
        if (CommonFunctions.checkIsNullOrUndefined(illustrationData[group.fieldName]) === '') {
          illustrationData[group.fieldName] = group.fieldProperty.defaultValue;
        }
      }
    });
    dispatch(setInputValues(illustrationData));
  };

  const getNextPageContent = (groupIndex, subGroupIndex, trackerGroupNames) => {
    try {
      const jsonFileName = trackerGroupNames[groupIndex].SubGroup[subGroupIndex].$ref;
      const jsonFileNameWithoutExt = jsonFileName.replace('.json', '');
      setDefaultValues(Jsonfiles[jsonFileNameWithoutExt][jsonFileNameWithoutExt]);
      return Jsonfiles[jsonFileNameWithoutExt][jsonFileNameWithoutExt];
    } catch (error) {
      return '';
    }
  };

  const cleanJSON = inputFormData => {
    const returnObject = { ...inputFormData };
    Object.keys(returnObject).forEach(key => {
      if (CommonFunctions.checkIsNullOrUndefined(returnObject[key]) === '') {
        delete returnObject[key];
      }
    });
    return returnObject;
  };

  const getJsonSpecification = () => {
    let illustrationData = getStarted.inputvalues;
    const { appFields } = Constants;
    const product = products.data?.data.find(p => p.id === getStarted.product);

    illustrationData[appFields.signedState] = getStarted.signedstateabb;
    illustrationData[appFields.signedStateId] = getStarted.signedstateid;
    illustrationData[appFields.applicationtype] = getStarted.applicationtype;
    illustrationData[appFields.product] = product;
    illustrationData[appFields.applicationTypeName] = applicationTypes.data?.data.find(
      p => p.id === getStarted.applicationtype,
    ).type;
    illustrationData[appFields.productName] = product.description;
    illustrationData[appFields.issueYear] = getPlanYear(product.description);
    illustrationData[appFields.applicationDate] = new Date();

    for (let i = 1; i < appFields.allocationCount; i += 1) {
      const allocationPercent = appFields.allocationPercent.replace('__', i.toString());
      illustrationData[allocationPercent] = '';
    }

    Constants.nonallocationList.forEach(field => (illustrationData[field] = ''));

    illustrationData[appFields.totalAllocation] = '';
    illustrationData = cleanJSON(illustrationData);

    const currentPage = { Group: 1, SubGroup: 0, JsonData: '' };
    dispatch(setCurrentPage(currentPage));
    dispatch(setInputValues(illustrationData));
    CommonFunctions.getSpecificationName();
  };

  const checkHasTabStateChanged = () => {
    const illustrationData = getStarted.inputvalues;
    const { appFields } = Constants;

    if (
      illustrationData[appFields.signedStateId] === getStarted.signedstateid &&
      illustrationData[appFields.applicationtype] === getStarted.applicationtype &&
      illustrationData[appFields.product] === getStarted.product
    ) {
      return true;
    }

    return false;
  };

  const handleClickTab = (_, groupIndex, subGroupIndex) => {
    scrollRef.current.scrollTo(0, 0);
    const subGroup = subGroupIndex;
    const group = getStarted.currentpage.Group;
    const totalTabs = getStarted.trackergroupnames.length - 1;
    const lastSubTab = getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup.length - 1;
    if (ProductPickerValidation.getStartedPageValidation(getStarted)) {
      return;
    }

    if (group === 0 && groupIndex === 1 && !checkHasTabStateChanged()) {
      const enfastData = { resultset: {}, issuccess: false, isFastRun: false, isError: false };
      dispatch(setFastData(enfastData));

      const pdfData = { resultset: '', isButton: false, isCalled: false, isError: FormatListBulleted };
      dispatch(setPdfData(pdfData));

      sessionStorage.removeItem('IllustrationPdfData');
      sessionStorage.removeItem('IllustrationPolicyData');
      getJsonSpecification();
      return false;
    }
    if (getStarted.currentpage.Group === totalTabs && getStarted.currentpage.SubGroup === lastSubTab) {
      checkErrors();
    } else {
      validatePage();
    }

    const jsonContent = getNextPageContent(groupIndex, subGroup, getStarted.trackergroupnames);
    const currentPage = { Group: groupIndex, SubGroup: subGroup, JsonData: jsonContent };
    dispatch(setCurrentPage(currentPage));
    sessionStorage.setItem('IllustrationPolicyData', JSON.stringify(getStarted));
    document.getElementById('divContainer').scrollTop = 0;
    window.scrollTo(0, 0);
  };

  const handleClickButton = () => {
    dispatch(setLoading(true));
    if (ProductPickerValidation.getStartedPageValidation(getStarted)) {
      dispatch(setLoading(false));
      return;
    }

    if (!checkHasTabStateChanged()) {
      const enfastData = { resultset: {}, issuccess: false, isFastRun: false, isError: false };
      dispatch(setFastData(enfastData));

      const pdfData = { resultset: '', isButton: false, isCalled: false, isError: FormatListBulleted };
      dispatch(setPdfData(pdfData));

      sessionStorage.removeItem('IllustrationPdfData');
    }
    getJsonSpecification();

    return false;
  };
  const checkRadioValue = value => {
    let checkBool = null;
    Constants.radioTrueValue.forEach(itm => {
      if (value === itm) {
        checkBool = true;
      }
    });
    Constants.radioFalseValue.forEach(itm => {
      if (value === itm) {
        checkBool = false;
      }
    });
    checkBool = checkBool === null ? value : checkBool;
    return checkBool;
  };
  const handleChange = (widgetObj, fieldValidations, widgetType, fieldName) => {
    const fastData = getStarted.fastdata;
    const totalTabs = getStarted.trackergroupnames.length - 1;
    const { appFields } = Constants;
    if (getStarted.currentpage.Group === 1 || getStarted.currentpage.Group === totalTabs - 1) {
      if (fastData !== undefined && widgetType !== 'button') {
        const enfastData = { resultset: {}, issuccess: false, isFastRun: false, isError: false };
        dispatch(setFastData(enfastData));

        const pdfData = { resultset: '', isButton: false, isCalled: false, isError: FormatListBulleted };
        dispatch(setPdfData(pdfData));

        sessionStorage.removeItem('IllustrationPdfData');
      }
    }

    const illustrationData = getStarted.inputvalues;
    let fldName = fieldName;
    switch (widgetType) {
      case 'date':
        illustrationData[fieldName] = widgetObj;
        if (CommonFunctions.checkIsNullOrUndefined(widgetObj) !== '') {
          if (widgetObj.type === 'blur') {
            if (dayjs(widgetObj.target.value, 'MM/DD/YYYY').isValid()) {
              illustrationData[fieldName] = dayjs(widgetObj.target.value).format('MM/DD/YYYY');
            } else {
              illustrationData[fieldName] = dayjs(widgetObj.target.value).format('MM/DD/YYYY');
            }
          } else {
            illustrationData[fieldName] = dayjs(widgetObj).format('MM/DD/YYYY');
          }
        }
        break;
      case 'button':
        illustrationData[fieldName] = true;

        break;
      case 'percent':
        illustrationData[fieldName] = parseFloat(widgetObj.target.value.replace('%', ''), 0);
        break;
      case 'radio':
      case 'radioQuestion':
        if (widgetObj.target === undefined) illustrationData[fieldName] = widgetObj;
        else if (widgetObj.target.textContent === 'Yes') illustrationData[fieldName] = true;
        else if (widgetObj.target.textContent === 'No') illustrationData[fieldName] = false;
        else if (widgetObj.target.textContent !== '')
          illustrationData[fieldName] = checkRadioValue(widgetObj.target.textContent);
        break;
      case 'currency':
        illustrationData[fieldName] =
          widgetObj.target.value === ''
            ? ''
            : Math.abs(parseFloat(widgetObj.target.value.replace('$', '').replace(/,/g, '')));
        if (fieldName === appFields.plannedValue)
          illustrationData[appFields.plannedValueString] = widgetObj.target.value;
        break;
      case 'checkbox':
        if (document.getElementsByName(fieldName).length === 1) {
          if (widgetObj.target.checked) {
            illustrationData[fieldName] = widgetObj.target.value;
          } else {
            illustrationData[fieldName] = '';
          }
        } else if (document.getElementsByName(fieldName).length > 0) {
          let checkboxValues = '';
          document.getElementsByName(fieldName).forEach(item => {
            if (item.checked) {
              if (checkboxValues !== '') checkboxValues = `${checkboxValues},${item.value}`;
              else checkboxValues = item.value;
            }
          });
          illustrationData[fieldName] = checkboxValues;
        }
        break;
      case 'numeric':
        illustrationData[fieldName] = widgetObj.target.value === '' ? '' : parseInt(widgetObj.target.value, 0);
        break;
      case 'textbox':
        illustrationData[fieldName] = widgetObj.target.value.replace(/[^a-zA-Z!@#$%^&* ]/g, '');
        break;
      default:
        if (widgetObj.target.name === undefined) illustrationData[fldName] = widgetObj.target.value;
        else illustrationData[widgetObj.target.name] = widgetObj.target.value;
        break;
    }

    if (fldName === undefined) fldName = String(widgetObj.target.name);
    if (fieldValidations !== undefined) CommonFunctions.executeFieldValidation(fieldValidations, fldName, true);
    else dispatch(setInputValues(illustrationData));
  };

  const generatePdf = () => {
    validatePage();
    if (checkErrors()) {
      return false;
    }

    if (!getStarted.fastdata.issuccess) {
      FastService.fastServiceCall(cleanJSON(getStarted.inputvalues));
    }
    dispatch(setLoading(true));

    showPDF();
  };

  const getToEapp = async () => {
    const illustrationData = cleanJSON(getStarted.inputvalues);
    const { appFields } = Constants;

    let formData = JSON.parse(JSON.stringify(ServiceInputJSON.eApp));

    formData = {
      ...formData,
      product: getStarted.product,
      signedstate: getStarted.signedstate,
      signedstateabb: getStarted.signedstateabb,
      signedstateid: getStarted.signedstateid,
      applicationtype: getStarted.applicationtype,
      inputvalues: illustrationData,
      policyapplicationid: illustrationData.applicationAlias,
      productname: illustrationData[appFields.productName],
    };

    const updatePayload = {
      applicationId: illustrationData.applicationAlias,
      completionPercentage: 7,
      agentPartyId,
      response: formData,
    };

    const [SaveApplicationError] = await MasterService.saveApplicationData(updatePayload);
    if (CommonFunctions.checkIsNullOrUndefined(SaveApplicationError) !== '') {
      NotificationService.error(SaveApplicationError.data.message);
      dispatch(setLoading(false));
      return false;
    }
    window.open(`${process.env.REACT_APP_EAPP_URL}/applications/${illustrationData.applicationAlias}`);
  };

  /*
   * Handle next button.
   */
  const goToNextPage = () => {
    scrollRef.current.scrollTo(0, 0);
    let currentGroup = getStarted.currentpage.Group;
    let currentSubGroup = getStarted.currentpage.SubGroup + 1;

    if (typeof getStarted.trackergroupnames[currentGroup] !== 'undefined') {
      if (typeof getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup] === 'undefined') {
        currentGroup += 1;
        currentSubGroup = 0;
      }
    }

    validatePage();
    // handle next page content
    const jsonContent = getNextPageContent(currentGroup, currentSubGroup, getStarted.trackergroupnames);
    const currentPage = { Group: currentGroup, SubGroup: currentSubGroup, JsonData: jsonContent };
    dispatch(setCurrentPage(currentPage));
    sessionStorage.setItem('IllustrationPolicyData', JSON.stringify(getStarted));
    document.getElementById('divContainer').scrollTop = 0;
    window.scrollTo(0, 0);
  };

  /*
   * Handel previous button
   */
  const goToPreviousPage = () => {
    let currentGroup = getStarted.currentpage.Group;
    let currentSubGroup = getStarted.currentpage.SubGroup - 1;

    if (typeof getStarted.trackergroupnames[currentGroup] !== 'undefined') {
      if (typeof getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup] === 'undefined') {
        currentGroup -= 1;
        currentSubGroup = getStarted.trackergroupnames[currentGroup].SubGroup.length - 1;
      }
    }

    const jsonContent = getNextPageContent(currentGroup, currentSubGroup, getStarted.trackergroupnames);
    const currentPage = { Group: currentGroup, SubGroup: currentSubGroup, JsonData: jsonContent };
    dispatch(setCurrentPage(currentPage));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    scrollRef.current.scrollTo(0, 0);
  };

  const noModalClose = () => {
    const state = store.getState();
    const inputData = state.getStarted.inputvalues;
    inputData['#pdfURL'] = '';
    dispatch(setInputValues(inputData));
  };

  const yesModalClose = () => {
    const link = document.createElement('a');
    link.href = getStarted.inputvalues['#pdfURL'];
    document.body.appendChild(link);
    const name = 'illustrationPDF';
    link.setAttribute('download', name);
    link.click();
  };

  return (
    <div className={classes.contentContainer}>
      {getStarted.inputvalues['#pdfURL'] && (
        <IFrameModal
          illustrateUrl={getStarted.inputvalues['#pdfURL']}
          onNoClose={noModalClose}
          onYesClose={yesModalClose}
          visible
        />
      )}
      {getStarted.loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <Box className={classes.formContainer}>
          <Box className={classes.headerSection}>
            <Header errorLists={getStarted.errorlists} onTabClick={handleClickTab} />
            <Box className={classes.progressStyle}>
              <ProgressBar />
            </Box>
          </Box>
          <Grid container spacing={0} className={classes.tabPanelSection}>
            <GroupTracker onTabClick={handleClickTab} errorLists={getStarted.errorlists} />
            <Grid ref={scrollRef} container spacing={0} className={classes.tabPanelSectionnew}>
              <TabPanel index={0} className={classes.tabPanelWidth}>
                <Grid container spacing={2}>
                  {getStarted.currentpage.Group === 0 && getStarted.currentpage.SubGroup === 0 && (
                    <ProductPicker onClick={handleClickButton} onChange={onChangeProduct} />
                  )}
                  {getStarted.currentpage.Group > 0 && (
                    <RenderFormValidation onChange={handleChange} onTabClick={handleClickTab} />
                  )}
                </Grid>

                {!(getStarted.currentpage.Group === 0 && getStarted.currentpage.SubGroup === 0) && (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    className={classes.rowButton}
                  >
                    <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridAlignLeftBackButton}>
                      <Button fullWidth variant="outlined" color="secondary" onClick={goToPreviousPage}>
                        BACK
                      </Button>
                    </Grid>

                    {getStarted.currentpage.Group === getStarted.trackergroupnames.length - 1 &&
                    getStarted.currentpage.SubGroup ===
                      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup.length - 1 ? (
                        <React.Fragment>
                          <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridAlignLeftBackButton}>
                            <Button fullWidth variant="contained" onClick={generatePdf} color="secondary">
                              Illustrate
                            </Button>
                          </Grid>
                          {getStarted.inputvalues.showEAppButton === 'YES' && (
                          <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridAlignLeftBackButton}>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={getToEapp}
                              color="secondary"
                              startIcon={<AddIcon />}
                            >
                              Application
                            </Button>
                          </Grid>
                        )}
                        </React.Fragment>
                    ) : (
                      <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridAlignLeftBackButton}>
                        <Button fullWidth variant="contained" onClick={goToNextPage} color="secondary">
                          Next
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
