import {
  Box,
  Button,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Fade,
  ThemeProvider,
} from '@material-ui/core';
import Header from 'components/header';
import React, { useState } from 'react';
import { AddCircle, Close, GetApp, Mail, SearchOutlined } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableActionMenu from 'views/history/components/tableActionMenu';
import constants from 'constants/common';
import { styles, tableTheme } from './styles';
import HistoryNoData from './components/noData';

const useStyles = makeStyles(styles);

function HistoryView() {
  const appConfig = useSelector(state => state.appConfig);
  const user = useSelector(state => state.user);

  const isHistoryFlagEnabled = appConfig.config.illustration_history?.enabled;
  const isAdmin = user.types.some(type =>
    [...constants.STANDARD_ADMIN_ROLE_IDS, ...constants.REPORTING_ADMIN_ROLE_IDS].includes(type.id),
  );

  const navigate = useNavigate();

  const classes = useStyles();
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIndicies, setselectedRowIndicies] = useState([]);

  /**
   * Custom sorting function for the 'sortCompare' column option that handles sorting by the currency strings
   * in the format '$x,xxx,xxx.xxx'.
   *
   * Depending on the sort direction given, returns a sort function that compares two items
   * @type {import('mui-datatables').MUIDataTableColumnOptions['sortCompare']}
   */
  const handleCurrencyColumnSort = order => {
    return order === 'asc'
      ? (itemA, itemB) => {
          const a = parseFloat(itemA.data.split('$')[1].replace(',', ''));
          const b = parseFloat(itemB.data.split('$')[1].replace(',', ''));

          return a > b ? 1 : -1;
        }
      : (itemA, itemB) => {
          const a = parseFloat(itemA.data.split('$')[1].replace(',', ''));
          const b = parseFloat(itemB.data.split('$')[1].replace(',', ''));

          return a < b ? 1 : -1;
        };
  };

  // This is the required data for the columns in the table.
  // We should probably also have the illustrationId in the data as well (but not in the table)
  // so we can pass it through to the action menus to perform the required actions.

  /** @type {IllustrationData[]} */
  // const fakeData = [];
  const fakeData = [
    {
      illustrationId: '123123123',
      creator: 'Bob',
      producer: 'Tom',
      productName: "Sally's Product",
      planType: 'Q',
      premiumAmount: '$100,000.00',
      owner: 'Sally',
      createdDate: '07/31/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Richard',
      productName: 'Synergy Choice 2',
      planType: 'NQ',
      premiumAmount: '$50,000.00',
      owner: 'Tom',
      createdDate: '07/31/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Harold',
      productName: 'Aspida Friends & Family MYGA 3',
      planType: 'Q',
      premiumAmount: '$123,999.00',
      owner: 'Richard',
      createdDate: '07/22/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Harold',
      productName: "Sally's Product",
      planType: 'Q',
      premiumAmount: '$100,000.00',
      owner: 'Sally',
      createdDate: '07/31/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Sam',
      productName: 'Synergy Choice 2',
      planType: 'NQ',
      premiumAmount: '$50,000.00',
      owner: 'Tom',
      createdDate: '07/31/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Chris',
      productName: 'Aspida Friends & Family MYGA 3',
      planType: 'Q',
      premiumAmount: '$123,999.00',
      owner: 'Richard',
      createdDate: '07/22/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Paul',
      productName: "Sally's Product",
      planType: 'Q',
      premiumAmount: '$100,000.00',
      owner: 'Sally',
      createdDate: '07/31/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Sarah',
      productName: 'Synergy Choice 2',
      planType: 'NQ',
      premiumAmount: '$50,000.00',
      owner: 'Tom',
      createdDate: '07/31/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Sara',
      productName: 'Aspida Friends & Family MYGA 3',
      planType: 'Q',
      premiumAmount: '$123,999.00',
      owner: 'Richard',
      createdDate: '07/22/2024',
      actions: true,
    },
    {
      creator: 'Bob',
      producer: 'Kevin',
      productName: 'Aspida Friends & Family MYGA 3',
      planType: 'Q',
      premiumAmount: '$123,999.00',
      owner: 'Richard',
      createdDate: '07/22/2024',
      actions: true,
    },
  ];

  /** @type {import('mui-datatables').MUIDataTableColumnDef[]} */
  const columns = [
    { label: 'Creator', name: 'creator' },
    {
      label: 'Producer',
      name: 'producer',
      options: {
        display: isAdmin ? 'true' : 'excluded',
      },
    },
    { label: 'Product Name', name: 'productName' },
    { label: 'Plan Type', name: 'planType' },
    {
      label: 'Premium Amount',
      name: 'premiumAmount',
      options: {
        sortCompare: handleCurrencyColumnSort,
      },
    },
    { label: 'Owner', name: 'owner' },
    { label: 'Created Date', name: 'createdDate' },
    {
      label: 'Actions',
      name: 'actions',
      options: {
        customBodyRenderLite: dataIndex => <TableActionMenu rowData={fakeData[dataIndex]} />,
      },
    },
  ];

  /** @type {import('mui-datatables').MUIDataTableOptions} */
  const tableOptions = {
    searchText,
    search: false,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectToolbarPlacement: 'none',
    elevation: 0,
    rowsSelected: selectedRowIndicies,
    onRowSelectionChange: (_, __, rowIndiciesSelected) => {
      setselectedRowIndicies(rowIndiciesSelected);
    },
  };

  return isHistoryFlagEnabled ? (
    <Box>
      <Box>
        <Header />
      </Box>
      <Box className={classes.contentWrapper}>
        <Box>
          <Typography variant="body2" className={classes.subHeader} gutterBottom>
            History
          </Typography>
        </Box>
        {fakeData.length !== 0 ? (
          <>
            <Box display="flex" justifyContent="space-between">
              <Box className={classes.searchBox}>
                <Tooltip title="Search" className={classes.tooltipButton}>
                  <IconButton
                    color="primary"
                    onClick={event => {
                      event.stopPropagation();
                      setSearchBoxVisible(isVisible => !isVisible);
                    }}
                  >
                    <SearchOutlined className={classes.searchIcon} />
                  </IconButton>
                </Tooltip>
                <Fade in={searchBoxVisible} className={classes.searchInputContainer}>
                  <Box>
                    <TextField
                      className={classes.searchInput}
                      type="text"
                      onChange={e => setSearchText(e.target.value)}
                      value={searchText}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Clear" className={classes.tooltipButton}>
                            <IconButton onClick={() => setSearchText('')}>
                              {searchText.length > 0 ? <Close /> : ''}
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Box>
                </Fade>
              </Box>
              <Box className={classes.actionButtonsBox}>
                <Button
                  className={classes.actionButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate('/illustration')}
                  disableElevation
                >
                  <AddCircle className={classes.actionButtonIcon} />
                  CREATE NEW ILLUSTRATION
                </Button>
                <Button
                  className={classes.actionButton}
                  variant="contained"
                  color="secondary"
                  disabled={selectedRowIndicies.length === 0}
                  disableElevation
                >
                  <Mail className={classes.actionButtonIcon} />
                  EMAIL
                </Button>
                <Button
                  className={classes.actionButton}
                  variant="contained"
                  color="secondary"
                  disabled={selectedRowIndicies.length === 0}
                  disableElevation
                >
                  <GetApp className={classes.actionButtonIcon} />
                  DOWNLOAD
                </Button>
              </Box>
            </Box>
            <Box className={classes.dataTableContainer}>
              <ThemeProvider theme={tableTheme}>
                <MUIDataTable columns={columns} data={fakeData} options={tableOptions} />
              </ThemeProvider>
            </Box>
          </>
        ) : (
          <HistoryNoData />
        )}
      </Box>
    </Box>
  ) : (
    <p>Page Not Found </p>
  );
}

export default HistoryView;

/**
 * @typedef {Object} IllustrationData
 * @property {String} illustrationId
 * @property {String} creator
 * @property {String | undefined} producer
 * @property {String} productName
 * @property {'Q'|'NQ'} planType
 * @property {String} premiumAmount
 * @property {String} owner
 * @property {String} createdDate
 * @property {Boolean} actions
 */
