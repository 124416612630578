import { useQuery } from 'react-query';
import masterService from 'api/masterService';

const useApplicationTypes = () => {
  const applicationTypes = useQuery({
    queryFn: async () => masterService.getApplicationTypes(),
    queryKey: ['applicationTypes'],
    refetchOnMount: false,
    staleTime: Infinity,
  });

  return applicationTypes;
};

export default useApplicationTypes;
