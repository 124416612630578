export default {
  componentContainer: {
    width: '100%',
    paddingTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    gap: '34px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  brushImage: {
    width: '244px',
    height: '210px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  messageText: {
    fontSize: '22px',
    fontFamily: 'Muli',
    fontWeight: '700',
  },
  actionButtonsBox: {
    display: 'flex',
    gap: '8px',
  },
  actionButtonIcon: {
    marginRight: '9px',
  },
};
