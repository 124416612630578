export default theme => ({
  root: {
    display: 'flex',
    height: '64px',
    '@media (max-width:600px)': {
      height: '56px',
    },
  },
  title: {
    color: 'white !important',
  },
  appBar: {
    background: 'linear-gradient(90deg, rgba(50,68,93,1) 0%, rgba(94,116,148,1) 80%)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - 240px)`,
    marginLeft: '240px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '240px',
  },
  drawerPaper: {
    width: '240px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
});
