export default {
  label: {
    paddingBottom: '1rem',
    fontSize: '1.3rem',
    fontWeight: '400',
  },
  gridAlignLeftNextButton: {
    textAlign: 'left',
  },
  question: {
    margin: '2rem',
  },
  appType: {
    fontSize: '1.3rem',
    fontWeight: '400',
  },
  appTypeBtn: {
    margin: '.5rem',
  },
  lets: {
    color: '#4D4D4D',
    fontFamily: 'Playfair Display',
    fontSize: '43px',
    fontWeight: '900',
  },
  activeButton: {
    height: '62px',
    width: '246.33px',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
    '&:hover': {
      backgroundColor: '#D0CADC !important',
    },
  },
  activeTrustButton: {
    height: '62px',
    width: '206px',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
    '&:hover': {
      backgroundColor: '#D0CADC !important',
    },
  },
  appBtnStyle: {
    height: '62px',
    width: '246px',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
  },
  appBtnTrustStyle: {
    height: '62px',
    width: '206px',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
  },
  gridStyle: {
    height: '161px',
    width: '857px',
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    boxShadow: '0 2px 7px 0 #ABABAB',
    margin: '0.5rem',
  },
  paperBody: {
    padding: '1rem',
  },
  paperGrid: {
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    boxShadow: '0 2px 7px 0 #ABABAB',
    marginTop: '1rem',
  },
  aspida: {
    color: '#4D4D4D',
    fontFamily: 'Playfair Display',
    fontSize: '27px',
    fontWeight: '900',
  },
  rowButton: {
    paddingTop: '2em',
    paddingBottom: '2em',
  },
  labelMarginTop: {
    marginTop: '2em',
  },
  tooltipStyle: {
    marginBottom: '-4px',
    marginLeft: '9px',
  },
  dropStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '@media (min-width:125rem)': {
      width: '50%',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
    width: '100%',
  },
  percentageStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #14043C !important',
      boxSizing: 'border-box',
      height: '49px',
      borderRadius: '4px',
    },
    '@media (min-width:125rem)': {
      width: '50%',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  labelBackgroundColor: {
    backgroundColor: '#F2F4F7',
  },
  displayBlock: {
    display: 'block',
  },
  starticonstyle: {
    fontSize: '26px',
  },
  docErrorSection: {
    color: '#f44336 !important',
    marginLeft: '0.9rem',
    fontSize: '1rem',
  },
  proderror: {
    color: '#f44336 !important',
    marginLeft: '0.9rem',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
  npnSearch: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
};
