import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import store from 'redux/store';
import { setAuthenticatedUser } from 'redux/user/actions';
import { setReset, setLoading, setInputValues } from 'redux/getStarted/actions';
import RoleHelper, { ROLES } from 'helpers/roleHelper';
import BrowserHelper from 'helpers/browserHelper';
import { Grid, Box, Button } from '@material-ui/core';
import NotificationService from 'services/notificationService';
import MasterService from 'api/masterService';
import EditModal from 'components/editModal';
import { TreeItem, TreeView } from '@material-ui/lab';
import { CheckCircle, Warning, PanoramaFishEye, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import GroupTrackerFunctions from 'views/illustration/utils/groupTrackerFunctions';
import ErrorList from 'components/errorList';
import constants from 'constants/common';
import { useNavigate } from 'react-router-dom';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function GroupTracker(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const user = useSelector(state => state.user);
  const [expanded, setExpanded] = React.useState([]);
  const [editModalVisible, setModalEditVisible] = useState(false);
  const navigate = useNavigate();
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const noEditModalClose = () => {
    setModalEditVisible(false);
  };

  const yesEditModalClose = async () => {
    setModalEditVisible(false);
    const userObj = user;
    const inputData = { ...getStarted.inputvalues };
    inputData['#isEditEapp'] = true;
    store.dispatch(setInputValues({ ...getStarted.inputvalues, ...inputData }));
    store.dispatch(setLoading(true));
    const [appDataError] = await MasterService.editApplication(getStarted.policyapplicationid);
    if (appDataError !== null) {
      NotificationService.error(appDataError.data.message);
    }
    const message = `Agent Clicked Edit button inbetween filling app in
    ${BrowserHelper.getTabName(getStarted, getStarted.currentpage.Group, getStarted.currentpage.SubGroup)}`;
    BrowserHelper.saveBrowserInfo(getStarted.inputvalues['#applicationAliasId'], message);
    store.dispatch(setLoading(false));
    const isViewOnly = RoleHelper.userHasRoles(userObj, ROLES.VIEWONLY);
    store.dispatch(setAuthenticatedUser({ ...userObj, verified: userObj.verified, isViewOnly }));
    store.dispatch(setReset(getStarted));
    sessionStorage.removeItem('eAppPolicyData');
    navigate('/getStarted');
    navigate(`/applications/${getStarted.policyapplicationid}`);
  };

  const onErrorClick = (event, item) => {
    props.onTabClick(event, item.groupIndex, item.subGroupIndex);
  };

  const getIcon = isValid => {
    const tabIcon = {
      true: <CheckCircle className={classes.completeIcon} />,
      false: <Warning className={classes.inCompleteIcon} />,
      default: <PanoramaFishEyeIcon className={classes.iconStyle} />,
    };
    return tabIcon[isValid] || tabIcon.default;
  };

  const getSubTabs = (subgrp, index) => (
    <TreeItem
      key={subgrp.displayname}
      nodeId={subgrp.displayname}
      icon={getIcon(subgrp.isPageValid)}
      onClick={event => props.onTabClick(event, getStarted.currentpage.Group, index)}
      className={[
        getStarted.currentpage.SubGroup === index ? classes.activeSubTab : classes.subTab,
        classes.activeSubTabSpace,
      ].join(' ')}
      label={<span>{subgrp.displayname}</span>}
      labelIcon={getIcon(subgrp.isPageValid)}
    />
  );

  const getTrackerSourceTabs = getStarted.trackergroupnames.map((group, index) => {
    if (expanded && expanded.length > 1) {
      const res = expanded.filter(x => x === getStarted.trackergroupnames[getStarted.currentpage.Group].displayname);
      setExpanded(res);
    }
    const validLength = GroupTrackerFunctions.getLength(getStarted.trackergroupnames, index, false);
    const invalidLength = GroupTrackerFunctions.getLength(getStarted.trackergroupnames, index, undefined);
    const totalSubGroupLength = getStarted.trackergroupnames[index].SubGroup.length;

    let checkMarkDisplay = null;
    if (totalSubGroupLength !== invalidLength) {
      checkMarkDisplay = validLength === 0 && invalidLength === 0;
    }

    return (
      <TreeItem
        key={group.displayname}
        nodeId={group.displayname}
        icon={getIcon(checkMarkDisplay)}
        onClick={event => props.onTabClick(event, index, 0)}
        className={[getStarted.currentpage.Group === index && classes.activeTab, classes.activeTabSpace].join(' ')}
        label={<span>{group.displayname}</span>}
        labelIcon={getIcon(checkMarkDisplay)}
      >
        {getStarted.trackergroupnames.length > 0 &&
          getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup.map((subgrp, subindex) => (
            <div>{getSubTabs(subgrp, subindex)}</div>
          ))}
      </TreeItem>
    );
  });

  const getTabs = () => {
    if (getStarted.trackergroupnames.length === 0) {
      return (
        <TreeItem
          key="Start"
          nodeId="Start"
          icon={<PanoramaFishEye className={classes.iconStyle} />}
          className={[classes.activeTab, classes.activeTabSpace].join(' ')}
          label={<span>Product</span>}
        >
          <TreeItem
            key="Product"
            nodeId="Product"
            icon={<PanoramaFishEye className={classes.iconStyle} />}
            className={[classes.activeSubTab, classes.activeSubTabSpace].join(' ')}
            label={<span>Product</span>}
          />
        </TreeItem>
      );
    }

    return getTrackerSourceTabs;
  };

  return (
    <Box className={classes.container}>
      <EditModal editVisible={editModalVisible} onNoEditClose={noEditModalClose} onYesEditClose={yesEditModalClose} />
      {getStarted.inputvalues['#isReviewComplete'] &&
        getStarted.inputvalues['#applicationStatusId'] !== constants.applicationStatusId.inProgress && (
          <Grid item xs={12} sm={6} md={3} lg={8} className={classes.gridAlignCenterExitButton}>
            <Button
              variant="contained"
              color="secondary"
              name="editEappBtn"
              className={classes.editBtn}
              onClick={() => setModalEditVisible(true)}
              startIcon={<Edit />}
            >
              EDIT
            </Button>
          </Grid>
        )}
      <Box className={classes.tabContainer}>
        <TreeView
          className={classes.tab}
          expanded={
            getStarted.trackergroupnames.length === 0
              ? ['Start']
              : [getStarted.trackergroupnames[getStarted.currentpage.Group].displayname]
          }
          selected={
            getStarted.trackergroupnames.length === 0
              ? 'Product'
              : getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
                  .displayname
          }
          onNodeToggle={handleToggle}
        >
          {getTabs()}
        </TreeView>
      </Box>
      <Grid item xs={12} sm={6} md={8} lg={8}>
        <ErrorList errors={props.errorLists} onErrorClick={onErrorClick} />
      </Grid>
    </Box>
  );
}

GroupTracker.propTypes = {
  onTabClick: PropTypes.func,
  errorLists: PropTypes.array,
};
