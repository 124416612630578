import * as constants from './types';

export const initialAppConfig = {
  routes: {},
  sample: { enabled: true },
  maintenance_alert: {
    enabled: false,
    message: '',
    title: '',
    dismissable: false,
    severity: 'info',
    url: '',
  },
};

export const initialState = {
  config: initialAppConfig,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_FLAGS: {
      return {
        ...state,
        config: action.value,
      };
    }

    case constants.SET_LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }

    default: {
      return state;
    }
  }
};
