import React, { useState } from 'react';
import { Button, Grid, MenuItem, Select, TextField, Typography, Paper } from '@material-ui/core';
import { Group, Business, Lock, ThumbUp, ThumbDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DropDown } from '@aspida/react-components';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import {
  setProduct,
  setApplicationType,
  setSignedState,
  setSignedStateAbb,
  setSignedStateId,
  setInputValues,
} from 'redux/getStarted/actions';
import RoleHelper, { ROLES } from 'helpers/roleHelper';
import useStates from 'hooks/useStates';
import useContracts from 'hooks/useContracts';
import useProducts from 'hooks/useProducts';
import useApplicationTypes from 'hooks/useApplicationTypes';
import CommonFunctions from '../utils/commonFunctions';
import styles from './styles';

const useStyles = makeStyles(styles);

const otherAgentOptions = [
  {
    id: 'true',
    value: 'Yes',
  },
  {
    id: 'false',
    value: 'No',
  },
];
const annuityTypes = [
  { id: 'MYGA', value: 'MYGA' },
  { id: 'FIA', value: 'FIA' },
];
export default function ProductPicker(props) {
  const classes = useStyles();
  const applicationTypes = useApplicationTypes();
  const states = useStates();
  const products = useProducts();
  const getStarted = useSelector(state => state.getStarted);
  const user = useSelector(state => state.user);
  const approvedStates = getStarted.inputvalues['#approvedStates'];
  const [SignedStates, setSignedStates] = useState(approvedStates || []);
  const productFilter = item => {
    if (getStarted.inputvalues['#annuityTypeQues'] === 'MYGA') {
      return ['MYGA', 'MYA'].includes(item.productTypeAbbr);
    }
    return item.productTypeAbbr === getStarted.inputvalues['#annuityTypeQues'];
  };

  const isProducer = RoleHelper.userHasRoles(user, ROLES.PRODUCER);
  const partyId = isProducer ? user.id : user.organizations[0].id;
  const contracts = useContracts(partyId);
  const dispatch = useDispatch();
  const allowedProductContracts = uniqBy(
    (contracts.data?.data || []).flatMap(contract => contract.products),
    'id',
  );

  const allowedProducts = products.data?.data.filter(p => allowedProductContracts.some(apc => apc.id === p.id)) || [];

  const getIcon = isValid => {
    const tabIcon = {
      'd3dddb48-4ec7-4cea-9b65-566f0226a53b': <Lock className={classes.starticonstyle} />,
      'ad0199af-f829-4a96-9088-0d42a1db6525': <Business className={classes.starticonstyle} />,
      '543f1d6f-4d9e-4df8-9a4d-53399f43168d': <Group className={classes.starticonstyle} />,
    };
    return tabIcon[isValid] || tabIcon.default;
  };

  const handleChangeProduct = e => {
    states.data = states.data.data.filter(x => x.abbreviation !== 'NY');
    const signedValue = CommonFunctions.setSignedStatesEvent(e.target.value, states);
    setSignedStates(signedValue);
    const illustrationData = getStarted.inputvalues;
    illustrationData['#approvedStates'] = signedValue;
    dispatch(setInputValues(illustrationData));
    dispatch(setProduct(e.target.value));
  };

  const handleChangeApplicationType = e => {
    dispatch(setApplicationType(e));
  };

  const handleChangeState = e => {
    dispatch(setSignedState(e.target.value));
    dispatch(setSignedStateId(e.target.value.split('~')[0]));
    dispatch(setSignedStateAbb(e.target.value.split('~')[1]));
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body1" component="span" className={classes.aspida}>
              Aspida Illustration
            </Typography>
            <br />
            <Typography variant="body1" component="span" className={classes.lets}>
              Let's Get Started...
            </Typography>
          </Grid>
        </React.Fragment>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>Annuity Type
                </Typography>
                <DropDown
                  name="drAnnuitytype"
                  listItem={annuityTypes}
                  isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.annuityTypeQues) !== ''}
                  errorText={getStarted.inputerrors.annuityTypeQues}
                  labelText="AnnuityType"
                  variant="outlined"
                  isFullWidth
                  onChange={event => props.onChange('AQ', event.target.value)}
                  value={getStarted.inputvalues['#annuityTypeQues']}
                  className={classes.dropStyle}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>Product
                </Typography>
                <Select
                  value={getStarted.product || ''}
                  label="Product"
                  variant="outlined"
                  error={getStarted.inputerrors.product}
                  name="drpProduct"
                  onChange={handleChangeProduct}
                  className={classes.dropStyle}
                  disabled={CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#annuityTypeQues']) === ''}
                >
                  {allowedProducts.filter(productFilter).map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
                {CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.product) !== '' && (
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.proderror}>
                      Required
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.appType}>
                  <span style={{ color: 'red' }}>*</span>Type of ownership?
                </Typography>
              </Grid>
              {applicationTypes.data?.data.map((item, index) => {
                if (applicationTypes.data.data.length - 1 === index) {
                  return (
                    <Grid item xs={12} sm={12} md={3} lg={3} className={classes.appTypeBtn}>
                      <Button
                        name={item.id}
                        fullWidth
                        variant="outlined"
                        className={
                          item.id === getStarted.applicationtype
                            ? `${classes.activeTrustButton}`
                            : `${classes.appBtnTrustStyle}`
                        }
                        onClick={() => {
                          handleChangeApplicationType(item.id);
                        }}
                        startIcon={<Lock className={classes.starticonstyle} />}
                      >
                        {item.type}
                      </Button>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={12} sm={12} md={4} lg={4} className={classes.appTypeBtn}>
                    <Button
                      name={item.id}
                      fullWidth
                      variant="outlined"
                      className={
                        item.id === getStarted.applicationtype ? `${classes.activeButton}` : `${classes.appBtnStyle}`
                      }
                      onClick={() => {
                        handleChangeApplicationType(item.id);
                      }}
                      startIcon={getIcon(item.id)}
                    >
                      {item.type}
                    </Button>
                  </Grid>
                );
              })}
              {CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.applicationtype) !== '' && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.docErrorSection}>
                    Application Type is Required
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" gutterBottom className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>Solicitation State
                </Typography>
                <DropDown
                  listItem={SignedStates}
                  name="drpSignedState"
                  value={getStarted.signedstate}
                  onChange={handleChangeState}
                  isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.signedstate) !== ''}
                  errorText={getStarted.inputerrors.signedstate}
                  labelText="Solicitation State"
                  variant="outlined"
                  isFullWidth
                  disabled={getStarted.product === ''}
                  className={classes.dropStyle}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h5" className={classes.label}>
                  Is this Illustration being created on behalf of someone?
                </Typography>
              </Grid>
              {otherAgentOptions.length > 0 &&
                otherAgentOptions.map(item => {
                  return (
                    <Grid item xs={4} className={classes.appTypeBtn}>
                      <Button
                        name={item.id}
                        fullWidth
                        variant="outlined"
                        className={
                          item.id === getStarted.inputvalues['#forOtherAgent']
                            ? `${classes.activeButton}`
                            : `${classes.appBtnStyle}`
                        }
                        onClick={() => {
                          props.onChange('O', item.id);
                        }}
                        startIcon={
                          item.id === 'true' ? (
                            <ThumbUp className={classes.starticonstyle} />
                          ) : (
                            <ThumbDown className={classes.starticonstyle} />
                          )
                        }
                      >
                        {item.value}
                      </Button>
                    </Grid>
                  );
                })}
              {CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.forOtherAgent) !== '' && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.docErrorSection}>
                    Required
                  </Typography>
                </Grid>
              )}
              {getStarted.inputvalues['#forOtherAgent'] === 'true' && (
                <Grid item xs={8} sm={4} md={4} lg={5} className={classes.appTypeBtn}>
                  <TextField
                    id="otherAgentName"
                    label="Enter Producer Name"
                    name="otherAgentName"
                    onChange={event => props.onChange('T', event.target.value)}
                    onBlur={event => props.onChange('T', event.target.value)}
                    variant="outlined"
                    error={
                      getStarted.inputerrors.otherAgentName !== undefined
                        ? getStarted.inputerrors.otherAgentName !== ''
                        : false
                    }
                    helperText={getStarted.inputerrors.otherAgentName}
                    fullWidth
                    value={getStarted.inputvalues['#otherAgentName']}
                    className={classes.npnSearch}
                    InputLabelProps={{
                      className: classes.labelBackgroundColor,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        className={classes.rowButton}
      >
        <Grid item xs={8} sm={4} md={4} lg={4} className={classes.gridAlignLeftNextButton}>
          <Button fullWidth variant="contained" color="secondary" onClick={props.onClick}>
            Confirm &#38; Proceed
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ProductPicker.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};
