import to from 'await-to-js';
import axios from './config/axiosConfig';
import CleanAxios from './config/cleanAxios';

class MasterService {
  healthcheck = () => {
    return to(axios.get('/healthcheck'));
  };

  getProducts = () => {
    return CleanAxios.get('/products', { params: { sort: 'productOrder ' } }).then(res => res.data);
  };

  getSignedStates = () => {
    return CleanAxios.get('/states', { params: { sort: 'name' } }).then(res => res.data);
  };

  getApplicationTypes = () => {
    return CleanAxios.get('/applicationTypes').then(res => res.data);
  };

  getSpecificationName = query => {
    return to(axios.get(`/specification?${query}`));
  };

  getSpecification = (applicationTypeId, productId) => {
    return to(axios.get(`/specification?applicationTypeId=${applicationTypeId}&productId=${productId}&stateId=`));
  };

  getIllustrationPdf = payload => {
    return to(axios.post(`/illustrations/pdf`, payload));
  };

  illustrationRequest = payload => {
    return to(axios.post(`/illustrationRequests`, payload));
  };

  createApplicationPolicyNumber = payload => {
    return to(axios.post(`/application`, payload));
  };

  saveApplicationData = payload => {
    return to(axios.put(`/application/${payload.applicationId}`, payload));
  };

  getBankInformation = routingNumber => {
    return to(axios.get(`/bankInformation/${routingNumber}`));
  };

  getPeopleById = id => {
    return to(axios.get(`/people/${id}`));
  };

  getContractsForParty = async partyId => {
    return CleanAxios.get('/contracts', { params: { partyId } }).then(res => res.data);
  };

  updatePerson = async (partyId, payload) => {
    return to(axios.put(`/people/${partyId}`, payload));
  };

  getCurrentUser = () => to(axios.get(`/auth0/userInfo`));

  getAppConfig = params => to(axios.get('/appConfig', { params }));

  signOut = () => to(axios.get(`/auth0/logout`));
}

export default new MasterService();
