export const LOADING = 'loading';
export const PRODUCT = 'product';
export const SIGNEDSTATE = 'signedstate';
export const SIGNEDSTATEABB = 'signedstateabb';
export const SIGNEDSTATEID = 'signedstateid';
export const APPLICATIONTYPE = 'applicationtype';
export const JSONFILENAME = 'jsonfilename';
export const CURRENTPAGE = 'currentpage';
export const TRACKERGROUPNAMES = 'trackergroupnames';
export const INPUTVALUES = 'inputvalues';
export const INPUTERRORS = 'inputerrors';
export const SETVALUE = 'setvalue';
export const FASTDATA = 'fastdata';
export const RESET = 'RESET';
export const ERRORLISTS = 'errorlists';
export const PDFDATA = 'pdfData';
export const ILLUSTRATIONID = 'illustrationid';
