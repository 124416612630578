export default {
  tab: {
    height: '10rem',
    flexGrow: 1,
    maxWidth: 400,
    overflow: 'auto',
  },
  indicator: {
    backgroundColor: '#140f3c',
  },
  activeTab: {
    backgroundColor: '#ECEBEE !important',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    color: 'white',
    textTransform: 'none',
    '& > div': {
      backgroundColor: '#140f3c',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    '& > div > div': {
      fontSize: '17px',
      fontWeight: '700',
    },
  },
  activeTabSpace: {
    marginTop: '1rem',
    marginBottom: '.5rem',
    '& > div > div': {
      fontSize: '17px',
      marginLeft: '.5rem',
    },
  },
  inActiveTab: {
    backgroundColor: '#f3f3f5',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    color: '#b8b8b9',
    margin: '0 5px 0 5px',
    textTransform: 'none',
  },
  inCompleteIcon: {
    color: '#EF5350',
    fontSize: '1rem !important',
    marginLeft: '20px',
  },
  completeIcon: {
    color: '#8BC34A',
    fontSize: '1rem !important',
    marginLeft: '20px',
  },
  activeSubTab: {
    fontSize: '11px',
    textTransform: 'none',
    color: '#14043C',
    backgroundColor: '#14043C !important',
    '& > div > div': {
      fontWeight: '700',
    },
  },
  subTab: {
    '& > span > span': {
      fontSize: '11px',
      textTransform: 'none',
    },
  },
  container: {
    width: '22%',
    '@media (min-width: 2450px)': {
      width: '11%',
    },
    '@media (max-width: 800px)': {
      width: '26%',
    },
  },
  activeSubTabSpace: {
    minWidth: '0',
    marginRight: '0',
    backgroundColor: '#ECEBEE !important',
    color: '#14043C',
    '& > div > div': {
      fontSize: '15px',
      marginLeft: '.5rem',
    },
    '@media (max-width: 800px)': {
      '& > div > div': {
        fontSize: '12px',
        fontWeight: '300',
      },
    },
    borderBottomRightRadius: '10px',
  },
  iconStyle: {
    fontSize: '1rem !important',
    marginLeft: '20px',
  },
  tabContainer: {
    '@media (max-width: 800px)': {
      margin: '12px',
    },
    margin: '23px',
  },
  errorWidget: {
    margin: '.5rem 1rem',
    whiteSpace: 'nowrap',
  },
  editBtn: {
    width: '169px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    height: '32px',
    cursor: 'default',
    backgroundColor: '#EF426F',
    margin: '1rem',
  },
};
