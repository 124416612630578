import startIllustration from './Illustration/ApplicationJson/SubGroup/startIllustration.json';
import yourAnnuityIllustration from './Illustration/Financials/yourAnnuityIllustration.json';
import allocationIllustrationDetails from './Illustration/Financials/allocationIllustrationDetails.json';
import demoIllustrationFIA from './Illustration/ApplicationJson/SubGroup/demoIllustrationFIA.json';
import demoIllustrationMYGA from './Illustration/ApplicationJson/SubGroup/demoIllustrationMYGA.json';
import allocationIllustration from './Illustration/ApplicationJson/SubGroup/allocationIllustration.json';
import generateIllustration from './Illustration/ApplicationJson/SubGroup/generateIllustration.json';
import wvmygaIllustration from './Illustration/ApplicationJson/WV_MYGA_Illustration.json';
import aamygaIllustration from './Illustration/ApplicationJson/AA_MYGA_Illustration.json';
import msgmygaIllustration from './Illustration/ApplicationJson/MSG_MYGA_Illustration.json';
import wvfiaIllustration from './Illustration/ApplicationJson/WV_FIA_Illustration.json';
import msgfiaIllustration from './Illustration/ApplicationJson/MSG_FIA_Illustration.json';
import msgfiaIncomeIllustration from './Illustration/ApplicationJson/MSG_FIA_Income_Illustration.json';
import generateIllustrationDetails from './Illustration/Generate PDF/generateIllustrationDetails.json';

export default {
  demoIllustrationFIA,
  demoIllustrationMYGA,
  yourAnnuityIllustration,
  generateIllustration,
  startIllustration,
  wvmygaIllustration,
  aamygaIllustration,
  msgmygaIllustration,
  wvfiaIllustration,
  msgfiaIllustration,
  msgfiaIncomeIllustration,
  allocationIllustration,
  allocationIllustrationDetails,
  generateIllustrationDetails,
};
