import store from 'redux/store';
import { setCreatedIllustrationId, setFastData } from 'redux/getStarted/actions';
import MasterService from 'api/masterService';
import Constants from 'constants/common';
import utilFunctions from '../util/utilFunctions';
import ServiceInputJSON from '../assets/ServiceJSON';

/**
 *
 * @param {string} withdrawalOption
 * @param {string} deathBenefit
 * @param {{id: string; name: string}} product
 * @returns
 */
const getPolicyOption = (withdrawalOption, deathBenefit, product) => {
  let policyoption = 'None';
  const { policy } = Constants.policyOption.find(m => m.product.find(k => k === product.id));
  if (policy === 'SPDA') {
    policyoption = 'Single Premium Deferred Annuity';
  }

  if (policy === 'synergySPDA') {
    switch (withdrawalOption) {
      case '10% Free Withdrawal':
      case 'Free Withdrawal Amount':
        if (deathBenefit === 'Cash Surrender Value') {
          policyoption = '10 Free';
        }

        if (deathBenefit === 'Contract Value') {
          policyoption = '10 Free CV on Death';
        }

        break;
      case 'Free Interest Withdrawal':
      case 'Interest Only':
        if (deathBenefit === 'Cash Surrender Value') {
          policyoption = 'MIO';
        }

        if (deathBenefit === 'Contract Value') {
          policyoption = 'MIO CV on Death';
        }

        break;
      case 'None':
        if (deathBenefit !== 'Contract Value') {
          policyoption = 'None';
        }

        if (deathBenefit === 'Contract Value') {
          policyoption = 'CV on Death';
        }

        break;
      default:
        policyoption = 'None';
    }
  }

  return policyoption;
};

const getWithdrawalType = withDrawalOptionselected => {
  let withdrawalTypValue = '';
  switch (withDrawalOptionselected) {
    case 'None':
      withdrawalTypValue = 'None';
      break;
    case 'Free Interest Withdrawal':
    case 'Interest Only':
      withdrawalTypValue = 'Interest Only';
      break;
    case '10% Free Withdrawal':
    case 'Free Withdrawal Amount':
      withdrawalTypValue = 'Free Withdrawals';
      break;
    default:
      withdrawalTypValue = 'None';
  }

  return withdrawalTypValue;
};

const getAllocationInfo = illustrationData => {
  let allocationArray = [];
  const { appFields } = Constants;
  const nonAllocationFunds = Constants.nonAllocationFunds.find(m => m.product === illustrationData.product.id);
  if (!nonAllocationFunds) {
    for (let i = 1; i < 30; i += 1) {
      const allocationPercent = appFields.allocationPercent.replace('__', i.toString());
      if (illustrationData[allocationPercent]) {
        const allocationFundCode = appFields.allocationFundCode.replace('__', i.toString());
        const allocation = appFields.allocation.replace('__', i.toString());
        const allocationData = {
          fundCode: illustrationData[allocationFundCode],
          fundFullName: illustrationData[allocation],
          allocPercent: illustrationData[allocationPercent],
        };
        allocationArray.push(allocationData);
      }
    }
  } else {
    allocationArray = nonAllocationFunds.allocation;
  }

  const allocationInfo = allocationArray.filter(
    m => m.fundCode !== '' && m.fundFullName !== '' && m.allocPercent !== '',
  );

  return allocationInfo;
};

const getOwnerShipType = illustrationData => {
  let ownerType = '';
  switch (illustrationData.applicationtype) {
    case 'd3dddb48-4ec7-4cea-9b65-566f0226a53b':
      ownerType = 'Trust';
      break;
    case 'ad0199af-f829-4a96-9088-0d42a1db6525':
      ownerType = 'Corp';
      break;
    case '543f1d6f-4d9e-4df8-9a4d-53399f43168d':
      ownerType = illustrationData.ownershipType;
      break;
    default:
      ownerType = illustrationData.ownershipType;
  }

  return ownerType;
};

const getWithdrawalBasis = illustrationData => {
  let { withdrawalParty } = illustrationData;

  withdrawalParty = 'Owner';

  if (
    illustrationData.ifJointOwnerIncome === true &&
    illustrationData.incomeWOSelection === 'Single life lifetime income for Joint Owner'
  ) {
    withdrawalParty = 'Joint Owner';
  }

  if (
    illustrationData.ifJointOwnerIncome === true &&
    illustrationData.incomeWOSelection === 'Joint life lifetime income for the Two Owners' &&
    new Date(illustrationData.ownerDoB) < new Date(illustrationData.jointOwnerDoB)
  ) {
    withdrawalParty = 'Joint Owner';
  }

  if (
    illustrationData.incomeWOSelection === 'Joint life lifetime income with a Spouse' &&
    new Date(illustrationData.ownerDoB) < new Date(illustrationData.jointOwnerDoB)
  ) {
    withdrawalParty = 'Spouse';
  }

  if (
    illustrationData.withdrawalOptionIncome !== 'Income Withdrawal' &&
    new Date(illustrationData.ownerDoB) > new Date(illustrationData.jointOwnerDoB)
  ) {
    withdrawalParty = 'Joint Owner';
  }

  if (
    illustrationData.withdrawalOptionIncome !== 'Income Withdrawal' &&
    new Date(illustrationData.ownerDoB) < new Date(illustrationData.jointOwnerDoB)
  ) {
    withdrawalParty = 'Owner';
  }

  if (
    illustrationData.withdrawalOptionIncome !== 'Income Withdrawal' &&
    illustrationData.incomeWOSelection === 'Joint life lifetime income with a Spouse'
  ) {
    withdrawalParty = 'Owner';
  }

  return withdrawalParty;
};

const fastServiceCall = async inputvalues => {
  const inputdata = JSON.parse(JSON.stringify(inputvalues).replace(/#/g, ''));
  const applicationType = inputdata.applicationtype;
  const relToOwner = 'Unknown';
  const withDrawalfrequency =
    inputdata.withdrawalFrequency === '' || inputdata.withdrawalFrequency === undefined
      ? 'Annual'
      : inputdata.withdrawalFrequency;
  const withdrawalType = getWithdrawalType(inputdata.withdrawalOptionIllustration);
  const country = 'USA';
  const riskArea = 'Annuities';
  const covType = 'Base Coverage';
  const covOption = inputdata.product.name;
  let annuitantDoB = inputdata.ownerDoB;
  let annuitantFN = inputdata.ownerFN;
  let annuitantMN = inputdata.ownerMN;
  let annuitantLN = inputdata.ownerLN;
  let annuitantGender = inputdata.ownerSex;
  const annuitantSingleJointSpouse =
    inputdata.product.id === Constants.product.synergyIncome10 &&
    inputdata.incomeWOSelection === 'Joint life lifetime income with a Spouse';
  const annuitantOwner =
    (inputdata.product.id === Constants.product.synergyIncome10 &&
      inputdata.incomeWOSelection === 'Single life lifetime income for Owner') ||
    (inputdata.product.id === Constants.product.synergyIncome10 &&
      inputdata.ifJointOwner === true &&
      new Date(inputdata.ownerDoB) < new Date(inputdata.jointOwnerDoB) &&
      inputdata.withdrawalOptionIncome !== 'Income Withdrawal');
  const annuitantJointOwner =
    (inputdata.product.id === Constants.product.synergyIncome10 &&
      inputdata.incomeWOSelection === 'Single life lifetime income for Joint Owner') ||
    (inputdata.product.id === Constants.product.synergyIncome10 &&
      inputdata.ifJointOwner === true &&
      new Date(inputdata.ownerDoB) > new Date(inputdata.jointOwnerDoB) &&
      inputdata.withdrawalOptionIncome !== 'Income Withdrawal');

  if (
    ((inputdata.ifJointOwner === true || annuitantSingleJointSpouse) &&
      new Date(inputdata.ownerDoB) < new Date(inputdata.jointOwnerDoB)) ||
    annuitantJointOwner
  ) {
    annuitantDoB = inputdata.jointOwnerDoB;
    annuitantFN = inputdata.jointOwnerFN;
    annuitantMN = inputdata.jointOwnerMN;
    annuitantLN = inputdata.jointOwnerLN;
    annuitantGender = inputdata.jointOwnerSex;
  }

  if (annuitantOwner) {
    annuitantDoB = inputdata.ownerDoB;
    annuitantFN = inputdata.ownerFN;
    annuitantMN = inputdata.ownerMN;
    annuitantLN = inputdata.ownerLN;
    annuitantGender = inputdata.ownerSex;
  }

  const policyOption = getPolicyOption(inputdata.withdrawalOption, inputdata.deathBenefit, inputdata.product);
  const withdrawalValue =
    withdrawalType === 'None' ? '0' : utilFunctions.validatenull(inputdata.withdrawalPercent, '').toString();
  const withdrawalBegin =
    withdrawalType === 'None' ? 0 : utilFunctions.validatenull(inputdata.withdrawalStartPolicyYear, '');
  const withdrawalEnd =
    withdrawalType === 'None' ? 0 : utilFunctions.validatenull(inputdata.withdrawalEndPolicyYear, '');
  const incomeWithdrawStartYear = utilFunctions.validatenull(inputdata.incomeWithdrawStartYear, '');
  const nursingHomeStartYear = utilFunctions.validatenull(inputdata.nursingHomeStartYear, '');
  const withdrawalBasis = utilFunctions.validatenull(inputdata.withdrawalBasis, 'Single');
  const withdrawalParty = utilFunctions.validatenull(getWithdrawalBasis(inputdata), 'Owner');
  const hasJointLife =
    inputdata.withdrawalBasis === 'Joint' && inputdata.product.id === Constants.product.synergyIncome10;

  let FastRequestData = '';
  const RequestData = {};
  FastRequestData = JSON.parse(JSON.stringify(ServiceInputJSON.FASTInputJson.illustrationData));

  const ownershipType = getOwnerShipType(inputdata);

  Object.entries(FastRequestData).forEach(Key => {
    switch (Key[0]) {
      case 'effectiveDate':
        FastRequestData[Key[0]] = utilFunctions.formatDate(new Date(), '-');
        break;
      case 'product':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastProduct[inputdata.product.id],
          Key[1],
        );

        break;
      case 'signingState':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastStateCode[inputdata.signedState],
          Key[1],
        );

        break;
      case 'applicationType':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastApplicationTypeCode[applicationType],
          Key[1],
        );

        break;
      case 'annuityType':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastAnnuityTypeCode[inputdata.annuityType],
          Key[1],
        );

        break;
      case 'ownerName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(`${inputdata.ownerFN} ${inputdata.ownerLN}`, 'OwnerName');
        break;
      case 'ownerDOB':
        FastRequestData[Key[0]] = utilFunctions.validatenull(utilFunctions.formatDate(inputdata.ownerDoB, '-'), Key[1]);
        break;
      case 'jointOwnerFirstName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(inputdata.jointOwnerFN, '');
        break;
      case 'jointOwnerMiddleName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(inputdata.jointOwnerMN, '');
        break;
      case 'jointOwnerLastName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(inputdata.jointOwnerLN, '');
        break;
      case 'jointOwnerDOB':
        FastRequestData[Key[0]] =
          inputdata.ifJointOwner === true || hasJointLife
            ? utilFunctions.validatenull(utilFunctions.formatDate(inputdata.jointOwnerDoB, '-'), '')
            : '';

        break;
      case 'relToOwner':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.RelToOwnerCode[relToOwner],
          Key[1],
        );

        break;
      case 'annuitantFirstName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(annuitantFN, Key[1]);
        break;
      case 'annuitantMiddleName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(annuitantMN, '');
        break;
      case 'annuitantLastName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(annuitantLN, Key[1]);
        break;
      case 'annuitantDOB':
        FastRequestData[Key[0]] = utilFunctions.validatenull(utilFunctions.formatDate(annuitantDoB, '-'), '');
        break;
      case 'premiumAmount':
        FastRequestData[Key[0]] = utilFunctions.validatenull(inputdata.plannedValue.toString(), Key[1]);
        break;
      case 'systematicWithdrawalFrequency':
        FastRequestData[Key[0]] =
          withdrawalType === 'None'
            ? ''
            : utilFunctions.validatenull(
                ServiceInputJSON.FASTMappingCodeJson.FastWFrequecyCode[withDrawalfrequency],
                Key[1],
              );

        break;
      case 'withdrawalMaxFreePercentage':
        FastRequestData[Key[0]] = utilFunctions.validatenull(withdrawalValue, Key[1]);
        break;
      case 'withdrawalBeginYear':
        FastRequestData[Key[0]] = utilFunctions.validatenull(withdrawalBegin, 0);
        break;
      case 'withdrawalEndYear':
        FastRequestData[Key[0]] = utilFunctions.validatenull(withdrawalEnd, 0);
        break;
      case 'gender':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastGenderCode[annuitantGender],
          Key[1],
        );

        break;
      case 'smokerStatus':
        FastRequestData[Key[0]] = '0';
        break;
      case 'issueCountry':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastCountryCode[country],
          Key[1],
        );

        break;
      case 'riskArea':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastRiskAreaCode[riskArea],
          Key[1],
        );

        break;
      case 'covType':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastCovType[covType],
          Key[1],
        );

        break;
      case 'covOption':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastCovOption[covOption],
          Key[1],
        );

        break;
      case 'withdrawalType':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastWithdrawalTypeCode[withdrawalType],
          Key[1],
        );

        break;
      case 'policyOption':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastPolicyOption[policyOption],
          Key[1],
        );

        break;
      case 'fundSelection':
        FastRequestData[Key[0]].allocation = getAllocationInfo(inputdata);
        break;
      case 'marketingName':
        FastRequestData[Key[0]] = utilFunctions.validatenull(
          ServiceInputJSON.FASTMappingCodeJson.FastMarketingName[inputdata.product.id],
          Key[1],
        );

        break;
      case 'glwbWithdrawalStartYear':
        FastRequestData[Key[0]] = incomeWithdrawStartYear;
        break;
      case 'glwbWithdrawalBasis':
        FastRequestData[Key[0]] = withdrawalBasis;
        break;
      case 'glwbWithdrawalParty':
        FastRequestData[Key[0]] = withdrawalParty;
        break;
      case 'nhEnhancementStartYear':
        FastRequestData[Key[0]] = nursingHomeStartYear;
        break;
      case 'ownershipType':
        FastRequestData[Key[0]] = utilFunctions.validatenull(ownershipType, Key[1]);
        break;
      default:
        FastRequestData[Key[0]] = '1';
    }
  });

  RequestData.illustrationData = FastRequestData;

  let fastData = { resultset: {}, issuccess: false, isFastRun: true };

  const [FastIllustrationError, FastIllustrationData] = await MasterService.illustrationRequest(RequestData);

  if (FastIllustrationError !== null) {
    const fastErrorData = { resultset: FastIllustrationError, issuccess: false, isFastRun: false, isError: true };
    store.dispatch(setFastData(fastErrorData));
  } else {
    fastData = { resultset: FastIllustrationData, issuccess: true, isFastRun: false, isError: false };
    store.dispatch(setFastData(fastData));
    store.dispatch(setCreatedIllustrationId(FastIllustrationData.illustrationId ?? ''));
  }

  return fastData;
};

export default { fastServiceCall };
