import React from 'react';
import store from 'redux/store';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import CommonFunctions from 'views/illustration/utils/commonFunctions';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function Header() {
  const classes = useStyles();
  const state = store.getState();
  const writingAgent = CommonFunctions.checkIsNullOrUndefined(`${state.user.firstName} ${state.user.lastName}`);

  return (
    <Box>
      <Box className={classes.header}>
        <Grid item xs={12} sm={8} md={9} lg={9} className={classes.appNumberClient}>
          <Typography variant="body2" className={classes.appNumberTxt} gutterBottom>
            Aspida Illustration
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          className={writingAgent ? classes.gridAlignCenterExitButton : classes.displayNone}
        >
          <Button
            variant="outlined"
            name="editEappBtn"
            className={classes.editBtn}
            startIcon={<PersonIcon className={classes.personIcon} />}
          >
            {writingAgent}
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
