import store from 'redux/store';
import { setInputValues, setPdfData } from 'redux/getStarted/actions';
import Constants from 'constants/common';
import utilFunctions from '../util/utilFunctions';
import SmartCommService from '../services/smartCommService';

/**
 * It will fetch monthly Life data from the result of fast output data
 * productPrefix - which defines the prefix of the product selected
 */
const monthlyLifeTimeSection = fastdata => {
  const monthlyLifeData = JSON.parse(JSON.stringify(fastdata.formRequest.data.monthlyLifeIncomeData));

  const monthlyTableArray = monthlyLifeData.mTableData.map(tableData => {
    return {
      Age: tableData.age,
      GRMonthlyIncomce: tableData.grMonthlyIncome,
      NGRMonthlyIncomce: tableData.ngrMonthlyIncome,
      RatePer1000: tableData.ratePer1000,
    };
  });

  const MonthlyLifeTimeTable = {
    MTableData: monthlyTableArray,
  };

  return MonthlyLifeTimeTable;
};

const numericSummarySection = fastdata => {
  const { numericTable } = fastdata.formRequest.data;
  const numericTableArray = numericTable.nTableData.map(tableData => {
    return {
      ContractYr: tableData.contractYr,
      Age: tableData.age,
      Premium: tableData.premium,
      GRAnnualWithdrawal: tableData.grAnnualWithdrawal,
      GRContractvalue: tableData.grContractvalue,
      GRSurrenderValue: tableData.grSurrenderValue,
      GRDeathBenefit: tableData.grDeathBenefit,
      NGRAnnualWithdrawal: tableData.ngrAnnualWithdrawal,
      NGRContractvalue: tableData.ngrContractvalue,
      NGRSurrenderValue: tableData.ngrSurrenderValue,
      NGRDeathBenefit: tableData.ngrDeathBenefit,
    };
  });

  const numericTableData = {
    NTableData: numericTableArray,
  };
  return numericTableData;
};

const hypotheticalTableSection = fastdata => {
  const { hypotheticalTable } = fastdata.formRequest.data;
  const isIncomeProduct = fastdata.formRequest.data.marketingName === 'Synergy Choice Income 10';
  const hypotheticalTableArray = hypotheticalTable.hTableData.map(tableData => {
    return {
      ContractYr: tableData.contractYr,
      Age: tableData.age,
      Premium: tableData.premium,
      AnnualWithdrawal: tableData.annualWithdrawal,
      Contractvalue: tableData.contractvalue,
      SurrenderValue: tableData.surrenderValue,
      DeathBenefit: tableData.deathBenefit,
      InterestCredited: tableData.interestCredited,
      BenefitBase: tableData.benefitBase,
      GLWBIncome: tableData.glwbIncome,
      PremiumBonus: tableData.premiumBonus,
      UnvestedBonus: tableData.unvestedBonus,
      CreditedInterestRate: tableData.creditedInterestRate,
      CumulativeWithdrawals: tableData.cumulativeWithdrawals,
    };
  });

  const hypotheticalTableData = {
    HTableData: hypotheticalTableArray,
    BenefitBasePercentage: isIncomeProduct ? hypotheticalTable.benefitBasePercentage : '',
    HTotalData: isIncomeProduct
      ? hypotheticalTable.hTotalData.map(totalData => {
          return {
            HTotalPremium: totalData.hTotalPremium,
            HTotalInterest: totalData.hTotalInterest,
            HTotalWithdrawals: totalData.hTotalWithdrawals,
          };
        })
      : {},
  };

  return hypotheticalTableData;
};

const historicalTable = fastdata => {
  const { historicTable } = fastdata.formRequest.data;
  const historicTableArray = historicTable.hIndexData.map(tableData => {
    return {
      Index: tableData.index,
      IndexFundName: tableData.indexFundName,
      HistoryType: tableData.historyType,
      Period: tableData.period,
      AverageAnnualRateofReturn: tableData.averageAnnualRateofReturn,
      AverageAnnualRateofReturnNetofCharges: tableData.averageAnnualRateofReturnNetofCharges,
      HIndexHistData: {
        HTableData: tableData.hIndexHistData.hTableData.map(indexData => {
          return {
            ContractYr: indexData.contractYr,
            CreditedInterestRate: indexData.creditedInterestRate,
            ContractValue: indexData.contractValue,
          };
        }),
      },
    };
  });

  const historicalTableData = {
    HIndexData: historicTableArray,
  };

  return historicalTableData;
};

const supplementalHistoricalTable = fastdata => {
  const { supplementalHistoricTable } = fastdata.formRequest.data;
  const supplementalTableArray = supplementalHistoricTable.sIndexData.map(tableData => {
    return {
      Index: tableData.index,
      IndexFundName: tableData.indexFundName,
      HistoryType: tableData.historyType,
      Period: tableData.period,
      AverageAnnualRateofReturn: tableData.averageAnnualRateofReturn,
      AverageAnnualRateofReturnNetofCharges: tableData.averageAnnualRateofReturnNetofCharges,
      SIndexHistData: {
        STableData: tableData.sIndexHistData.sTableData.map(indexData => {
          return {
            ContractYr: indexData.contractYr,
            CreditedInterestRate: indexData.creditedInterestRate,
            ContractValue: indexData.contractValue,
          };
        }),
      },
    };
  });

  const supplementalHistoricTableData = {
    SIndexData: supplementalTableArray,
  };

  return supplementalHistoricTableData;
};

const supplementalTableSection = fastdata => {
  const { supplementalTable } = fastdata.formRequest.data;
  const isIncomeProduct = fastdata.formRequest.data.marketingName === 'Synergy Choice Income 10';
  const supplementalTableArray = supplementalTable
    ? supplementalTable.sTableData.map(tableData => {
        return {
          ContractYr: tableData.contractYr,
          Age: tableData.age,
          Premium: tableData.premium,
          AnnualWithdrawal: tableData.annualWithdrawal,
          Contractvalue: tableData.contractvalue,
          SurrenderValue: tableData.surrenderValue,
          DeathBenefit: tableData.deathBenefit,
          InterestCredited: tableData.interestCredited,
          BenefitBase: tableData.benefitBase,
          GLWBIncome: tableData.glwbIncome,
          PremiumBonus: tableData.premiumBonus,
          UnvestedBonus: tableData.unvestedBonus,
          CreditedInterestRate: tableData.creditedInterestRate,
          CumulativeWithdrawals: tableData.cumulativeWithdrawals,
        };
      })
    : [];

  const supplementalTableData = {
    BenefitBasePercentage: isIncomeProduct ? supplementalTable.benefitBasePercentage : '',
    STableData: supplementalTableArray,
    STotalData: isIncomeProduct
      ? supplementalTable.sTotalData.map(totalData => {
          return {
            STotalPremium: totalData.sTotalPremium,
            STotalInterest: totalData.sTotalInterest,
            STotalWithdrawals: totalData.sTotalWithdrawals,
          };
        })
      : {},
  };

  return supplementalTableData;
};

const historicTableSection = fastdata => {
  const { historicTable } = fastdata.formRequest.data;
  const { appFields } = Constants;
  const historicRecentDataArray = historicTable.hIndexData.filter(m => m.historyType === appFields.recent);
  const historicLowDataArray = historicTable.hIndexData.filter(m => m.historyType === appFields.low);
  const historicHighDataArray = historicTable.hIndexData.filter(m => m.historyType === appFields.high);
  const historicTableArray = historicRecentDataArray.map(tableData => {
    const historicLowData = historicLowDataArray.find(l => l.index === tableData.index);
    const historicHighData = historicHighDataArray.find(h => h.index === tableData.index);
    return {
      Index: tableData.index,
      IndexFundName: tableData.indexFundName,
      HighPeriod: historicHighData.period,
      HighAverageAnnualRateofReturnNetofCharges: historicHighData.averageAnnualRateofReturnNetofCharges,
      HighAverageAnnualRateofReturn: historicHighData.averageAnnualRateofReturn,
      LowPeriod: historicLowData.period,
      LowAverageAnnualRateofReturnNetofCharges: historicLowData.averageAnnualRateofReturnNetofCharges,
      LowAverageAnnualRateofReturn: historicLowData.averageAnnualRateofReturn,
      RecentPeriod: tableData.period,
      RecentAverageAnnualRateofReturnNetofCharges: tableData.averageAnnualRateofReturnNetofCharges,
      RecentAverageAnnualRateofReturn: tableData.averageAnnualRateofReturn,
      HIndexHistData: tableData.hIndexHistData.hTableData.map(iTableData => {
        const lowIndexData = historicLowData.hIndexHistData.hTableData.find(
          i => i.contractYr === iTableData.contractYr,
        );
        const highIndexData = historicHighData.hIndexHistData.hTableData.find(
          i => i.contractYr === iTableData.contractYr,
        );
        return {
          HighContractYr: highIndexData.contractYr,
          HighCreditedInterestRate: highIndexData.creditedInterestRate,
          HighContractValue: highIndexData.contractValue,
          LowContractYr: lowIndexData.contractYr,
          LowCreditedInterestRate: lowIndexData.creditedInterestRate,
          LowContractValue: lowIndexData.contractValue,
          RecentContractYr: iTableData.contractYr,
          RecentCreditedInterestRate: iTableData.creditedInterestRate,
          RecentContractValue: iTableData.contractValue,
        };
      }),
    };
  });

  const indexData = {
    HIndexData: historicTableArray,
  };

  return indexData;
};

const supplementalHistoricTableSection = fastdata => {
  const { supplementalHistoricTable } = fastdata.formRequest.data;
  const { appFields } = Constants;
  const historicRecentDataArray = supplementalHistoricTable.sIndexData.filter(m => m.historyType === appFields.recent);
  const historicLowDataArray = supplementalHistoricTable.sIndexData.filter(m => m.historyType === appFields.low);
  const historicHighDataArray = supplementalHistoricTable.sIndexData.filter(m => m.historyType === appFields.high);
  const hypotheticalTableArray = historicRecentDataArray.map(tableData => {
    const historicLowData = historicLowDataArray.find(l => l.index === tableData.index);
    const historicHighData = historicHighDataArray.find(l => l.index === tableData.index);
    return {
      Index: tableData.index,
      IndexFundName: tableData.indexFundName,
      HighPeriod: historicHighData.period,
      HighAverageAnnualRateofReturnNetofCharges: historicHighData.averageAnnualRateofReturnNetofCharges,
      HighAverageAnnualRateofReturn: historicHighData.averageAnnualRateofReturn,
      LowPeriod: historicLowData.period,
      LowAverageAnnualRateofReturnNetofCharges: historicLowData.averageAnnualRateofReturnNetofCharges,
      LowAverageAnnualRateofReturn: historicLowData.averageAnnualRateofReturn,
      RecentPeriod: tableData.period,
      RecentAverageAnnualRateofReturnNetofCharges: tableData.averageAnnualRateofReturnNetofCharges,
      RecentAverageAnnualRateofReturn: tableData.averageAnnualRateofReturn,
      SIndexHistData: tableData.sIndexHistData.sTableData.map(iTableData => {
        const lowIndexData = historicLowData.sIndexHistData.sTableData.find(
          k => k.contractYr === iTableData.contractYr,
        );
        const highIndexData = historicHighData.sIndexHistData.sTableData.find(
          k => k.contractYr === iTableData.contractYr,
        );
        return {
          HighContractYr: highIndexData.contractYr,
          HighCreditedInterestRate: highIndexData.creditedInterestRate,
          HighContractValue: highIndexData.contractValue,
          LowContractYr: lowIndexData.contractYr,
          LowCreditedInterestRate: lowIndexData.creditedInterestRate,
          LowContractValue: lowIndexData.contractValue,
          RecentContractYr: iTableData.contractYr,
          RecentCreditedInterestRate: iTableData.creditedInterestRate,
          RecentContractValue: iTableData.contractValue,
        };
      }),
    };
  });

  const indexData = {
    SIndexData: hypotheticalTableArray,
  };

  return indexData;
};

const guaranteedTableleSection = fastdata => {
  const { guaranteedTable } = fastdata.formRequest.data;
  const isIncomeProduct = fastdata.formRequest.data.marketingName === 'Synergy Choice Income 10';
  const guaranteedTableArray = guaranteedTable.gTableData.map(tableData => {
    return {
      ContractYr: tableData.contractYr,
      Age: tableData.age,
      Premium: tableData.premium,
      AnnualWithdrawal: tableData.annualWithdrawal,
      Contractvalue: tableData.contractvalue,
      SurrenderValue: tableData.surrenderValue,
      DeathBenefit: tableData.deathBenefit,
      InterestCredited: tableData.interestCredited,
      BenefitBase: tableData.benefitBase,
      GLWBIncome: tableData.glwbIncome,
      PremiumBonus: tableData.premiumBonus,
      UnvestedBonus: tableData.unvestedBonus,
      CreditedInterestRate: tableData.creditedInterestRate,
      CumulativeWithdrawals: tableData.cumulativeWithdrawals,
    };
  });

  const guaranteedTableData = {
    GTableData: guaranteedTableArray,
    BenefitBasePercentage: isIncomeProduct ? guaranteedTable.benefitBasePercentage : '',
    GTotalData: isIncomeProduct
      ? guaranteedTable.gTotalData.map(totalData => {
          return {
            GTotalPremium: totalData.gTotalPremium,
            GTotalInterest: totalData.gTotalInterest,
            GTotalWithdrawals: totalData.gTotalWithdrawals,
          };
        })
      : {},
  };

  return guaranteedTableData;
};

const withdrawalRatesSection = fastdata => {
  const { withdrawalRates } = fastdata.formRequest.data;

  const withdrawalRatesArray = withdrawalRates.wTableData.map(tableData => {
    return {
      WithdrawalYear: tableData.withdrawalYear,
      WithdrawalRate: tableData.withdrawalRate,
    };
  });

  const withdrawalRatesData = {
    WTableData: withdrawalRatesArray,
  };

  return withdrawalRatesData;
};

const rollUpRates = fastdata => {
  const { rollupRates } = fastdata.formRequest.data;

  const rollupRatesArray = rollupRates?.rTableData.map(tableData => {
    return {
      RollupYear: tableData.rollupYear,
      RollupRate: tableData.rollupRate,
    };
  });

  const rollupRatesData = {
    RTableData: rollupRatesArray,
  };

  return rollupRatesData;
};

const lifetimePercentageTableSection = fastdata => {
  const { lifetimePercentageTable } = fastdata.formRequest.data;

  const lifetimePercentageArray = lifetimePercentageTable?.lTableData.map(tableData => {
    return {
      ActivationAge: tableData.activationAge,
      SingleIncome: tableData.singleIncome,
      JointIncome: tableData.jointIncome,
    };
  });

  const lifetimePercentageTableData = {
    LTableData: lifetimePercentageArray,
  };

  return lifetimePercentageTableData;
};

const monthlyLifeSection = fastdata => {
  const { monthlyLifeIncomeData } = fastdata.formRequest.data;

  const monthlyLifeDataArray = monthlyLifeIncomeData.mTableData.map(tableData => {
    return {
      Age: tableData.age,
      GRMonthlyIncome: tableData.grMonthlyIncome,
      NGRMonthlyIncome: tableData.ngrMonthlyIncome,
      RatePer1000: tableData.ratePer1000,
    };
  });

  const monthlyLifeIncomeSection = {
    MTableData: monthlyLifeDataArray,
  };

  return monthlyLifeIncomeSection;
};

const totalsSection = fastdata => {
  const { hypotheticalTable, guaranteedTable, supplementalTable } = fastdata.formRequest.data;

  return {
    STTotalPremium: supplementalTable ? supplementalTable.sTotalData[0].sTotalPremium : '',
    STTotalInterest: supplementalTable ? supplementalTable.sTotalData[0].sTotalInterest : '',
    STTotalWithdrawals: supplementalTable ? supplementalTable.sTotalData[0].sTotalWithdrawals : '',
    HTTotalPremium: hypotheticalTable.hTotalData[0].hTotalPremium,
    HTTotalInterest: hypotheticalTable.hTotalData[0].hTotalInterest,
    HTTotalWithdrawals: hypotheticalTable.hTotalData[0].hTotalWithdrawals,
    GTTotalPremium: guaranteedTable.gTotalData[0].gTotalPremium,
    GTTotalInterest: guaranteedTable.gTotalData[0].gTotalInterest,
    GTTotalWithdrawals: guaranteedTable.gTotalData[0].gTotalWithdrawals,
  };
};

const fundStrategySummarySection = fastdata => {
  const { fundStrategySummary } = fastdata.formRequest.data;

  const strategyDetailsTableArray = fundStrategySummary
    ? fundStrategySummary.fTableData.map(tableData => {
        return {
          Index: tableData.index,
          StrategyPeriod: tableData.strategyYear,
          StrategyName: tableData.strategy,
          Allocation: tableData.allocation,
          CurrentRate: tableData.currentRate,
          GuaranteedMinimumRate: tableData.guaranteedMinimumRate ? tableData.guaranteedMinimumRate : '',
          IsCompliantFund: tableData.isCompliantFund,
        };
      })
    : [];

  const fundStrategySummaryData = {
    FTableData: strategyDetailsTableArray,
  };

  return fundStrategySummaryData;
};

const fundStrategySummaryIncome = fastdata => {
  const { fundStrategySummary } = fastdata.formRequest.data;

  const strategyDetailsTableArray = fundStrategySummary
    ? fundStrategySummary.fTableData.map(tableData => {
        return {
          Index: tableData.index,
          StrategyYear: tableData.strategyYear,
          StrategyName: tableData.strategy,
          Allocation: tableData.allocation,
          CurrentRate: tableData.currentRate,
          GuaranteedMinimumRate: tableData.guaranteedMinimumRate ? tableData.guaranteedMinimumRate : '',
          IsCompliantFund: tableData.isCompliantFund,
          IllustratedParticipationRate: tableData.illustratedParticipationRate,
          GuaranteedParticipationRate: tableData.guaranteedParticipationRate,
        };
      })
    : [];

  const fundStrategySummaryData = {
    FTableData: strategyDetailsTableArray,
  };

  return fundStrategySummaryData;
};

const numericTableTotalSection = fastdata => {
  const { totals } = fastdata.formRequest.data.numericTable;
  return {
    CumulativePremiums: totals.cumulativePremiums,
    GuarWithdrawalAmount: totals.guarWithDrawalAmount,
    NonGuarWithdrawalAmount: totals.nonGuarWithDrawalAmount,
  };
};

const withdrawalScheduleSection = fastdata => {
  const { withdrawalSchedule } = fastdata.formRequest.data;

  const withdrawalScheduleArray = withdrawalSchedule.wTableData.map(tableData => {
    return {
      Mode: tableData.mode,
      ScheduledPayments: tableData.scheduledPayments,
      Amount: tableData.amount,
      Begin: tableData.begin,
      From: tableData.from,
      Through: tableData.through,
    };
  });

  const withdrawalScheduleData = {
    WTableData: withdrawalScheduleArray,
  };

  return withdrawalScheduleData;
};

const riderAdditionalBenefitsSection = fastdata => {
  const { riderAdditionalBenefits } = fastdata.formRequest.data;

  const riderAdditionalBenefitsData = {
    FinalContractAnniversary: riderAdditionalBenefits.finalContractAnniversary,
    SingleLifeEnhancement: riderAdditionalBenefits.singleLifeEnhancement,
    JointLifeEnhancement: riderAdditionalBenefits.jointLifeEnhancement,
    ConfinementQualitfyingPeriod: riderAdditionalBenefits.confinementQualitfyingPeriod,
    EnhancementExclusionPeriod: riderAdditionalBenefits.enhancementExclusionPeriod,
    MaxEnhancementPeriod: riderAdditionalBenefits.maxEnhancementPeriod,
    BenefitBasePercent: riderAdditionalBenefits.benefitBasePercent,
  };

  return riderAdditionalBenefitsData;
};

const smartCommData = fastData => {
  const { data } = fastData.formRequest;
  const state = store.getState();
  const illustrationData = state.getStarted.inputvalues;
  const inputData = JSON.parse(JSON.stringify(illustrationData).replace(/#/g, ''));

  illustrationData['#preparedName'] =
    inputData.ifJointOwner === true
      ? `${inputData.ownerFN} ${inputData.ownerLN}, ${inputData.jointOwnerFN} ${inputData.jointOwnerLN}`
      : `${inputData.ownerFN} ${inputData.ownerLN}`;

  illustrationData['#date'] = utilFunctions.formatDate(new Date(), '/');

  illustrationData['#initialPremium'] = inputData.plannedValue;
  illustrationData['#initialPremiumFast'] = data.initialPremium;

  illustrationData['#agentName'] =
    inputData.forOtherAgent === 'true' ? inputData.otherAgentName : `${state.user.firstName} ${state.user.lastName}`;

  illustrationData['#custGender'] =
    inputData.ifJointOwner === true
      ? utilFunctions.addStringWithComma(inputData.ownerSex, inputData.jointOwnerSex)
      : utilFunctions.validatenull(inputData.ownerSex, '');

  illustrationData['#custDOB'] =
    inputData.ifJointOwner === true
      ? utilFunctions.addStringWithComma(inputData.ownerDoB, data.jointOwnerDOB)
      : data.annuitantDOB;

  illustrationData['#issueAge'] =
    inputData.ifJointOwner === true
      ? utilFunctions.addStringWithComma(inputData.ownerAge, data.jointOwnerIssueAge)
      : data.annuitantIssueAge;

  illustrationData['#withDrawalBenefit'] = data.withdrawalBenefit ? data.withdrawalBenefit : '';

  illustrationData['#deathBenefit'] = data.deathBenefit ? data.deathBenefit : '';

  illustrationData['#templateId'] = data.templateId;
  illustrationData['#resourceId'] = data.resourceId;

  illustrationData['#ownerName'] = [inputData.ownerFN, inputData.ownerMN, inputData.ownerLN, inputData.ownerSF]
    .filter(Boolean)
    .join(' ');

  illustrationData['#ownerDOB'] = data.ownerDOB;
  illustrationData['#jointOwnerName'] =
    inputData.ifJointOwner === true
      ? [inputData.jointOwnerFN, inputData.jointOwnerMN, inputData.jointOwnerLN, inputData.jointOwnerSF]
          .filter(Boolean)
          .join(' ')
      : '';

  illustrationData['#jointOwnerDOB'] = data.jointOwnerDOB;
  illustrationData['#stateOfIssue'] = data.stateOfIssue;
  illustrationData['#initialRate'] = data.currCreditingRate;
  illustrationData['#minimumRate'] = data.guarCreditingRate;
  illustrationData['#supplementalRequired'] = data.supplementalRequired ? data.supplementalRequired : '';
  illustrationData['#illustrationPrintFormName1'] = data.illustrationPrintFormName1
    ? data.illustrationPrintFormName1
    : '';

  illustrationData['#illustrationPrintFormName2'] = data.illustrationPrintFormName2
    ? data.illustrationPrintFormName2
    : '';

  illustrationData['#numberofAllocatedFunds'] = data.numberofAllocatedFunds ? data.numberofAllocatedFunds : '';
  illustrationData['#numberOfAllocatedCompliantFunds'] = data.numberOfAllocatedCompliantFunds
    ? data.numberOfAllocatedCompliantFunds
    : '';

  illustrationData['#numberOfAllocatedNonCompliantFunds'] = data.numberOfAllocatedNonCompliantFunds
    ? data.numberOfAllocatedNonCompliantFunds
    : '';

  illustrationData['#totalCompliantFundAllocation'] = data.totalCompliantFundAllocation
    ? data.totalCompliantFundAllocation
    : '';

  illustrationData['#totalNonCompliantFundAllocation'] = data.totalNonCompliantFundAllocation
    ? data.totalNonCompliantFundAllocation
    : '';

  illustrationData['#marketingName'] = data.marketingName ? data.marketingName : '';

  illustrationData['#ownerIssueAge'] = data.ownerIssueAge;
  illustrationData['#jointOwnerIssueAge'] = data.jointOwnerIssueAge;
  illustrationData['#glwbWithdrawalStartYear'] = data.glwbWithdrawalStartYear;
  illustrationData['#nhEnhancementStartYear'] = data.nhEnhancementStartYear;
  illustrationData['#glwbWithdrawalBasis'] = data.glwbWithdrawalBasis;
  illustrationData['#glwbWithdrawalParty'] = data.glwbWithdrawalParty;
  illustrationData['#riderWithdrawalBasis'] = data.riderWithdrawalBasis;
  illustrationData['#illustrationEffectiveDate'] = data.illustrationEffectiveDate;
  illustrationData['#publishedFor'] = data.publishedFor;
  illustrationData['#annuitantDOB'] = data.annuitantDOB;
  illustrationData['#annuitantIssueAge'] = data.annuitantIssueAge;
  illustrationData['#gender'] = data.gender;
  illustrationData['#company'] = data.company;

  illustrationData['#glwbActivationAge'] = data.glwbActivationAge;
  illustrationData['#glwbIncomeFactor'] = data.glwbIncomeFactor;
  illustrationData['#ownershipTypeSC'] = data.ownershipType;
  illustrationData['#preActivationRiderCharge'] = data.preActivationRiderCharge;
  illustrationData['#postActivationRiderCharge'] = data.postActivationRiderCharge;

  store.dispatch(setInputValues(illustrationData));
};

const preFastService = () => {
  const state = store.getState();
  const fastData = state.getStarted.fastdata;
  const { appFields, schemaCodes } = Constants;
  if (fastData !== null && fastData !== undefined && !fastData.isFastRun) {
    if (fastData.issuccess) {
      const pdfData = {
        resultset: state.getStarted.pdfData.resultset,
        isButton: false,
        isCalled: true,
      };
      store.dispatch(setPdfData(pdfData));

      const jsonName = state.getStarted.jsonfilename.replace('.json', '');
      const schemaCode = schemaCodes[jsonName];

      const illustrationData = state.getStarted.inputvalues;

      if (schemaCode.prefix === schemaCodes.WV_FIA_Illustration.prefix) {
        illustrationData[appFields.fiaHypotheticalValuesTable] = hypotheticalTableSection(fastData.resultset);
        illustrationData[appFields.fiaGuaranteedValuesTable] = guaranteedTableleSection(fastData.resultset);
        illustrationData[appFields.fiaAdditionalBenefits] = withdrawalRatesSection(fastData.resultset);
        illustrationData[appFields.fiaMonthlyLifeTimeTable] = monthlyLifeSection(fastData.resultset);
        illustrationData[appFields.fiaTotals] = totalsSection(fastData.resultset);
        illustrationData[appFields.fiaSupplementalTable] = fastData.resultset.formRequest.data.supplementalTable
          ? supplementalTableSection(fastData.resultset)
          : {};

        illustrationData[appFields.fiaHistoricTable] = fastData.resultset.formRequest.data.historicTable
          ? historicTableSection(fastData.resultset)
          : {};

        illustrationData[appFields.historicTable] = fastData.resultset.formRequest.data.historicTable
          ? historicalTable(fastData.resultset)
          : {};

        illustrationData[appFields.supplementalHistoricalTable] = fastData.resultset.formRequest.data
          .supplementalHistoricTable
          ? supplementalHistoricalTable(fastData.resultset)
          : {};

        illustrationData[appFields.fiaSupplementalHistoricTable] = fastData.resultset.formRequest.data
          .supplementalHistoricTable
          ? supplementalHistoricTableSection(fastData.resultset)
          : {};

        illustrationData[appFields.withdrawalSchedule] = fastData.resultset.formRequest.data.withdrawalSchedule
          ? withdrawalScheduleSection(fastData.resultset)
          : {};

        illustrationData[appFields.riderAdditionalBenefits] = fastData.resultset.formRequest.data
          .riderAdditionalBenefits
          ? riderAdditionalBenefitsSection(fastData.resultset)
          : {};

        illustrationData[appFields.fundStrategySummarySection] = fundStrategySummarySection(fastData.resultset);

        illustrationData[appFields.fundStrategySummaryIncome] = fundStrategySummaryIncome(fastData.resultset);

        illustrationData[appFields.lifetimePercentageTable] = lifetimePercentageTableSection(fastData.resultset);

        illustrationData[appFields.rollupRates] = rollUpRates(fastData.resultset);
      } else {
        illustrationData[appFields.monthlyTableData] = monthlyLifeTimeSection(fastData.resultset);
        illustrationData[appFields.numericTable] = numericSummarySection(fastData.resultset);
        illustrationData[appFields.withdrawalCharges] = withdrawalRatesSection(fastData.resultset);
        illustrationData[appFields.numericTableTotal] = numericTableTotalSection(fastData.resultset);
      }

      store.dispatch(setInputValues(illustrationData));
      smartCommData(fastData.resultset);
      SmartCommService.prepareSmartCommData(
        schemaCode,
        state.getStarted.createdIllustrationId,
        state.getStarted.inputvalues,
      );
    }
  }
};

export default {
  preFastService,
  rollUpRates,
  lifetimePercentageTableSection,
};
