import * as UserTypes from './types';

export const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case UserTypes.USER: {
      return action.value;
    }

    case UserTypes.VERIFIED: {
      return { ...state, verified: action.value };
    }

    default: {
      return state;
    }
  }
};
