export default {
  modal: {
    textAlign: 'center',
    overflowY: 'hidden',
    position: 'relative',
  },
  modalClear: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },
  modalTitle: {
    marginLeft: '1.4rem',
    marginTop: '.5rem',
  },
  modalText: {
    marginBottom: '2.5rem',
  },
  modalButton: {
    margin: '.5rem',
    width: '225px',
  },
  IframeStyle: {
    width: '1000px',
    height: '413px',
    '@media (max-width: 1024px)': {
      width: '700px',
    },
  },
};
