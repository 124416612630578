import * as GetStartedTypes from './types';

export const initialState = {
  loading: false,
  product: '',
  signedstate: '',
  signedstateabb: '',
  signedstateid: '',
  applicationtype: '',
  currentpage: { Group: 0, SubGroup: 0, JsonData: '' },
  inputvalues: {},
  inputerrors: { annuityTypeQues: '', product: '', applicationtype: '', signedstate: '' },
  fastdata: { resultset: {}, issuccess: false, isFastRun: false, isError: false },
  trackergroupnames: [],
  errorlists: [],
  pdfData: { resultset: '', isButton: false, isCalled: false, isError: false },
  createdIllustrationId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GetStartedTypes.LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }

    case GetStartedTypes.PRODUCT: {
      return {
        ...state,
        product: action.value,
      };
    }

    case GetStartedTypes.APPLICATIONTYPE: {
      return {
        ...state,
        applicationtype: action.value,
      };
    }

    case GetStartedTypes.SIGNEDSTATE: {
      return {
        ...state,
        signedstate: action.value,
      };
    }

    case GetStartedTypes.SIGNEDSTATEABB: {
      return {
        ...state,
        signedstateabb: action.value,
      };
    }

    case GetStartedTypes.SIGNEDSTATEID: {
      return {
        ...state,
        signedstateid: action.value,
      };
    }

    case GetStartedTypes.JSONFILENAME: {
      return {
        ...state,
        jsonfilename: action.value,
      };
    }

    case GetStartedTypes.CURRENTPAGE: {
      return {
        ...state,
        currentpage: action.value,
      };
    }

    case GetStartedTypes.TRACKERGROUPNAMES: {
      return {
        ...state,
        trackergroupnames: action.value,
      };
    }

    case GetStartedTypes.INPUTVALUES: {
      return {
        ...state,
        inputvalues: action.value,
      };
    }

    case GetStartedTypes.INPUTERRORS: {
      return {
        ...state,
        inputerrors: action.value,
      };
    }

    case GetStartedTypes.ERRORLISTS: {
      return {
        ...state,
        errorlists: action.value,
      };
    }

    case GetStartedTypes.RESET: {
      return {
        ...state,
        product: '',
        signedstate: '',
        applicationtype: '',
        currentpage: { Group: 0, SubGroup: 0, JsonData: '' },
        inputvalues: {},
        inputerrors: { annuityTypeQues: '', product: '', applicationtype: '', signedstate: '' },
        trackergroupnames: [],
        pdfData: { resultset: '', isButton: false },
      };
    }

    case GetStartedTypes.SETVALUE: {
      return {
        ...state,
        product: action.value.product,
        signedstate: action.value.signedstate,
        signedstateabb: action.value.signedstateabb,
        signedstateid: action.value.signedstateid,
        applicationtype: action.value.applicationtype,
        currentpage: action.value.currentpage,
        inputvalues: action.value.inputvalues,
        inputerrors: action.value.inputerrors,
        trackergroupnames: action.value.trackergroupnames,
        errorlists: action.value.errorlists,
      };
    }

    case GetStartedTypes.FASTDATA: {
      return {
        ...state,
        fastdata: action.value,
      };
    }

    case GetStartedTypes.PDFDATA: {
      return {
        ...state,
        pdfData: action.value,
      };
    }

    case GetStartedTypes.ILLUSTRATIONID: {
      return {
        ...state,
        createdIllustrationId: action.value,
      };
    }

    default: {
      return { ...state };
    }
  }
};
