import { useQuery } from 'react-query';
import masterService from 'api/masterService';

const useStates = () => {
  const states = useQuery({
    queryKey: ['states'],
    queryFn: async () => masterService.getSignedStates(),
    refetchOnMount: false,
    staleTime: Infinity,
  });

  return states;
};

export default useStates;
