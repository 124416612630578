import store from 'redux/store';
import {
  setProduct,
  setApplicationType,
  setSignedState,
  setInputErrors,
  setSignedStateId,
  setSignedStateAbb,
  setFastData,
  setInputValues,
} from 'redux/getStarted/actions';

function getStartedPageValidation(getStarted) {
  let otherAgentName = '';
  if (getStarted.inputvalues['#forOtherAgent'] === 'true') {
    otherAgentName = getStarted.inputvalues['#otherAgentName'] === '' ? 'Required' : '';
  }

  const inputErrors = Object.assign(getStarted.inputerrors, {
    annuityTypeQues: getStarted.inputvalues['#annuityTypeQues'] ? '' : 'Required',
    product: getStarted.product === '' ? 'Required' : '',
    applicationtype: getStarted.applicationtype === '' ? 'Required' : '',
    signedstate: getStarted.signedstate === '' ? 'Required' : '',
    otherAgent: getStarted.inputvalues['#forOtherAgent'] === '' ? 'Required' : '',
    otherAgentName,
  });
  return (
    inputErrors.product !== '' ||
    inputErrors.annuityTypeQues !== '' ||
    inputErrors.applicationtype !== '' ||
    inputErrors.signedstate !== '' ||
    inputErrors.otherAgent !== '' ||
    inputErrors.otherAgentName !== ''
  );
}

const handleProductPickOnchangeEvent = (field, value) => {
  const state = store.getState();
  const fastData = state.getStarted.fastdata;
  const inputValues = state.getStarted.inputvalues;
  if (fastData !== undefined) {
    const enfastData = { resultset: {}, issuccess: false, isFastRun: false, isError: false };
    store.dispatch(setFastData(enfastData));
  }

  const inputErrors = state.getStarted.inputerrors;
  if (field === 'AQ') {
    inputErrors.annuityTypeQues = '';
    inputValues['#annuityTypeQues'] = value;
    state.getStarted.product = '';
    store.dispatch(setProduct(''));
  }

  if (field === 'P') {
    inputErrors.product = '';
    store.dispatch(setProduct(value));
  }

  if (field === 'S') {
    inputErrors.signedstate = '';
    store.dispatch(setSignedStateId(value.split('~')[0]));
    store.dispatch(setSignedStateAbb(value.split('~')[1]));
    store.dispatch(setSignedState(value));
  }

  if (field === 'A') {
    inputErrors.applicationtype = '';
    store.dispatch(setApplicationType(value));
  }

  if (field === 'O') {
    inputErrors.otherAgent = '';
    inputValues['#forOtherAgent'] = value;
    store.dispatch(setInputValues(inputValues));
  }

  if (field === 'T') {
    inputValues['#otherAgentName'] = value;
    inputErrors.otherAgentName = '';
    store.dispatch(setInputValues(inputValues));
  }

  store.dispatch(setInputErrors(inputErrors));
};

export default {
  getStartedPageValidation,
  handleProductPickOnchangeEvent,
};
