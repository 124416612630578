import * as GetStartedTypes from './types';

export const setLoading = value => dispatch => {
  dispatch({ type: GetStartedTypes.LOADING, value });
};

export const setProduct = value => dispatch => {
  dispatch({ type: GetStartedTypes.PRODUCT, value });
};

export const setSignedState = value => dispatch => {
  dispatch({ type: GetStartedTypes.SIGNEDSTATE, value });
};

export const setSignedStateAbb = value => dispatch => {
  dispatch({ type: GetStartedTypes.SIGNEDSTATEABB, value });
};

export const setSignedStateId = value => dispatch => {
  dispatch({ type: GetStartedTypes.SIGNEDSTATEID, value });
};

export const setApplicationType = value => dispatch => {
  dispatch({ type: GetStartedTypes.APPLICATIONTYPE, value });
};

export const setJSONFilename = value => dispatch => {
  dispatch({ type: GetStartedTypes.JSONFILENAME, value });
};

export const setCurrentPage = value => dispatch => {
  dispatch({ type: GetStartedTypes.CURRENTPAGE, value });
};

export const setTrackerGroupName = value => dispatch => {
  dispatch({ type: GetStartedTypes.TRACKERGROUPNAMES, value });
};

export const setInputValues = value => dispatch => {
  dispatch({ type: GetStartedTypes.INPUTVALUES, value });
};

export const setInputErrors = value => dispatch => {
  dispatch({ type: GetStartedTypes.INPUTERRORS, value });
};

export const setErrorLists = value => dispatch => {
  dispatch({ type: GetStartedTypes.ERRORLISTS, value });
};

export const setFastData = value => dispatch => {
  dispatch({ type: GetStartedTypes.FASTDATA, value });
};

export const setReset = value => dispatch => {
  dispatch({ type: GetStartedTypes.RESET, value });
};

export const setValue = value => dispatch => {
  dispatch({ type: GetStartedTypes.SETVALUE, value });
};

export const setPdfData = value => dispatch => {
  dispatch({ type: GetStartedTypes.PDFDATA, value });
};

export const setCreatedIllustrationId = value => dispatch => {
  dispatch({ type: GetStartedTypes.ILLUSTRATIONID, value });
};
