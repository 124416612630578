/* eslint-disable no-console */
/**
 * Based on
 * https://medium.com/@fakiolinho/handle-blobs-requests-with-axios-the-right-way-bb905bdb1c04
 * */
const blobReader = data => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Problem parsing file.'));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(data);
  });
};

const transformBlob = async blobResponse => {
  try {
    const blobData = await blobReader(blobResponse.data);
    const data = JSON.parse(blobData);
    return { ...blobResponse, ...{ data } };
  } catch (e) {
    console.error(e);
  }
};

/**
 * Checks if a response is a Blob
 * @param {Object} error
 */
const isBlob = error => {
  return (
    error.request &&
    error.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  );
};

export default { isBlob, transformBlob };
