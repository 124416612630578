import { CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import styles from './styles';

const useStyles = makeStyles(styles);

/**
 * Component to handle conditional rendering based on an appConfig feature
 *
 * @param {FeatureGuardProps}
 */
export default function FeatureGuard({ featureName, enabledElement, disabledElement, waitingElement }) {
  const classes = useStyles();
  const appConfig = useSelector(state => state.appConfig);
  const isFeatureEnabled = appConfig.config[featureName]?.enabled;
  const hasFeatureLoaded = !_.isNil(isFeatureEnabled);

  const componentToRender = isFeatureEnabled ? enabledElement : disabledElement;

  return hasFeatureLoaded ? (
    <>{componentToRender}</>
  ) : (
    <>{waitingElement ?? <CircularProgress className={classes.loadingSpinner} />}</>
  );
}

FeatureGuard.propTypes = {
  featureName: PropTypes.string,
  enabledElement: PropTypes.element,
  disabledElement: PropTypes.element,
  waitingElement: PropTypes.element,
};

/**
 * @typedef {Object} FeatureGuardProps
 * @property {String} featureName - the feature name you want to guard on
 * @property {React.JSX.Element} enabledElement - React component to render if feature is enabled
 * @property {React.JSX.Element} disabledElement - React component to render if feature is disabled
 * @property {React.JSX.Element} waitingElement - React component to render while waiting for feature status (defaults to CircularProgress)
 */
