import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  RadioButton,
  ZipTextbox,
  PhoneField,
  TINTextbox,
  CurrencyTextbox,
  PercentageTextbox,
  DateField,
  DropDown,
  SSNTextbox,
  UploadButton,
  AddressSearch,
  Tooltip,
} from '@aspida/react-components';
import CustomPercentageTextbox from 'components/customPercent';
import Radio from 'components/radio';
import CheckBox from 'components/checkbox';
import { Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ReviewGrid from 'components/reviewGrid';
import ConstantNotification from 'components/constantNotification';
import AllocationCheck from 'components/allocationComp';
import CommonFunctions from 'views/illustration/utils/commonFunctions';
import InputAdornment from '@material-ui/core/InputAdornment';
import styles from '../styles';

const useStyles = makeStyles(styles);

const tooltipMessageFn = (tooltipTextVal, tooltipStyle) => {
  return <Tooltip message={tooltipTextVal} className={tooltipStyle} placement="right" />;
};
export default function RenderForms(props) {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState({});
  const getStarted = useSelector(state => state.getStarted);
  const Values = getStarted.inputvalues;
  const errors = getStarted.inputerrors;
  let jsonData = [];
  jsonData = props.JsonData;

  const handleFocus = (_, fieldName) => {
    setIsFocused({ fieldName });
  };
  const handleDisableNumericEvent = (e, isAction) => {
    if (isAction) {
      const charCode = e.which ? e.which : e.keyCode;
      if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)) {
        e.preventDefault();
      }
    }
  };
  const allocationRequired = () => {
    return (
      <p style={{ margin: 0, fontWeight: 'bold' }}>
        <span style={{ color: 'red' }}>*</span>Allocation %
      </p>
    );
  };

  const setReqButtonStyles = value => {
    if (value === 100) {
      return classes.splPercentFilled;
    }
    if (value !== 100) {
      return classes.splPercentError;
    }
  };
  const setNotReqButtonStyles = value => {
    if (value === 100) {
      return classes.splPercentFilled;
    }
    if (value > 0 && value !== 100) {
      return classes.splPercentError;
    }
    return classes.splPercentFilled;
  };
  const datefieldRequired = (text, group) => {
    if (group) {
      return (
        <p style={{ margin: 0 }}>
          {text}
          <span style={{ color: 'red' }}>*</span>
        </p>
      );
    }
  };

  const fieldRequired = (text, group) => {
    if (group) {
      return (
        <p style={{ margin: 0 }}>
          <span style={{ color: 'red' }}>*</span>
          {text}
          {group.fieldProperty.tooltipText !== '' &&
            CommonFunctions.tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)}
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      {jsonData !== ''
        ? jsonData.map(fieldGroup => {
            const group = CommonFunctions.fillFieldDefaults(fieldGroup);
            const { widgetType } = group.fieldProperty;
            switch (widgetType) {
              case 'currency':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      key={group.fieldName}
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'currency',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                        marginLeft: `${
                          group.fieldProperty.styles.marginLeft !== undefined
                            ? group.fieldProperty.styles.marginLeft
                            : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 3}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 3}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                            fontSize: `${group.fieldProperty.styles.labelFontSize}em`,
                          }}
                        >
                          {group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.labelText, group)
                            : group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <CurrencyTextbox
                        id={`txt${group.fieldName}`}
                        label={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        name={group.fieldName}
                        onBlur={event => props.onChange(event, group.fieldValidation, 'currency', group.fieldName)}
                        onChange={event => props.onChange(event, undefined, undefined, group.fieldName)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        helperText={errors[group.fieldName]}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName]}
                        InputLabelProps={{
                          className: classes.labelBackgroundColor,
                        }}
                        variant="outlined"
                        tooltipText={group.fieldProperty.tooltipText}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'radio':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'radiobutton',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <RadioButton
                        name={group.fieldName}
                        labelText={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.labelText, group)
                            : group.fieldProperty.labelText
                        }
                        listItem={group.fieldProperty.choices}
                        isError={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        errorText={errors[group.fieldName]}
                        value={Values[group.fieldName]}
                        selectedValue={Values[group.fieldName]}
                        onClick={event => props.onChange(event, group.fieldValidation, 'radio', group.fieldName)}
                        color={group.fieldProperty.styles.labelFontColor}
                        fontSize={group.fieldProperty.styles.labelFontSize}
                        radioFontSize={group.fieldProperty.styles.radioFontSize}
                        fontVariant={group.fieldProperty.styles.labelFontVariant}
                        justifyContent={group.fieldProperty.styles.justifyContent}
                        fontWeight={group.fieldProperty.styles.labelFontWeight}
                        grid={group.fieldProperty.grid}
                        disabled={!group.fieldProperty.isEditable}
                        group={group}
                        onChange={props.onChange}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'checkbox':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'checkbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 4}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 4}
                    >
                      <CheckBox
                        name={group.fieldName}
                        labelText={group.fieldProperty.labelText}
                        listItem={group.fieldProperty.choices}
                        isError={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        errorText={errors[group.fieldName]}
                        selectedValue={Values[group.fieldName]}
                        onChange={event => props.onChange(event, group.fieldValidation, 'checkbox', group.fieldName)}
                        color={group.fieldProperty.styles.labelFontColor}
                        fontVariant={group.fieldProperty.styles.labelFontVariant}
                        fontWeight={group.fieldProperty.styles.labelFontWeight}
                        tooltipText={group.fieldProperty.tooltipText}
                        checktextWrap={group.fieldProperty.styles.textWrap}
                        checkmarginLeft={group.fieldProperty.styles.marginLeft}
                        checkmarginTop={group.fieldProperty.styles.checkmarginTop}
                        textPlacement="right"
                        disabled={!group.fieldProperty.isEditable}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'radioQuestion':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'radiobutton',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Radio
                        name={group.fieldName}
                        labelText={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.labelText, group)
                            : group.fieldProperty.labelText
                        }
                        listItem={group.fieldProperty.choices}
                        isError={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        errorText={errors[group.fieldName]}
                        selectedValue={Values[group.fieldName]}
                        value={Values[group.fieldName]}
                        onClick={event =>
                          props.onChange(event, group.fieldValidation, 'radioQuestion', group.fieldName)
                        }
                        color={group.fieldProperty.styles.labelFontColor}
                        fontSize={group.fieldProperty.styles.labelFontSize}
                        radioFontSize={group.fieldProperty.styles.radioFontSize}
                        fontVariant={group.fieldProperty.styles.labelFontVariant}
                        justifyContent={group.fieldProperty.styles.justifyContent}
                        fontWeight={group.fieldProperty.styles.labelFontWeight}
                        grid={group.fieldProperty.grid}
                        disabled={!group.fieldProperty.isEditable}
                        group={group}
                        onChange={props.onChange}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'zip':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'ZipTextbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <ZipTextbox
                        id={`txt${group.fieldName}`}
                        name={group.fieldName}
                        label={group.fieldProperty.infieldLabelText}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        helperText={errors[group.fieldName]}
                        InputLabelProps={{
                          shrink:
                            CommonFunctions.checkIsNullOrUndefined(Values[group.fieldName]) !== '' ||
                            isFocused[group.fieldName],
                          className: classes.labelBackgroundColor,
                        }}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName]}
                        variant="outlined"
                        tooltipText={group.fieldProperty.tooltipText}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'tin':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'TINTextbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <TINTextbox
                        id={`txt${group.fieldName}`}
                        name={group.fieldName}
                        label={group.fieldProperty.infieldLabelText}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        helperText={errors[group.fieldName]}
                        InputLabelProps={{
                          className: classes.labelBackgroundColor,
                        }}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName]}
                        variant="outlined"
                        tooltipText={group.fieldProperty.tooltipText}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'phone':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                          MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'PhoneField',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <PhoneField
                        id={`txt${group.fieldName}`}
                        name={group.fieldName}
                        label={group.fieldProperty.infieldLabelText}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        helperText={errors[group.fieldName]}
                        InputLabelProps={{
                          shrink:
                            CommonFunctions.checkIsNullOrUndefined(Values[group.fieldName]) !== '' ||
                            isFocused[group.fieldName],
                          className: classes.labelBackgroundColor,
                        }}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName]}
                        variant="outlined"
                        tooltipText={group.fieldProperty.tooltipText}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'button':
                return (
                  <Grid
                    key={group.fieldName}
                    item
                    id={`grd${group.fieldName}`}
                    className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                    style={{
                      marginTop: `${
                        group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                      }rem`,
                    }}
                    xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                    sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 12}
                    md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 12}
                    lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 12}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={event => props.onChange(event, group.fieldValidation, 'button', group.fieldName)}
                      value={group.fieldProperty.labelText}
                      disabled={!group.fieldProperty.isEditable}
                      name={group.fieldName}
                    >
                      {group.fieldProperty.labelText}
                    </Button>
                  </Grid>
                );
              case 'date':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'DateField',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <DateField
                        autoOk="true"
                        inputVariant="outlined"
                        name={group.fieldName}
                        className={classes.percentageStyle}
                        variant="inline"
                        openTo="year"
                        id={`dat${group.fieldName}`}
                        label={
                          group.fieldProperty.isRequired
                            ? datefieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        format="MM/dd/yyyy"
                        margin="none"
                        InputLabelProps={{
                          className: classes.labelBackgroundColor,
                        }}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        fullWidth
                        helperText={errors[group.fieldName]}
                        onChange={event => props.onChange(event, group.fieldValidation, 'date', group.fieldName)}
                        onBlur={event => props.onChange(event, group.fieldValidation, 'date', group.fieldName)}
                        value={Values[group.fieldName] || null}
                        disabled={!group.fieldProperty.isEditable}
                        InputAdornmentProps={{ position: 'start' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {group.fieldProperty.tooltipText !== '' &&
                                tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'percent':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'PercentageTextbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <PercentageTextbox
                        id={`txt${group.fieldName}`}
                        name={group.fieldName}
                        label={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        onChange={event => props.onChange(event, undefined, undefined, group.fieldName)}
                        onBlur={event => props.onChange(event, group.fieldValidation, 'percent', group.fieldName)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName] || ''}
                        helperText={errors[group.fieldName]}
                        InputLabelProps={{
                          className: classes.labelBackgroundColor,
                        }}
                        variant="outlined"
                        tooltipText={group.fieldProperty.tooltipText}
                        isDecimal={group.fieldProperty.isDecimal}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'customPercent':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'PercentageTextbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                        marginLeft: `${
                          group.fieldProperty.styles.marginLeft !== undefined
                            ? group.fieldProperty.styles.marginLeft
                            : 0
                        }rem`,
                      }}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: `${group.fieldProperty.styles.labelFontWeight}`,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <CustomPercentageTextbox
                        id={`txt${group.fieldName}`}
                        name={group.fieldName}
                        label={
                          group.fieldProperty.isAllocation ? allocationRequired() : group.fieldProperty.infieldLabelText
                        }
                        onBlur={event => props.onChange(event, group.fieldValidation, 'percent', group.fieldName)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        fullWidth
                        value={Values[group.fieldName] || 0}
                        helperText={errors[group.fieldName]}
                        disabled={!group.fieldProperty.isEditable}
                        InputLabelProps={{
                          className: group.fieldProperty.isEditable && classes.labelBackgroundColor,
                        }}
                        variant="outlined"
                        isDecimal={group.fieldProperty.isDecimal}
                        className={
                          group.fieldProperty.isRequired
                            ? setReqButtonStyles(Values[group.fieldName])
                            : setNotReqButtonStyles(Values[group.fieldName])
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'label':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12 MuiGrid-grid-sm-12
                          MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      key={group.fieldName}
                      item
                      id={`grd${group.fieldName}`}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={
                        group.fieldProperty.specialLabel
                          ? {
                              maxWidth: `${group.fieldProperty.styles.maxWidth}%`,
                              borderRadius: `${group.fieldProperty.styles.borderRadius}px`,
                              backgroundColor: `${group.fieldProperty.styles.backgroundColor}`,
                              marginTop: `${
                                group.fieldProperty.styles.marginTop !== undefined
                                  ? group.fieldProperty.styles.marginTop
                                  : 0
                              }rem`,
                            }
                          : {
                              marginTop: `${
                                group.fieldProperty.styles.marginTop !== undefined
                                  ? group.fieldProperty.styles.marginTop
                                  : 0
                              }rem`,
                            }
                      }
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 4}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 4}
                    >
                      {group.fieldProperty.isDivider === true ? (
                        <div className={classes.dividerStyle}>
                          <hr />
                        </div>
                      ) : (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          className={group.fieldProperty.isSpecialLabel && classes.spllabelStyle}
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontStyle: group.fieldProperty.styles.labelFontStyle,
                            fontWeight: `${group.fieldProperty.styles.labelFontWeight}`,
                            fontSize: `${group.fieldProperty.styles.labelFontSize}em`,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                            marginLeft: `${group.fieldProperty.styles.marginLeft}em`,
                            marginRight: `${group.fieldProperty.styles.marginRight}em`,
                            margin: `${group.fieldProperty.styles.margin}`,
                            borderRadius: `${group.fieldProperty.styles.borderRadius}px`,
                            backgroundColor: `${group.fieldProperty.styles.backgroundColor}`,
                            width: `${group.fieldProperty.styles.width}`,
                            padding: `${group.fieldProperty.styles.padding}`,
                            boxSizing: `${group.fieldProperty.styles.boxSizing}`,
                            height: `${group.fieldProperty.styles.height}`,
                            border: `${group.fieldProperty.styles.border}`,
                            cursor: `${group.fieldProperty.styles.cursor}`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                          {group.fieldProperty.tooltipText !== '' &&
                            tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)}
                        </Typography>
                      )}
                    </Grid>
                  </React.Fragment>
                );
              case 'textbox':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                          MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'textbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <TextField
                        id={`txt${group.fieldName}`}
                        label={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        name={group.fieldName}
                        onKeyDown={event => handleDisableNumericEvent(event, group.fieldProperty.isDisableNumeric)}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        onFocus={() => handleFocus(group.fieldName, true)}
                        variant="outlined"
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        helperText={errors[group.fieldName]}
                        InputLabelProps={{
                          shrink:
                            CommonFunctions.checkIsNullOrUndefined(Values[group.fieldName]) !== '' ||
                            isFocused[group.fieldName],
                          className: classes.labelBackgroundColor,
                        }}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName] || ''}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {group.fieldProperty.tooltipText !== '' &&
                                tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)}
                            </InputAdornment>
                          ),
                        }}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'reviewbox':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                            MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'reviewbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <TextField
                        id={`txt${group.fieldName}`}
                        label={group.fieldProperty.infieldLabelText}
                        name={group.fieldName}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName] || ''}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'email':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'emailtextbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 4}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 4}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <TextField
                        id={`txt${group.fieldName}`}
                        label={group.fieldProperty.infieldLabelText}
                        name={group.fieldName}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        helperText={errors[group.fieldName]}
                        InputLabelProps={{
                          shrink: CommonFunctions.checkIsNullOrUndefined(Values[group.fieldName]) !== '',
                          className: classes.labelBackgroundColor,
                        }}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        variant="outlined"
                        value={Values[group.fieldName]}
                        tooltipText={group.fieldProperty.tooltipText}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'address':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'address',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 12}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 12}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 12}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <AddressSearch
                        label={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        name={group.fieldName}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        placeChangedCallBack={props.placeChangedCallBack}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        helperText={errors[group.fieldName]}
                        InputLabelProps={{
                          shrink:
                            CommonFunctions.checkIsNullOrUndefined(Values[group.fieldName]) !== '' ||
                            isFocused[group.fieldName],
                          className: classes.labelBackgroundColor,
                        }}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        variant="outlined"
                        value={Values[group.fieldName]}
                        stateAssignName={
                          group.fieldProperty.addressPrefix !== undefined ? group.fieldProperty.addressPrefix : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {group.fieldProperty.tooltipText !== '' &&
                                tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)}
                            </InputAdornment>
                          ),
                        }}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'textarea':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'textarea',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 12}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 12}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 8}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <TextField
                        id={`txt${group.fieldName}`}
                        label={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        name={group.fieldName}
                        multiline
                        rows="4"
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        InputLabelProps={{
                          className: classes.labelBackgroundColor,
                        }}
                        helperText={errors[group.fieldName]}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        variant="outlined"
                        value={Values[group.fieldName]}
                        tooltipText={group.fieldProperty.tooltipText}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'dropdown':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                      MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'dropdown',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 3}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 3}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <DropDown
                        listItem={group.fieldProperty.choices}
                        name={group.fieldName}
                        isFullWidth
                        value={Values[group.fieldName] !== undefined ? Values[group.fieldName] : ''}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        isError={errors[group.fieldName] !== '' && errors[group.fieldName] !== undefined}
                        errorText={errors[group.fieldName]}
                        disabled={!group.fieldProperty.isEditable}
                        labelText={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        labelClass={classes.labelBackgroundColor}
                        variant="outlined"
                        endAdornment={
                          group.fieldProperty.tooltipText !== '' &&
                          tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)
                        }
                        className={
                          group.fieldProperty.tooltipText !== ''
                            ? classes.dropdownDownArrowMark
                            : classes.percentageStyle
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'ssn':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'ssn',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <SSNTextbox
                        id={`txt${group.fieldName}`}
                        label={group.fieldProperty.infieldLabelText}
                        name={group.fieldName}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation)}
                        onFocus={() => handleFocus(group.fieldName, true)}
                        variant="outlined"
                        error={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        InputLabelProps={{
                          shrink:
                            CommonFunctions.checkIsNullOrUndefined(Values[group.fieldName]) !== '' ||
                            isFocused[group.fieldName],
                          className: classes.labelBackgroundColor,
                        }}
                        helperText={errors[group.fieldName]}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName]}
                        tooltipText={group.fieldProperty.tooltipText}
                        className={classes.percentageStyle}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'fileupload':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      <UploadButton
                        name={group.fieldName}
                        tooltipText={
                          group.fieldProperty.tooltipText !== '' &&
                          tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'check':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      {/* <EFT group={group} onChange={props.onChange} /> */}
                    </Grid>
                  </React.Fragment>
                );
              case 'downloadlink':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    />
                  </React.Fragment>
                );
              case 'numeric':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                          MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'textbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                    >
                      {!!group.fieldProperty.labelText && (
                        <Typography
                          variant={group.fieldProperty.styles.labelFontVariant}
                          gutterBottom
                          style={{
                            color: group.fieldProperty.styles.labelFontColor,
                            fontWeight: group.fieldProperty.styles.labelFontWeight,
                            marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                          }}
                        >
                          {group.fieldProperty.labelText}
                        </Typography>
                      )}
                      <TextField
                        id={`txt${group.fieldName}`}
                        label={
                          group.fieldProperty.isRequired
                            ? fieldRequired(group.fieldProperty.infieldLabelText, group)
                            : group.fieldProperty.infieldLabelText
                        }
                        name={group.fieldName}
                        onChange={event => props.onChange(event)}
                        onBlur={event => props.onChange(event, group.fieldValidation, 'numeric', group.fieldName)}
                        variant="outlined"
                        error={!!errors[group.fieldName]}
                        helperText={errors[group.fieldName]}
                        fullWidth
                        disabled={!group.fieldProperty.isEditable}
                        value={Values[group.fieldName]}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {group.fieldProperty.tooltipText !== '' &&
                                tooltipMessageFn(group.fieldProperty.tooltipText, classes.tooltipLabel)}
                            </InputAdornment>
                          ),
                        }}
                        type="Number"
                        className={classes.removeArrows}
                      />
                    </Grid>
                  </React.Fragment>
                );
              case '1035autocomplete':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      {/* <AutoCompleteTextBox group={group} /> */}
                    </Grid>
                  </React.Fragment>
                );
              case 'trashbutton':
                return (
                  <Grid
                    key={group.fieldName}
                    item
                    id={`grd${group.fieldName}`}
                    className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                    style={{
                      marginTop: `${
                        group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                      }rem`,
                    }}
                    xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                    sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 12}
                    md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 12}
                    lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 12}
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={event => props.onChange(event, group.fieldValidation, 'button', group.fieldName)}
                      value={group.fieldProperty.labelText}
                      name={group.fieldName}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Grid>
                );
              case 'start':
                return (
                  <React.Fragment key={group.fieldName}>
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      <ReviewGrid
                        Values={Values}
                        JsonData={props.JsonData}
                        onTabClick={props.onTabClick}
                        widgetType="start"
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'yourannuity':
                return (
                  <React.Fragment key={group.fieldName}>
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      <ReviewGrid
                        Values={Values}
                        JsonData={props.JsonData}
                        onTabClick={props.onTabClick}
                        widgetType="yourannuity"
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'owner':
                return (
                  <React.Fragment key={group.fieldName}>
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      <ReviewGrid
                        Values={Values}
                        JsonData={props.JsonData}
                        onTabClick={props.onTabClick}
                        widgetType="owner"
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'jowner':
                return (
                  <React.Fragment key={group.fieldName}>
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      <ReviewGrid
                        Values={Values}
                        JsonData={props.JsonData}
                        onTabClick={props.onTabClick}
                        widgetType="jowner"
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'allocation':
                return (
                  <React.Fragment key={group.fieldName}>
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      <ReviewGrid
                        Values={Values}
                        JsonData={props.JsonData}
                        onTabClick={props.onTabClick}
                        widgetType="allocation"
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'allocationcheck':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                                        MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      className={[
                        group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
                        'checkbox',
                      ].join(' ')}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                        paddingTop: `${
                          group.fieldProperty.styles.paddingTop !== undefined
                            ? group.fieldProperty.styles.paddingTop
                            : ''
                        }rem`,
                        paddingBottom: `${
                          group.fieldProperty.styles.paddingBottom !== undefined
                            ? group.fieldProperty.styles.paddingBottom
                            : ''
                        }rem`,
                      }}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 4}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 4}
                    >
                      <AllocationCheck
                        name={group.fieldName}
                        labelText={group.fieldProperty.labelText}
                        listItem={group.fieldProperty.choices}
                        isError={errors[group.fieldName] !== undefined ? errors[group.fieldName] !== '' : false}
                        errorText={errors[group.fieldName]}
                        selectedValue={Values[group.fieldName]}
                        onChange={event => props.onChange(event, group.fieldValidation, 'checkbox', group.fieldName)}
                        color={group.fieldProperty.styles.labelFontColor}
                        fontVariant={group.fieldProperty.styles.labelFontVariant}
                        fontWeight={group.fieldProperty.styles.labelFontWeight}
                        tooltipText={group.fieldProperty.tooltipText}
                        textPlacement="right"
                        textWrap={group.fieldProperty.styles.textWrap}
                        isDisabled={
                          (Values['#totalAllocation'] >= 100 &&
                            CommonFunctions.checkIsNullOrUndefined(Values[group.fieldName]) === '') ||
                          !group.fieldProperty.isEditable
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              case 'constantnotification':
                return (
                  <React.Fragment key={group.fieldName}>
                    {group.fieldProperty.position.newLine === true && (
                      <div
                        className="MuiGrid-root MuiGrid-grid-xs-12
                      MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
                      />
                    )}
                    <Grid
                      item
                      id={`grd${group.fieldName}`}
                      xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
                      sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
                      md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
                      lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
                      className={group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
                      style={{
                        marginTop: `${
                          group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
                        }rem`,
                      }}
                    >
                      <ConstantNotification group={group} />
                    </Grid>
                  </React.Fragment>
                );
              default:
                return '';
            }
          })
        : ''}
    </React.Fragment>
  );
}

RenderForms.propTypes = {
  JsonData: PropTypes.array,
  placeChangedCallBack: PropTypes.func,
  onChange: PropTypes.func,
  onTabClick: PropTypes.func,
};
