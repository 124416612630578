import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { theme } from '@aspida/react-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import router from 'routes';
import { RouterProvider } from 'react-router-dom';
import Store from './redux/store';
import './index.css';

const queryClient = new QueryClient();
ReactDOM.render(
  <Provider store={Store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);
