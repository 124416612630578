import React from 'react';
import { Typography, Dialog, DialogContent, IconButton, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { SaveAlt } from '@material-ui/icons';
import Iframe from 'react-iframe';
import PropTypes from 'prop-types';
import styles from './style';

const useStyles = makeStyles(styles);

const IllustrateModal = ({ visible, onNoClose, onYesClose, illustrateUrl }) => {
  const classes = useStyles();

  return (
    <Dialog open={visible} aria-labelledby="form-dialog-title" maxWidth="lg">
      <Typography variant="h6" gutterBottom className={classes.modalTitle}>
        Illustration
      </Typography>
      <IconButton aria-label="close" title="Close" className={classes.modalClear} onClick={onNoClose}>
        <ClearIcon />
      </IconButton>
      <DialogContent className={classes.modal}>
        <Iframe
          url={illustrateUrl}
          className={classes.IframeStyle}
          id="illustrateID"
          display="block"
          position="relative"
        />
        <Grid container justifyContent="flex-end">
          <Button color="secondary" variant="outlined" size="large" className={classes.modalButton} onClick={onNoClose}>
            Close
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.modalButton}
            onClick={onYesClose}
            startIcon={<SaveAlt />}
          >
            Download
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default IllustrateModal;
IllustrateModal.propTypes = {
  onNoClose: PropTypes.func,
  onYesClose: PropTypes.func,
  visible: PropTypes.bool,
  illustrateUrl: PropTypes.string,
};
