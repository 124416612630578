import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/Material.css';

import './notificationColors.css';

PNotify.defaults.icons = 'material';
PNotify.defaults.styling = 'material';
PNotify.defaultStack.close(true);
PNotify.defaultStack.maxOpen = Infinity;
PNotify.defaultStack.modal = false;

export default class NotificationService {
  static success(title, text) {
    PNotify.success({ title, text });
  }

  static error(error) {
    PNotify.error({ title: 'Error', text: error });
  }
}
