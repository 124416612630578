import dayjs from 'dayjs';
import store from 'redux/store';
import { setValue } from 'redux/getStarted/actions';
import { setAuthenticatedUser } from 'redux/user/actions';
import CommonFunctions from 'views/illustration/utils/commonFunctions';

const validatenull = (data, key) => {
  return data === undefined || data === '' ? key : data;
};

const formatDate = (dateString, key) => {
  const formatString = key === '-' ? `YYYY${key}MM${key}DD` : `MM${key}DD${key}YYYY`;
  const formattedDate = dateString === '' || dateString === undefined ? '' : dayjs(dateString).format(formatString);
  return formattedDate;
};

const formatToCurrency = val => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(val); /* $2,500.00 */
};

const validateCurrency = val => {
  return val === '0' || val === '0.00' ? '0' : formatToCurrency(val);
};

const validateCurrencyZero = val => {
  return val === '0' || val === '0.00' ? '0' : formatToCurrency(val).replace('.00', '');
};

const addStringWithComma = (firstData, secondData) => {
  let outputData = firstData.toString();
  if (secondData !== undefined || secondData !== '') {
    outputData = `${outputData}, ${secondData.toString()}`;
  }

  return outputData;
};

const calculateAge = dateString => {
  const age = dayjs().diff(new Date(dateString), 'years', true);
  return age.toFixed();
};

const addPercentage = data => {
  const val = (parseFloat(data) * 100).toFixed(2);
  return `${val.toString()}%`;
};

const addPercentString = data => {
  return data ? `${data.toString()}%` : null;
};

const setSpecification = async (user, applicationData) => {
  const state = store.getState();

  if (CommonFunctions.checkIsNullOrUndefined(applicationData) !== '') {
    store.dispatch(setAuthenticatedUser({ ...user, verified: user.verified }));
    store.dispatch(setValue(applicationData));
    state.getStarted = applicationData;
  }

  CommonFunctions.getSpecificationName();
};

export default {
  validatenull,
  formatDate,
  validateCurrency,
  validateCurrencyZero,
  addStringWithComma,
  calculateAge,
  addPercentage,
  addPercentString,
  setSpecification,
};
