import FASTInputJson from './FASTInputJson.json';
import FASTMappingCodeJson from './FASTMapping.json';
import msgMygaSchema from './msgMygaSchema.json';
import wealthLockMygaSchema from './wealthLockMygaSchema.json';
import fiaSchema from './fiaSchema.json';
import fiaIncomeSchema from './fiaIncomeSchema.json';
import withdrawalCharges from './withdrawalCharges.json';
import eApp from './eApp.json';

export default {
  FASTInputJson,
  FASTMappingCodeJson,
  msgMygaSchema,
  fiaSchema,
  fiaIncomeSchema,
  wealthLockMygaSchema,
  withdrawalCharges,
  eApp,
};
