import * as React from 'react';
import store from 'redux/store';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography } from '@material-ui/core';
import GroupTrackerFunctions from 'views/illustration/utils/groupTrackerFunctions';
import { makeStyles } from '@material-ui/core/styles';
import styles from './style';

const useStyles = makeStyles(styles);

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '87%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} className={classes.linearProg} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography className={classes.percentStyle} variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}% Complete`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel() {
  const state = store.getState();
  const prevProgress = GroupTrackerFunctions.calculateCompletePercentage(state.getStarted.trackergroupnames);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={prevProgress} />
    </Box>
  );
}
